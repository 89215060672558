import { TableColumnsType } from "antd";
import { UseTermItem } from "../../../../../../../shared/dto/UseTermItem";
import moment from "moment";

const columns: TableColumnsType<UseTermItem> = [
  {
    title: 'Autor',
    dataIndex: 'authorName',
    render: (text) => <div style={{ overflowX: "auto", maxWidth: 500, width: "100%"}}>{text}</div>,
    sorter: (a, b) => a.useTermId - b.useTermId,
    width: 150,
  },
  {
    title: 'Data inicial',
    render: (value) => <div style={{ overflowX: "auto", maxWidth: 100, width: "100%"}}>{moment(value).format("DD/MM/YYYY")}</div>,
    dataIndex: 'startDate',
    sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
  },
  {
    title: 'Data final',
    render: (value) => <div style={{ overflowX: "auto", maxWidth: 100, width: "100%"}}>{moment(value).format("DD/MM/YYYY")}</div>,
    dataIndex: 'endDate',
    sorter: (a, b) => moment(a.endDate).unix() - moment(b.endDate).unix(),
  },
  {
    title: 'Status',
    dataIndex: 'active',
    render: (text) => <div style={{ overflowX: "auto", maxWidth: 500, width: "100%"}}>{text ? "ATIVO" : "INATIVO"}</div>,
    width: 150,
  },
  {
    title: 'Ações',
    dataIndex: 'action',
    width: 250,
  },
]

export default columns;