import React, { useEffect, useState } from "react";
import { Carousel, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import ChallengeProvider from "../../../../shared/provider/ChallengeProvider";
import { ChallengeCarouselContainer, ChallengeImage, ChallengeContent, ChallengeFooter } from "./styles";
import ChallengeDto from "../../../../shared/dto/ChallengeDto";
import moment from "moment";

function ChallengeCarousel() {
  const [featuredChallenges, setFeaturedChallenges] = useState<ChallengeDto[]>([]);
  const challengeProvider = new ChallengeProvider();
  const navigate = useNavigate();

  useEffect(() => {
    fetchFeaturedChallenges();
  }, []);

  async function fetchFeaturedChallenges() {
    try {
      const data = await challengeProvider.getAll({
        page: 1,
        itemsPerPage: 10,
        featured: true,
      });

      if (!data) return;

      setFeaturedChallenges(data.data);
    } catch (error) {
      console.error("Erro ao buscar desafios em destaque:", error);
    }
  }

  function handleCardClick(challengeId: string) {
    navigate(`/?idDesafio=${challengeId}`);
  }

  if (!featuredChallenges || !featuredChallenges.length) return null;

  return (
    <ChallengeCarouselContainer>
      <Carousel autoplay>
        {featuredChallenges.map((challenge) => (
          <div key={challenge.challengeId} onClick={() => challenge.challengeId && handleCardClick(challenge.challengeId)}>
            <Row>
              <Col xs={24} lg={10}>
                <ChallengeImage src={`${window.global.env.reactAppApiPublicUrl}/${challenge.coverImage}`} alt={challenge.title} />
              </Col>
              <Col xs={24} lg={14}>
                <ChallengeContent>
                  <h2>{challenge.title}</h2>
                  <p>{challenge.shortDescription}</p>
                  <ChallengeFooter>
                    <p>
                      <strong>Data Início:</strong> {moment(challenge.startDate).format("DD/MM/YYYY")} &nbsp;
                      <strong>Data Fim:</strong> {moment(challenge.endDate).format("DD/MM/YYYY")}
                    </p>
                  </ChallengeFooter>
                </ChallengeContent>
              </Col>
            </Row>
          </div>
        ))}
      </Carousel>
    </ChallengeCarouselContainer>
  );
}

export default ChallengeCarousel;