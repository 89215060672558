import React, { useEffect, useState } from "react";
import {
  ChallengeListContainer,
  ChallengeListHeader,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { IPageData } from "../../../../../../shared/dto/PageData";
import sortList from "../../../../../../shared/util/Order";
import AddChallenge from "../addChallenge";
import EditChallenge from "../EditChallenge";
import DeleteChallenge from "../DeleteChallenge";
import { Table } from "antd";
import { ChallengeItem } from "../../../../../../shared/dto/ChallengeItemDto";
import customLocale from "../../../../../../shared/constants/customLocale";
import columns from "./config/columns";
import mapChallengeDtoListToItems from "./utils/mapDtoToItem";
import ChallengeDto from "../../../../../../shared/dto/ChallengeDto";
import ChallengeProvider from "../../../../../../shared/provider/ChallengeProvider";

function ChallengeListItem({
  openAddChallenge,
  setOpenAddChallenge,
}: {
  readonly openAddChallenge: boolean;
  readonly setOpenAddChallenge: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const [sortDirection] = React.useState<"asc" | "desc">();
  const [sortKey] = React.useState<string>();
  const [editChallenge, setEditChallenge] = React.useState(false);
  const [deleteChallenge, setDeleteChallenge] = React.useState(false);
  const [selectedChallengeForEdit, setSelectedChallengeForEdit] = React.useState(
    {} as ChallengeDto
  );
  const [selectedChallengeForDelete, setSelectedChallengeForDelete] =
    React.useState({} as ChallengeDto);

  const [response, setResponse] = useState<IPageData<Array<ChallengeDto>>>(
    {} as IPageData<Array<ChallengeDto>>
  );
  const [list, setList] = useState<ChallengeItem[]>([]);

  const challengeProvider = new ChallengeProvider();
  const navigate = useNavigate();

  function getPreviousSelectedChallengeForEdit(data: ChallengeDto[]) {
    if (!data) return;
    const challenge = data.find(
      (ch) => ch.challengeId === selectedChallengeForEdit.challengeId
    );
    if (challenge) setSelectedChallengeForEdit(challenge);
  }

  async function fetchChallenges(page = 1, itemsPerPage = 10) {
    try {
      const data = await challengeProvider.getAll({
        page,
        itemsPerPage,
      });
      if (!data) return;

      setResponse(data);
      getPreviousSelectedChallengeForEdit(data.data);
      setList(mapChallengeDtoListToItems({
        dto: data.data,
        deleteFn: handleDeleteChallengeClick,
        viewFn: handleEditChallengeClick,
      }));
    } catch (error) {
     /*  toast(
        <span>
          <b>Sessão expirada.</b> Você será redirecionado!
        </span>,
        {
          type: "error",
          autoClose: 500,
          onClose: () => {
            navigate("/entrar");
          },
        }
      ); */
    }
  }

  useEffect(() => {
    fetchChallenges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleEditChallengeClick(challenge: ChallengeDto) {
    setSelectedChallengeForEdit(challenge);
    setEditChallenge(true);
  }

  function handleDeleteChallengeClick(challenge: ChallengeDto) {
    setSelectedChallengeForDelete(challenge);
    setDeleteChallenge(true);
    setEditChallenge(false);
  }

  function handleSortList() {
    if (sortKey) {
      const direction = sortDirection === "asc" ? "desc" : "asc";
      const sorted = sortList(
        response.data,
        sortKey as keyof ChallengeDto,
        direction
      );
      setResponse({ ...response, data: sorted });
    }
  }

  React.useEffect(() => {
    handleSortList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, sortKey]);

  React.useEffect(() => {
    if (!openAddChallenge) fetchChallenges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAddChallenge]);

  React.useEffect(() => {
    if (!editChallenge) fetchChallenges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editChallenge]);

  return (
    <ChallengeListContainer>
      <AddChallenge
        open={openAddChallenge}
        setOpen={setOpenAddChallenge}
        requireCategoryList={fetchChallenges}
      />
      <EditChallenge
        open={editChallenge}
        setOpen={setEditChallenge}
        requireChallengeList={fetchChallenges}
        challenge={selectedChallengeForEdit}
        handleDeleteChallengeClick={handleDeleteChallengeClick}
      />
      <DeleteChallenge
        open={deleteChallenge}
        setOpen={setDeleteChallenge}
        challenge={selectedChallengeForDelete}
        onFinished={fetchChallenges}
      />

      <ChallengeListHeader></ChallengeListHeader>

      <Table<ChallengeItem>
        columns={columns}
        dataSource={list || []}
        locale={customLocale}
        pagination={{ defaultPageSize: 10 }}
        scroll={{ x: 920 }}
      />
    </ChallengeListContainer>
  );
}

export default ChallengeListItem;