import { FaHome, FaMedal, FaPaste } from "react-icons/fa";
import { ROLES } from "../../../../../guard/Roles";
import { PERMISSIONS } from "../../../../../guard/Permissions";
const normalizeTitle = (title: string) => {
	return title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s+/g, '');
};

const MenuItems = [
	{
		title: "Explorar Ideias",
		path: "/",
		icon: <FaHome />,
		disabled: false,
	},
	{
		title: "Explorar desafios",
		path: "/explorar-desafios",
		icon: <FaMedal />,
		protected: [PERMISSIONS.VER_DESAFIOS],
		disabled: false,
	},
	{
		title: "Quem Inova",
		path: "/quem-inova",
		icon: <img src={`${window.global.env.publicUrl}queminovaicon.png`} width={24} style={{ marginRight: 5 }}/>,
		protected: [PERMISSIONS.VISUALIZAR_QUEM_INOVA],
		disabled: false,
	},
	{
		title: "Administração",
		path: "/administracao",
		protected: [PERMISSIONS.ADMINISTRACAO, PERMISSIONS.ALTERAR_FASE_IDEIA, PERMISSIONS.ALTERAR_PAPEL, PERMISSIONS.ALTERAR_TENANT, PERMISSIONS.EXCLUIR_CATEGORIAS, PERMISSIONS.CADASTRAR_DESAFIOS, PERMISSIONS.ADICIONAR_PAPEL ],
		icon: <FaPaste />,
		childs: [
			{ title: "Permissões", icon: <span style={{ width: 24, display: "inline-block" }} />, queryString: normalizeTitle("Permissões") },
			{
			title: "Usuários",
			path: "/adm/gestao-usuario",
			protected: [ROLES.ADMIN],
			icon: <span style={{ width: 24, display: "inline-block" }} />,
			queryString: normalizeTitle("Usuários"),
			},
			{ title: "Categorias", icon: <span style={{ width: 24, display: "inline-block" }} />, queryString: normalizeTitle("Categorias") },
			{ title: "Fases", icon: <span style={{ width: 24, display: "inline-block" }} />, queryString: normalizeTitle("Fases") },
			{ title: "Dimensão", icon: <span style={{ width: 24, display: "inline-block" }} />, queryString: normalizeTitle("Dimensão") },
			{ title: "Desafios", icon: <span style={{ width: 24, display: "inline-block" }} />, queryString: normalizeTitle("Desafios") },
			{ title: "Termos de uso", icon: <span style={{ width: 24, display: "inline-block" }} />, queryString: normalizeTitle("Termos de uso") },
			{ title: "Relatório", icon: <span style={{ width: 24, display: "inline-block" }} />, queryString: normalizeTitle("Relatório") },
		],
	},
];

export default MenuItems;
