import styled from "styled-components";

export const EditUseTermFormContainer = styled.form`
    section {
        margin: 15px 0;
    }

    h2 {
        text-align: center;
    }

    .date-picker-container {
        display: flex;
        gap: 15px;
    }

    footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
        width: 100%;
        gap: 15px;
    }
`;