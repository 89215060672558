import React from "react";
import { ExcluirChallengeContainer } from "./styles";
import Modal from "../../../../../../shared/util/Modal";
import DefaultButton from "../../../../../../shared/util/DefaultButton";
import ChallengeDto from "../../../../../../shared/dto/ChallengeDto";
import ChallengeProvider from "../../../../../../shared/provider/ChallengeProvider";

function DeleteChallenge({
													 open,
													 setOpen,
													 challenge,
													 onFinished,
												 }: Readonly<{
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	challenge: ChallengeDto;
	onFinished?: () => void;
}>) {
	const [successMessage, setSuccessMessage] = React.useState("");
	const challengeProvider = new ChallengeProvider();
	React.useEffect(() => {
		if (!open) {
			setSuccessMessage("");
		}
	}, [open]);

	async function handleExcluirChallenge() {
		if (challenge) {
			try {
				await challengeProvider.delete(challenge.challengeId || "");
				setSuccessMessage("Categoria excluida com sucesso.");
			} catch (error) {
				console.error("Erro ao excluir categoria:", error);
			}
		}
	}

	return (
		<ExcluirChallengeContainer>
			<Modal
				open={open}
				setOpen={setOpen}
			>
				{!successMessage && (
					<>
						<h3 style={{ textAlign: "center" }}>Excluir categoria "{challenge.title}"?</h3>

						<footer>
							<DefaultButton
								width="115px"
								fontSize="1em"
								height="36px"
								withOutBackground={true}
								params={
									{
										onClick: () => setOpen(false),
									}
								}
							>
								Voltar
							</DefaultButton>
							<DefaultButton
								width="115px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: handleExcluirChallenge,
								}}
							>
								Excluir
							</DefaultButton>
						</footer>
					</>
				)}

				{successMessage && (
					<div className="edit-user-success-step">
						<h2>{successMessage}</h2>
						<div>
							<DefaultButton
								withOutBackground={true}
								width="115px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: () => {
										setOpen(false);
										onFinished && onFinished();
									},
								}}
							>
								Fechar
							</DefaultButton>
						</div>
					</div>
				)}
			</Modal>
		</ExcluirChallengeContainer>
	);
}

export default DeleteChallenge;