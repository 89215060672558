import styled from "styled-components";

export const IdeaListContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .infinite-scroll-initial-page {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 2% 15px;
    justify-content: space-between;

    @media (max-width: 933px) {
      gap: 45px;

    }
  }
`;