import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CardIdea } from '../CardIdea/Index';
import { IdeiaDto } from '../../../../shared/dto/IdeiaDto';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { IdeaListContainer } from './styles';
import { IIdeiasDTO } from '../../../../shared/provider/IdeaProvider';

interface IdeaListProps {
  ideas: IdeiaDto[];
  setIdeas: (value: IdeiaDto[]) => void;
  page: number;
  setPage: (value: number) => void;
  fetchMoreIdeas: (params : IIdeiasDTO) => Promise<any[]>;
  handleViewIdea(id: string): void;
  hasMore: boolean;
}

const IdeaList = ({ ideas, setIdeas, fetchMoreIdeas, handleViewIdea, page, setPage, hasMore }: IdeaListProps) => {
  const fetchMoreData = async () => {
    const newIdeas = await fetchMoreIdeas({});
    let allIdeas = [...ideas, ...newIdeas];
    allIdeas.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
    setIdeas(allIdeas);
  };

  return (
    <IdeaListContainer>
      <InfiniteScroll
        className='infinite-scroll-initial-page'
        dataLength={ideas.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<Spin indicator={<LoadingOutlined spin />} size="large" fullscreen/>}
        endMessage={<p style={{ textAlign: 'center' }}></p>}
      >
        {ideas.map((idea) => (
          <CardIdea key={idea.ideaId} ideia={idea} handleClick={handleViewIdea} />
        ))}
    </InfiniteScroll>
    </IdeaListContainer>
  );
};

export default IdeaList;