import CryptoJS from "crypto-js";

function getKey(): string {
    const { reactAppCryptoKey: REACT_APP_CRYPTOKEY } = window.global.env;
    if (REACT_APP_CRYPTOKEY) return REACT_APP_CRYPTOKEY;
    else return ""
}

function encriptar(value: string): string {
    const key = getKey();
    //Se não existir chave não cifra e nem quebra a aplicação
    if (!key) return value;

    const cipher = CryptoJS.AES.encrypt(value, key).toString();

    return cipher;
}

function decriptar(value : string): string {
    const key = getKey();
    //Se não existir chave não cifra e nem quebra a aplicação
    if (!key) return value;

    const decipher = CryptoJS.AES.decrypt(value, key);

    // Tenta converter o Base64 em UTF-8, caso estivesse encriptado
    // Caso contrário vai lançar um erro de má formatação de UTF-8
    // Logo, não estava criptografado, deve devolver assim mesmo.
    try {
        const response = decipher.toString(CryptoJS.enc.Utf8);
        return response;
    } catch (error) {
        return value;
    }

};

const Crypter = {
    encriptar, decriptar
};

export { Crypter };