import React from "react";
import { EditUseTermFormContainer } from "./styles";
import { ConfigProvider, DatePicker, Flex, Switch, message } from "antd";
import DefaultButton from "../../../../../../../shared/util/DefaultButton";
import UseTermProvider from "../../../../../../../shared/provider/UseTermProvider";
import { ResponseUseTermDto } from "../../../../../../../shared/dto/ResponseUseTermDto";
import ptBR from "antd/lib/locale/pt_BR";
import dayjs from "dayjs";
import CustomEditor from "../../../../../../ideia/components/AddModal/components/CustomEditor";

function EditUseTermForm({
  setOpen,
  isLoading = false,
  setLoading,
  setSuccessMessage,
  useTerm,
  requireUseTermList,
}: {
  setOpen: React.Dispatch<boolean>,
  isLoading?: boolean,
  setLoading: React.Dispatch<boolean>,
  setSuccessMessage: React.Dispatch<string>,
  requireUseTermList: () => Promise<void>,
  useTerm: ResponseUseTermDto,
  setSuccessMesstage?: (value: (((prevState: string) => string) | string)) => void,
  setEditMode?: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  open?: boolean,
  handleDeleteUseTermClick?: (useTerm: ResponseUseTermDto) => void
}) {
const [UserTermDescription, setUserTermDescription] = React.useState<string>(useTerm.description || "");
const format = 'YYYY-MM-DDTHH:mm:ss';

const [startDate, setStartDate] = React.useState<dayjs.Dayjs | null>(
  useTerm.startDate ? dayjs(useTerm.startDate, format, true) : null
);

const [endDate, setEndDate] = React.useState<dayjs.Dayjs | null>(
  useTerm.endDate ? dayjs(useTerm.endDate, format, true) : null
);
  const [active, setActive] = React.useState<boolean>(useTerm.active || false);

  const useTermProvider = new UseTermProvider();

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    updateUseTerm();
  }

  function handleSwitchChange(checked: boolean) {
    const today = dayjs();
    if (checked && (startDate && endDate && (startDate.isAfter(today) || endDate.isBefore(today)))) {
      message.error("Datas inválidas para ativar o termo de uso");
      return;
    }
    setActive(checked);
  }

  function handleStartDateChange(date: dayjs.Dayjs | null) {
    const today = dayjs();
    if (date && date.isBefore(today, 'day')) {
      message.error("Data de início não pode ser anterior a hoje");
      return;
    }
    setStartDate(date);
  }
  
  function handleEndDateChange(date: dayjs.Dayjs | null) {
    if (date && startDate && date.isBefore(startDate, 'day')) {
      message.error("Data de término não pode ser anterior à data de início");
      return;
    }
    setEndDate(date);
  }

  async function updateUseTerm() {
    setLoading(true);

    try {
      if (!UserTermDescription || !startDate || !endDate) {
        message.error("Preencha todos os campos");
        return;
      }

      const response = await useTermProvider.updateUseTerm(useTerm.useTermId, {
        description: UserTermDescription,
        startDate: startDate?.toISOString() || new Date().toISOString(),
        endDate: endDate?.toISOString() || new Date().toISOString(),
        active
      });

      if (!response) throw new Error("Erro ao atualizar termo de uso");
      setSuccessMessage("Termo de uso atualizado com sucesso!");
      setTimeout(() => requireUseTermList(), 550);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <EditUseTermFormContainer onSubmit={handleSubmit}>
      <CustomEditor
        value={UserTermDescription}
        onChange={(e) => setUserTermDescription(e.target.value)}
        showCharCount
        charLimit={8000}
      />
      <ConfigProvider locale={ptBR}>
      <div className="date-picker-container">
        <DatePicker
          defaultValue={startDate}
          placeholder="Data de Início"
          onChange={handleStartDateChange}
          format={"DD/MM/YYYY"}
        />
        <DatePicker
          defaultValue={endDate}
          placeholder="Data de Término"
          format={"DD/MM/YYYY"}
          onChange={handleEndDateChange}
          disabledDate={(current) => !!current && !!startDate && current.isBefore(startDate, 'day')}
        />
      </div>
      <Flex align="center" gap={15} style={{marginTop: 15}}>
        <Switch checked={active} onChange={handleSwitchChange} /> {active ? "Ativo" : "Inativo"}
      </Flex>
      </ConfigProvider>
      <footer>
        <DefaultButton
          width="125px"
          height="36px"
          fontSize="1em"
          withOutBackground={true}
          params={{
            onClick: () => setOpen(false),
          }}
        >
          Voltar
        </DefaultButton>
        <DefaultButton
          width="125px"
          height="36px"
          fontSize="1em"
          params={{
            type: "submit",
          }}
        >
          Atualizar
        </DefaultButton>
      </footer>
    </EditUseTermFormContainer>
  );
}

export default EditUseTermForm;