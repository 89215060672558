import styled from "styled-components";

export const TabMenuContainer = styled.nav`
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1em;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-bottom: 15px;
    li {
      flex-shrink: 0;

      a {
        padding: 15px 24px;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.text};
        svg {
          background: none;
          margin-right: 10px;
        }
        &:hover {
          background-color: ${({ theme }) => theme.colors.lightGray};
        }
        &:visited {
          color: unset;
        }
      }
    }
    .active-nav-tab-item {
      color: ${({ theme }) => theme.colors.primary};
      border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    }
    .item-menu {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media (max-width: 1530px) {
    .main-dropdown {
      display: none;
    }
  }

  .hamburger-menu {
    display: none;
    cursor: pointer;
    @media (max-width: 1530px) {
      display: block;
    }
  }

  .dropdown-menu {
    position: fixed;
    top: 160px;
    left: -400px;
    width: 400px;
    background-color: ${({ theme }) => theme.colors.background};
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 20px 0 20px 10%;
    margin: 0;
    font-size: 1em;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
    overflow-y: auto;
    li {
      flex-shrink: 0;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
      padding: 15px 0;
      a {
        padding: 15px 24px;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.text};
        svg {
          background: none;
          margin-right: 10px;
        }
        &:hover {
          background-color: ${({ theme }) => theme.colors.lightGray};
        }
        &:visited {
          color: unset;
        }
      }
    }
  }

  .dropdown-menu.show {
    left: 0;
  }
`;