import { message } from 'antd';
import { CreateUserDto } from '../dto/CreateUserDto';
import Envoy from '../util/Envoy';

class AuthProvider extends Envoy {
  public async login({email, password}: {email: string, password: string}): Promise<boolean> {
    try {
      const response = await this.conn.post('/auth/login', { email, password });

      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));

      const { token, tenant, multipleProfiles } = response.data.data;
      localStorage.setItem('token', token);
      localStorage.setItem('tenantName', tenant);
      localStorage.setItem('multipleProfiles', multipleProfiles);

      this.conn.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const role = await this.conn.get('/auth/role/user');
      if (role.data.messages && role.data.messages.length)
        throw new Error(role.data.messages.join('\n'));
      localStorage.setItem('role', role.data.data);

      const permissions = await this.conn.get('/auth/role/user/permissions');
      if (permissions.data.messages && permissions.data.messages.length)
        throw new Error(permissions.data.messages.join('\n'));
      localStorage.setItem('permissions', permissions.data.data.join(','));
      return true;
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      throw error;
    }
  }

  public async logout(): Promise<void> {
    try {
      await this.conn.post("/auth/logout");
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('tenantName');
      localStorage.removeItem('multipleProfiles');
      localStorage.removeItem('permissions');

      delete this.conn.defaults.headers.common['Authorization'];
      if (!window.global.env.reactAppLocal) {
        window.location.href = window.global.env.reactAppOauthLogoutUrl;
      }
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
      throw error;
    }
  }

  public async checkLogin(): Promise<boolean> {
    try {
      this.conn.defaults.headers.common['X-TOKEN-IDEAX'] = `${localStorage.getItem('token')}`;
      const response = await this.conn.get('/auth/check-login');
      return response.data.data;
    } catch (error) {
      console.error('Erro ao verificar login:', error);
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('tenantName');
      localStorage.removeItem('multipleProfiles');
      localStorage.removeItem('permissions');
      //throw error;
      return false;
    }
  }

  public async register(data : CreateUserDto): Promise<boolean> {
    try {
      const response = await this.conn.post('/auth/register', { ...data });
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));
      return true;
    } catch (error : any) {
      if (error && error.response && error.response.status === 403) {
        message.info('Você não tem permissão');
      }
      if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length) {
        message.info(error.response.data.messages.join('\n'));
      }
      console.error('Erro ao registrar usuário:', error);
      //throw error;
      return false;
    }
  }

  public async refreshSession(): Promise<boolean> {
    try {
      this.conn.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      this.conn.defaults.headers.common['X-TOKEN-IDEAX'] = `Bearer ${localStorage.getItem('token')}`;

      console.log({token: localStorage.getItem('token')});
      const response = await this.conn.post('/auth/refresh-session');
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));

      const { token, tenant, multipleProfiles } = response.data.data;
      localStorage.setItem('token', token);
      localStorage.setItem('tenantName', tenant);
      localStorage.setItem('multipleProfiles', multipleProfiles);

      this.conn.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const role = await this.conn.get('/auth/role/user');
      if (role.data.messages && role.data.messages.length)
        throw new Error(role.data.messages.join('\n'));
      localStorage.setItem('role', role.data.data);

      const permissions = await this.conn.get('/auth/role/user/permissions');
      if (permissions.data.messages && permissions.data.messages.length)
        throw new Error(permissions.data.messages.join('\n'));
      localStorage.setItem('permissions', permissions.data.data.join(','));

      return true;
    } catch (error) {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('multipleProfiles');
      localStorage.removeItem('tenantName');
      localStorage.removeItem('permissions');
      console.error('Erro ao atualizar sessão:', error);
      throw error;
    }
  }
  public async selectTenant(tenantId: string): Promise<boolean> {
    try {
      const response = await this.conn.put(`/auth/session/select-tenant/${tenantId}`);
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));
      
      localStorage.setItem('tenantName', response.data.data);

      const role = await this.conn.get('/auth/role/user');
      if (role.data.messages && role.data.messages.length)
        throw new Error(role.data.messages.join('\n'));
      localStorage.setItem('role', role.data.data);

      return true;
    } catch (error) {
      console.error('Erro ao selecionar tenant:', error);
      throw error;
    }
  }
}

export default AuthProvider;