import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sortList from "../../../../../../shared/util/Order";
import { message, Table } from "antd";
import columns from "./config/columns";
import customLocale from "../../../../../../shared/constants/customLocale";
import DeleteUseTerm from "../DeleteUserTerm";
import { ResponseUseTermDto } from "../../../../../../shared/dto/ResponseUseTermDto";
import { UseTermItem } from "../../../../../../shared/dto/UseTermItem";
import { UseTermListContainer, UseTermListHeader } from "./styles";
import UseTermProvider from "../../../../../../shared/provider/UseTermProvider";
import mapUseTermDtoListToItems from "../utils/mapDtoToItem";
import AddUseTermForm from "../AddUseTerm";
import { ColumnGroupType, ColumnType } from "antd/lib/table";
import EditUseTerm from "../EditUseTerm";

function UseTermItemListItem({
  openAddUseTerm,
  setOpenAddUseTerm,
}: {
  readonly openAddUseTerm: boolean;
  readonly setOpenAddUseTerm: React.Dispatch<boolean>;
}): JSX.Element {
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">();
  const [sortKey, setSortKey] = useState<string>();
  const [editUseTerm, setEditUseTerm] = useState(false);
  const [deleteUseTerm, setDeleteUseTerm] = useState(false);
  const [selectedUseTermForEdit, setSelectedUseTermForEdit] = useState({} as ResponseUseTermDto);
  const [selectedStageForDelete, setSelectedStageForDelete] = useState({} as ResponseUseTermDto);

  const [response, setResponse] = useState<ResponseUseTermDto[]>([]);
  const [list, setList] = useState<UseTermItem[]>([]);

  const useTermProvider = new UseTermProvider();
  const navigate = useNavigate();

  async function requireUseTermList() {
    try {
      const data = await useTermProvider.getAllUseTerms();
      if (!data) return;

      setResponse(data.data);
      console.log({ data });
      setList(
        mapUseTermDtoListToItems({
          dto: data.data,
          deleteFn: handleDeleteUseTermClick,
          viewFn: handleEditStageClick,
        })
      );
    } catch (error) {
      console.log("Erro ao buscar termos de uso", { error });
      message.error(
				<span>
					<b>Sessão expirada.</b> Você será redirecionado!
				</span>,
			);
			setTimeout(() => navigate("/entrar"), 500);
    }
  }

  useEffect(() => {
    requireUseTermList();
  }, []);

  function handleEditStageClick(useTerm: ResponseUseTermDto) {
    setSelectedUseTermForEdit(useTerm);
    setEditUseTerm(true);
  }

  function handleDeleteUseTermClick(useTerm: ResponseUseTermDto) {
    setSelectedStageForDelete(useTerm);
    setDeleteUseTerm(true);
    setEditUseTerm(false);
  }

  function handleSortList() {
    if (sortKey) {
      const direction = sortDirection === "asc" ? "desc" : "asc";
      const sorted = sortList(response, sortKey as keyof ResponseUseTermDto, direction);
      setResponse(sorted);
    }
  }

  useEffect(() => {
    handleSortList();
  }, [sortDirection, sortKey]);

  return (
    <UseTermListContainer>
      <AddUseTermForm
        open={openAddUseTerm}
        setOpen={setOpenAddUseTerm}
        requireUseTermList={requireUseTermList}
        requireStageList={requireUseTermList}
      />
      {editUseTerm && (
        <EditUseTerm
          open={editUseTerm}
          setOpen={setEditUseTerm}
          requireUseTermList={requireUseTermList}
          useTerm={selectedUseTermForEdit}
          handleDeleteUseTermClick={handleDeleteUseTermClick}
        />
      )}
      <DeleteUseTerm
        open={deleteUseTerm}
        setOpen={setDeleteUseTerm}
        useTerm={selectedStageForDelete}
        onFinished={requireUseTermList}
      />

      <UseTermListHeader></UseTermListHeader>

      <Table<UseTermItem>
        columns={columns as (ColumnGroupType<UseTermItem> | ColumnType<UseTermItem>)[]}
        dataSource={list || []}
        locale={customLocale}
        pagination={{ defaultPageSize: 10 }}
        scroll={{ x: 920 }}
      />
    </UseTermListContainer>
  );
}

export default UseTermItemListItem;