import styled from "styled-components";

export const HeaderContainer = styled.header`
  position: relative;
  z-index: 800;
  margin-bottom: 15px;
`;

export const NotificationListContainer = styled.div`
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const NotificationItem = styled.div<{ isRead: boolean }>`
  padding: 5px 15px;
  border-bottom: 1px solid #f0f0f0;
  background-color: ${({ isRead }) => (isRead ? "white" : "#e6f7ff")};
  display: flex;
  align-items: center;

  a {
    color: ${props => props.theme.colors.text};
    font-size: 14px;
    font-weight: 500;
  }
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const NotificationIcon = styled.div`
  margin-right: 10px;
  font-size: 18px;
  color: #1890ff;
`;

export const MarkAllAsReadContainer = styled.div`
  padding: 10px;
  text-align: center;
  background-color: white;
`;