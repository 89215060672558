import styled from "styled-components";

export const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 20px;
`;

export const ModalContainer = styled.div`
    background: ${({ theme }) => theme.colors.background};
    padding: 2%;
    max-width: 500px;
    max-height: 90vh; 
    overflow-y: auto; 
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
    position: relative;

    header {
        button {
            border: none;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &:hover {
                background: ${({ theme }) => theme.colors.lightGray};
            }

            svg {
                font-size: 2em;
                fill: ${({ theme }) => theme.colors.primary};
                background: transparent;
            }
        }
    }
`;