import { IPageData } from "../dto/PageData";
import { IdeiaDto } from "../dto/IdeiaDto";
import Envoy, { IPaginationDTO } from "../util/Envoy";
import { UploadFile } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import { MetaSimplificadoDto } from "../dto/MetaSimplificadoDto";

export interface IIdeiasDTO extends IPaginationDTO, ISearchIdeasDTO, IReportParams {
	q?: string;
	stageId?: string;
	active?: boolean;
	featured?: boolean;
}

export interface CreateIdeiaDto {
	idea: IdeiaDto;
	coAuthorsId: string[];
	challengeId?: string;
}

interface ISearchIdeasDTO {
  query?: string;
  categoriesId?: number[];
  startDate?: string;
  endDate?: string;
  stageIds?: number[];
  page?: number;
  itemsPerPage?: number;
	ideaId?: string;
	challengeId?: string;
}

export interface IReportParams {
  startDate?: string;
  endDate?: string;
  authorId?: number;
  startStageDate?: string;
  endStageDate?: string;
  withoutScore?: boolean;
  minScore?: number;
  maxScore?: number;
  stageIds?: number[];
}

class IdeaProvider extends Envoy {

	public async getAllIdeas(
		{
			page,
			itemsPerPage,
			q,
			stageId,
			ideaId,
		}
			: IIdeiasDTO): Promise<IPageData<Array<IdeiaDto>>> {
		try {
			const paginationString = this.buildQueryString({ page, itemsPerPage, q, stageId, ideaId });

			const token = localStorage.getItem("token");
			if (!token) {
				throw new Error("Token não encontrado.");
			}

			this.conn.defaults.headers.Authorization = `Bearer ${token}`;

			const response = await this.conn.get(`/idea${paginationString}`);

			if (response.data.messages?.length)
				throw new Error(response.data.messages.join("\n"));
			return response.data.data;
		} catch (error) {
			console.error("Erro ao obter ideias:", error);
			throw error;
		}
	}

	public async searchIdeas({
    query,
    categoriesId,
    startDate,
    endDate,
    stageIds,
    page = 1,
    itemsPerPage = 10,
		ideaId,
		challengeId,
  }: ISearchIdeasDTO): Promise<IPageData<Array<IdeiaDto>>> {
    try {
      const paginationString = this.buildQueryString({
        query,
        categoriesId,
        startDate,
        endDate,
        stageIds,
        page,
        itemsPerPage,
				ideaId,
				challengeId,
      });

      const response = await this.conn.get(`/idea/search${paginationString}`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join("\n"));
      return response.data.data;
    } catch (error) {
      console.error("Erro ao buscar ideias:", error);
      throw error;
    }
  }

	public async getOne(ideaId: string): Promise<IdeiaDto> {
		try {
			const response = await this.conn.get(`/idea/${ideaId}`);

			if (response.data.messages?.length)
				throw new Error(response.data.messages.join("\n"));
			return response.data.data;
		} catch (error) {
			console.error("Erro ao buscar ideia:", error);
			throw error;
		}
	}

	public async getAllRelated(ideaId: string): Promise<Array<IdeiaDto>> {
    try {
      const response = await this.conn.get(`/idea/related/${ideaId}`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join("\n"));
      return response.data.data;
    } catch (error) {
      console.error("Erro ao buscar ideias relacionadas:", error);
      throw error;
    }
  }

	public async addRelatedIdea(ideaId: string, relatedIdeaId: string): Promise<void> {
    try {
      const response = await this.conn.get(`/idea/related/${ideaId}/with/${relatedIdeaId}`);
      if (response.data.messages?.length)
        throw new Error(response.data.messages.join("\n"));

    } catch (error) {
      console.error("Erro ao adicionar ideia relacionada:", error);
      throw error;
    }
  }

  public async removeRelatedIdea(ideaId: string, relatedIdeaId: string): Promise<void> {
    try {
      const response = await this.conn.delete(`/idea/related/${ideaId}/with/${relatedIdeaId}`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join("\n"));
    } catch (error) {
      console.error("Erro ao remover ideia relacionada:", error);
      throw error;
    }
  }

	public async getMyIdeas(
		{
			page,
			itemsPerPage,
		}
			: IPaginationDTO): Promise<IPageData<Array<IdeiaDto>>> {
		try {
			const paginationString = this.buildQueryString({ page, itemsPerPage });

			const token = localStorage.getItem("token");
			if (!token) {
				throw new Error("Token não encontrado.");
			}

			this.conn.defaults.headers.Authorization = `Bearer ${token}`;

			const response = await this.conn.get(`/idea/my${paginationString}`);

			if (response.data.messages?.length)
				throw new Error(response.data.messages.join("\n"));
			return response.data.data;
		} catch (error) {
			console.error("Erro ao obter minhas idéias:", error);
			throw error;
		}
	}

	public async createOrUpdate(payload: CreateIdeiaDto): Promise<IdeiaDto> {
		try {
			const response = await this.conn.post("/idea", payload);

			if (response.data.messages?.length)
				throw new Error(response.data.messages.join("\n"));
			return response.data.data;
		} catch (error) {
			console.error("Erro ao criar/alterar idéia:", error);
			throw error;
		}
	}


	public async delete(ideiaId: string): Promise<void> {
		try {
			const response = await this.conn.delete(`/idea/${ideiaId}`);
			if (response.data.messages?.length) {
				throw new Error(response.data.messages.join("\n"));
			}
		} catch (error) {
			console.error("Erro ao deletar fase:", error);
			throw error;
		}
	}

	public async uploadImage(ideiaId: string, image: UploadFile): Promise<void> {
		try {
			const formData = await this.convertUploadFileToFormData(image);

			const response = await this.conn.post(`/idea/${ideiaId}/attach`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			if (response.data.messages?.length) {
				throw new Error(response.data.messages.join("\n"));
			}
		} catch (error) {
			console.error("Erro ao fazer upload de imagem:", error);
			throw error;
		}
	}

	public async replaceOrUploadImage(ideiaId: string, imageId: string, image: UploadFile): Promise<void> {
		try {
			const formData = this.convertUploadFileToFormData(image);

			const response = await this.conn.post(`/idea/${ideiaId}/attach/${imageId}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			if (response.data.messages?.length) {
				throw new Error(response.data.messages.join("\n"));
			}
		} catch (error) {
			console.error("Erro ao fazer upload de imagem:", error);
			throw error;
		}
	}

	public async getImages(ideiaId: string): Promise<MetaSimplificadoDto[]> {
		try {
			const response = await this.conn.get(`/idea/${ideiaId}/meta/images`);

			if (response.data.messages?.length)
				throw new Error(response.data.messages.join("\n"));
			return response.data.data;
		} catch (error) {
			console.error("Erro ao buscar imagens da idéia:", error);
			throw error;
		}
	}

	private async convertUploadFileToFormData(uploadFile: UploadFile): Promise<FormData> {
		const formData = new FormData();

		// Verifica se o arquivo existe e é do tipo RcFile
		if (uploadFile.originFileObj) {
			const file = uploadFile.originFileObj as RcFile;
			const blob = new Blob([file], { type: file.type });
			formData.append("file", blob, file.name);
		} else if (uploadFile.url) {
			const response = await fetch(uploadFile.url);
			const blob = await response.blob();
			formData.append("file", blob, uploadFile.name);
		} else {
			console.error("O arquivo não é válido ou não foi encontrado.");
		}

		return formData;
	}

	public async deleteImage(imageId: string): Promise<void> {
		try {
			const response = await this.conn.delete(`/idea/image/${imageId}`);
			if (response.data.messages?.length) {
				throw new Error(response.data.messages.join("\n"));
			}
		} catch (error) {
			console.error("Erro ao deletar imagem:", error);
			throw error;
		}
	}

	public async getAll(
		{
			page,
			itemsPerPage,
		}
			: IPaginationDTO): Promise<IPageData<Array<IdeiaDto>>> {
		try {
			const paginationString = this.buildQueryString({ page, itemsPerPage });

			const token = localStorage.getItem("token");
			if (!token) {
				throw new Error("Token não encontrado.");
			}

			this.conn.defaults.headers.Authorization = `Bearer ${token}`;

			const response = await this.conn.get(`/idea${paginationString}`);

			if (response.data.messages?.length)
				throw new Error(response.data.messages.join("\n"));
			return response.data.data;
		} catch (error) {
			console.error("Erro ao obter minhas idéias:", error);
			throw error;
		}
	}

	public async generateReport(params: IReportParams): Promise<IdeiaDto[]> {
		try {
			const token = localStorage.getItem("token");
			if (!token) {
				throw new Error("Token não encontrado.");
			}

			this.conn.defaults.headers.Authorization = `Bearer ${token}`;
				const queryString = this.buildQueryString(params);
				const response = await this.conn.get(`/idea/report${queryString}`);

				if (response.data.messages?.length) {
						throw new Error(response.data.messages.join("\n"));
				}
				return response.data.data;
		} catch (error) {
				console.error("Erro ao gerar relatório de ideias:", error);
				throw error;
		}
}

}

export default IdeaProvider;