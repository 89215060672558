import React, { useEffect, useState } from "react";
import { Row, Col, Checkbox, Spin, Radio } from "antd";
import { ExplorerChallengesContainer, Sidebar, Content } from "./styles";
import InfiniteScroll from "react-infinite-scroll-component";
import ChallengeDto from "../../../shared/dto/ChallengeDto";
import ChallengeProvider from "../../../shared/provider/ChallengeProvider";
import ChallengeCard from "../components/ChallengeCard/ChallengeCard";
import { LoadingOutlined } from '@ant-design/icons';

const challengeProvider = new ChallengeProvider();

function ExplorarDesafios() {
  const [challenges, setChallenges] = useState<ChallengeDto[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [activeFilter, setActiveFilter] = useState<boolean | undefined>(undefined);
  const [filterValue, setFilterValue] = useState<'all' | 'active' | 'inactive'>("all");
  const lastPageRef = React.useRef<number>(0);

  useEffect(() => {
    onInit();
  }, [activeFilter]);

  async function onInit() {
    await fetchChallenges(1, filterValue);
  }

  async function fetchChallenges(pageParam: number, activeFilter?: "all" | "active" | "inactive", renew?: boolean) {
    try {
      if (lastPageRef.current === pageParam) return;
      const active = activeFilter === "all" ? undefined : activeFilter === "active" ? true : false;
      const data = await challengeProvider.getAll({
        page: pageParam || page,
        itemsPerPage: 10,
        ...(active !== undefined && { active }),
      });
      
      if (data.totalPages <= pageParam) {
        setHasMore(false);
      }

      const filteredChallenges = removeDuplicates([...challenges, ...data.data]);

      setChallenges(renew ? data.data : filteredChallenges);
      setPage(pageParam);
      lastPageRef.current = pageParam;
    } catch (error) {
      console.error("Erro ao buscar desafios:", error);
    }
  }

  function removeDuplicates(array: ChallengeDto[]) {
    return array.filter((challenge, index, self) => 
      index === self.findIndex((t) => (
        t.challengeId === challenge.challengeId
      ))
    );
  }

  function handleFilterChange(e: any) {
    const value = e.target.value;
    setFilterValue(value)
    setActiveFilter(value === "all" ? undefined : value === "active" ? true : false);
    lastPageRef.current = 0;
    setChallenges([]);
    fetchChallenges(1, value, true);
  }

  return (
    <ExplorerChallengesContainer>
      <Row>
        <Col xs={24} lg={4}>
          <Sidebar>
            <Radio.Group onChange={handleFilterChange} value={activeFilter === undefined ? "all" : activeFilter ? "active" : "inactive"}>
              <Radio value="all">Mostrar todos os desafios</Radio>
              <Radio value="active">Mostrar apenas desafios ativos</Radio>
              <Radio value="inactive">Mostrar apenas desafios inativos</Radio>
            </Radio.Group>
          </Sidebar>
        </Col>
        <Col xs={24} lg={20}>
          <Content>
            <InfiniteScroll
              dataLength={challenges.length}
              next={() => fetchChallenges(page + 1, filterValue)}
              hasMore={hasMore}
              loader={<Spin indicator={<LoadingOutlined spin />} size="large" fullscreen/>}
              endMessage={<p style={{ textAlign: "center" }}>Você viu todos os desafios!</p>}
            >
              <Row gutter={[16, 16]} style={{ margin: "8px"}}>
                {challenges.map((challenge) => (
                  <Col key={challenge.challengeId} xs={24}>
                    <ChallengeCard challenge={challenge} />
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>
          </Content>
        </Col>
      </Row>
    </ExplorerChallengesContainer>
  );
}

export default ExplorarDesafios;