import { useEffect, useState } from "react";
import AuthProvider from "../shared/provider/AuthProvider";
import { Navigate, useNavigate } from "react-router-dom";
import { LoadingSpinner, ProtectedContainer } from "../styles/protected";
import PermissionValidator from "../shared/guard/PermissionValidator";
import { ROLES } from "../shared/guard/Roles";
import RoleProvider from "../shared/provider/RoleProvider";

function Protected({
	page,
	roles,
}: {
	readonly page: JSX.Element;
	readonly roles?: ROLES[];
}): JSX.Element | null {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [hasAccess, setHasAccess] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const authProvider = new AuthProvider();
	const roleProvider = new RoleProvider();
	const permissionValidator = new PermissionValidator();

	useEffect(() => {
		const checkAuth = async () => {
			try {
				const isLogged = await authProvider.checkLogin();
				if (isLogged && roles && roles.length) {
					await roleProvider.getUserRole();
					await roleProvider.getUserPermissions();
					const hasAccess = permissionValidator.hasAccess(roles);
					if (!hasAccess) {
						setHasAccess(false);
						setIsLoading(false);
						return;
					}
					setHasAccess(true);
				}
				setIsAuthenticated(isLogged);
				setIsLoading(false);
			} catch (error) {
				setIsLoading(true);
			}
		};

		checkAuth();
	}, []);

	if (isLoading) {
		return (
			<ProtectedContainer>
				<LoadingSpinner />
			</ProtectedContainer>
		);
	}

	if (!hasAccess && roles && roles.length) {
		return (
			<Navigate
				to="/"
				replace={true}
			/>
		);
	}

	if (!isAuthenticated) {
		return (
			<Navigate
				to="/entrar"
				replace={true}
			/>
		);
	}

	return page;
}

export default Protected;
