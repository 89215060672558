import React from "react";
import {
    AddDimensionCriteria,
    AddDimensionFormContainer,
    AddDivider,
} from "./styles";
import DefaultButton from "../../../../../../../shared/util/DefaultButton";
import DimensionProvider from "../../../../../../../shared/provider/DimensionProvider";
import DimensionInput from "../../DimensionInput";
import {DimensionCategoryDto} from "../../../../../../../shared/dto/DimensionCategoryDto";
import {Button, Flex, Input, Space, Tooltip, Spin, message} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import generateUUID from "../../../../../../../shared/constants/uuid";
import {validateDimension} from "../validation";
import {useIdeia} from "../../../../../../../shared/context/ideia/provider";
import TextArea from "antd/es/input/TextArea";

interface AddDimensionCategoryItem extends DimensionCategoryDto {
    key: string;
}

function AddDimensionForm({
                              setOpen,
                              isLoading = false,
                              setLoading,
                              setSuccessMessage,
                              requireDimensionList,
                          }: {
    setOpen: React.Dispatch<boolean>;
    isLoading?: boolean;
    setLoading: React.Dispatch<boolean>;
    setSuccessMessage: React.Dispatch<string>;
    requireDimensionList: () => Promise<void>;
}) {
    const [dimensionName, setDimensionName] = React.useState<string>("");
    const [dimensionDescription, setDimensionDescription] =
        React.useState<string>("");

    const [dimensionWeight, setDimensionWeight] = React.useState<string>("");

    const [dimensionCategories, setDimensionCategories] = React.useState<AddDimensionCategoryItem[]>([
        {title: "120", value: 0, key: generateUUID()}
    ] as AddDimensionCategoryItem[]);

    const dimensionProvider = new DimensionProvider();

    const {messageApi} = useIdeia();

    function addCategory(category: AddDimensionCategoryItem) {
        setDimensionCategories([...dimensionCategories, category]);
    }

    function removeCategory(category: AddDimensionCategoryItem) {
        setDimensionCategories(dimensionCategories.filter((c) => c.key !== category.key));
    }

    function updateCategory(category: AddDimensionCategoryItem) {
        const index = dimensionCategories.findIndex((c) => c.key === category.key);
        const newCategories = [...dimensionCategories];
        newCategories[index] = category;
        setDimensionCategories(newCategories);
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        createDimension();
    }

    function removeEmptyCategories() {
        const filtered = dimensionCategories.filter((category) => category.title !== "");
        setDimensionCategories(filtered);
        return filtered;
    }

    function isOnlyWhitespace(str: string): boolean {
        return str.trim().length === 0;
    }

    async function createDimension() {
        setLoading(true);

        try {
            if (isOnlyWhitespace(dimensionName)) {
                messageApi.warning("O título da dimensão não pode conter apenas espaços em branco.");
                setLoading(false);
                return;
            }

            if (isOnlyWhitespace(dimensionDescription)) {
                messageApi.warning("A descrição da dimensão não pode conter apenas espaços em branco.");
                setLoading(false);
                return;
            }

            const filtered = removeEmptyCategories();

            if (filtered.some(category => isOnlyWhitespace(category.title))) {
                messageApi.warning("Os critérios não podem conter apenas espaços em branco.");
                setLoading(false);
                return;
            }

            const payload = {
                title: dimensionName,
                description: dimensionDescription,
                weight: parseInt(dimensionWeight),
                categories: filtered.map((category) => ({
                    title: category.title,
                    value: category.value,
                })),
            }

            const errors = validateDimension(payload, "insert");

            if (errors.length) {
                errors.forEach((error) => {
                    messageApi.warning(error.message);
                });
                return;
            }

            const response = await dimensionProvider.createOrUpdate(payload)

            if (!response) throw new Error("Erro ao salvar dimensão");
            setSuccessMessage("Dimensão salva com sucesso!");
            setTimeout(() => requireDimensionList(), 550)
        } catch (error) {
            message.error("Erro ao criar dimensão");
        } finally {
            setLoading(false);
        }
    }

    const handleWeightChange = (value: string) => {
        const numericValue = Number(value);
        if (numericValue > 50) {
            setDimensionWeight("50");
        } else if (numericValue < 0) {
            setDimensionWeight("0");
        } else {
            setDimensionWeight(value);
        }
    };

    const handleCategoryValueChange = (value: string, category: AddDimensionCategoryItem) => {
        const numericValue = Number(value);
        if (numericValue > 50) {
            updateCategory({...category, value: 50});
        } else if (numericValue < 0) {
            updateCategory({...category, value: 0});
        } else {
            updateCategory({...category, value: numericValue});
        }
    };

    return (
        <AddDimensionFormContainer onSubmit={handleSubmit}>
            <Flex gap={6} style={{marginBottom: 25, flexDirection: "column" }}>
                    <label className={"input-label"} htmlFor="title">
                    Título da dimensão*:
                    </label>
                    <TextArea
                            id="nome"
                            showCount
                            placeholder="Informe o título da dimensão"
                            maxLength={200}
                            value={dimensionName}
                            onChange={(e) => setDimensionName(e.target.value)}
                            autoSize={{ minRows: 1, maxRows: 3 }}
                            disabled={isLoading}
                    />
            </Flex>
            <AddDivider/>

            <DimensionInput
                label="Adicione uma descrição"
                value={dimensionDescription}
                setValue={setDimensionDescription}
                placeholder="Descreva sobre a dimensão"
                isLoading={isLoading}
                width="100%"
                height="130px"
                limitCharacters={400}
                hasCharacters={true}
                type="textarea"
            />

            <DimensionInput
                label="Selecione o peso"
                value={dimensionWeight}
                setValue={handleWeightChange}
                placeholder="0"
                isLoading={isLoading}
                width="12%"
                type="number"
            />

            <Flex gap={46.5} style={{alignContent: "center"}}>
                <Flex gap={135}>
                    <span>Critérios</span>
                    <span>Peso</span>
                </Flex>
                <Tooltip zIndex={999999} title="Adicionar critério">
                <Button
                    type="primary"
                    size="small"
                    shape="circle"
                    icon={<PlusOutlined/>}
                    onClick={() => addCategory({title: "", value: 0, key: generateUUID()})}
                />
                </Tooltip>
            </Flex>
            <AddDimensionCriteria>
                {dimensionCategories.map((category) => (
                    <Flex gap={12} style={{
                        marginTop: 5,
                        alignContent: "center"
                    }}>
                        <Space.Compact>
                            <Input
                                key={category.key + "title"}
                                style={{width: 193.08}}
                                placeholder="Descreva sobre o critério"
                                maxLength={150}
                                onBlur={(e) => updateCategory({...category, title: e.target.value})}
                            />

                            <Input
                                key={`${category.title}#${category.dimensionCategoryId}#value`}
                                style={{width: 73.92}}
                                type="number"
                                placeholder="Valor"
                                max={50}
                                min={0}
                                defaultValue={category.value}
                                onBlur={(e) => handleCategoryValueChange(e.target.value, category)}
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Space.Compact>
                        <Flex gap={10}
                              style={{flexDirection: "column", alignContent: "center", justifyContent: "center"}}>
                            <Tooltip zIndex={999999} title="Remover critério">
                                <Button
                                    danger
                                    size="small"
                                    shape="circle"
                                    icon={<MinusOutlined/>}
                                    onClick={() => removeCategory(category)}
                                />
                            </Tooltip>
                        </Flex>
                    </Flex>
                ))}
            </AddDimensionCriteria>

            <footer>
                <DefaultButton
                    width="125px"
                    height="36px"
                    fontSize="1em"
                    withOutBackground={true}
                    params={{
                        onClick: () => setOpen(false),
                    }}
                >
                    Voltar
                </DefaultButton>
                <DefaultButton
                    width="125px"
                    height="36px"
                    fontSize="1em"
                    params={{
                        type: "submit",
                        disabled: isLoading,
                    }}
                >
                    {isLoading ? <Spin /> : "Salvar"}
                </DefaultButton>
            </footer>
        </AddDimensionFormContainer>
    );
}

export default AddDimensionForm;