import styled from "styled-components";

export const AvatarContainer = styled.div``
interface AvatarProps {
  shape: 'square' | 'circle';
  src?: string;
  size: number | string;
}

export const AvatarDiv = styled.div<AvatarProps>`
  width: ${(props) => (typeof props.size === 'number' ? `${props.size}px` : props.size)};
  height: ${(props) => (typeof props.size === 'number' ? `${props.size}px` : props.size)};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  font-size: ${(props) => (typeof props.size === 'number' ? `${props.size / 2}px` : `calc(${props.size} / 2)`)};
  color: white;
  text-transform: uppercase;
  border-radius: ${(props) => (props.shape === 'circle' ? '50%' : '8%')};
  overflow: hidden;
`;

interface AvatarImgProps {
  shape: 'square' | 'circle';
  src: string;
  size: number | string;
}

export const AvatarImg = styled.img<AvatarImgProps>`
  width: ${(props) => (typeof props.size === 'number' ? `${props.size}px` : props.size)};
  height: ${(props) => (typeof props.size === 'number' ? `${props.size}px` : props.size)};
  border-radius: ${(props) => (props.shape === 'circle' ? '50%' : '8%')};
  object-fit: cover;
`;