import styled from "styled-components";

export const ModalContent = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;

  h1, h2, h3, h4, h5, h6 {
    margin: 10px 0;
  }

  p {
    margin: 10px 0;
  }
`;