import React from "react";
import { ExcluirStageContainer } from "./styles";
import Modal from "../../../../../../shared/util/Modal";
import DefaultButton from "../../../../../../shared/util/DefaultButton";
import { ResponseUseTermDto } from "../../../../../../shared/dto/ResponseUseTermDto";
import UseTermProvider from "../../../../../../shared/provider/UseTermProvider";

function DeleteUseTerm({
											 open,
											 setOpen,
											 useTerm,
											 onFinished,
										 }: Readonly<{
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	useTerm: ResponseUseTermDto;
	onFinished?: () => void;
}>) {
	const [successMessage, setSuccessMessage] = React.useState("");
	const useTermProvider = new UseTermProvider();
	React.useEffect(() => {
		if (!open) {
			setSuccessMessage("");
		}
	}, [open]);

	async function handleExcluir() {
		if (useTerm && useTerm.useTermId) {
			try {
				await useTermProvider.deleteUseTerm(useTerm.useTermId);
				setSuccessMessage("Termo de uso excluido com sucesso.");
			} catch (error) {
				console.error("Erro ao excluir fase:", error);
			}
		}
	}

	return (
		<ExcluirStageContainer>
			<Modal
				open={open}
				setOpen={setOpen}
			>
				{!successMessage && (
					<>
						<div className="centered-message">
							<h3>Excluir termo de uso?</h3>
						</div>

						<footer>
							<DefaultButton
								width="115px"
								fontSize="1em"
								height="36px"
								withOutBackground={true}
								params={
									{
										onClick: () => setOpen(false),
									}
								}
							>
								Voltar
							</DefaultButton>
							<DefaultButton
								width="115px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: handleExcluir,
								}}
							>
								Excluir
							</DefaultButton>
						</footer>
					</>
				)}

				{successMessage && (
					<div className="edit-user-success-step">
						<div className="success-message-container">
							<h2>{successMessage}</h2>
						</div>
						<div>
							<DefaultButton
								withOutBackground={true}
								width="115px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: () => {
										setOpen(false);
										onFinished && onFinished();
									},
								}}
							>
								Fechar
							</DefaultButton>
						</div>
					</div>
				)}
			</Modal>
		</ExcluirStageContainer>
	);
}

export default DeleteUseTerm;