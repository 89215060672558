import React from "react";
import { AddStageFormContainer, AddDivider } from "./styles";
import DefaultButton from "../../../../../../../shared/util/DefaultButton";
import StageProvider from "../../../../../../../shared/provider/StageProvider";
import StageInput from "../../StageInput";
import { Flex, message } from "antd";
import TextArea from "antd/es/input/TextArea";

function AddStageForm({
												setOpen,
												isLoading = false,
												setLoading,
												setSuccessMessage,
												requireStageList,
											}: {
	setOpen: React.Dispatch<boolean>;
	isLoading?: boolean;
	setLoading: React.Dispatch<boolean>;
	setSuccessMessage: React.Dispatch<string>;
	requireStageList: () => Promise<void>;
}) {
	const [stageName, setStageName] = React.useState<string>("");
	const [stageDescription, setStageDescription] = React.useState<string>("");
	const [stageOrder, setStageOrder] = React.useState<string>("");

	const stageProvider = new StageProvider();

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		createStage();
	}

	async function createStage() {
		setLoading(true);

		try {
			if (!stageName || !stageDescription || !stageOrder) {
				message.error("Preencha todos os campos");
				return;
			}

			const orderValue = Math.min(Number(stageOrder), 50);

			const response = await stageProvider.createOrUpdate({
				title: stageName,
				description: stageDescription,
				order: orderValue,
			});
			if (!response) throw new Error("Erro ao criar fase da idéia");
			setSuccessMessage("Fase da ideia salva com sucesso!");
			setTimeout(() => requireStageList(), 550);
		} catch (error) {
			message.error("Erro ao criar fase da idéia");
		} finally {
			setLoading(false);
		}
	}

	const handleOrderChange = (value: string) => {
		const numericValue = Number(value);
		if (numericValue > 50) {
			setStageOrder("50");
		} else if (numericValue < 0) {
			setStageOrder("0");
		} else {
			setStageOrder(value);
		}
	};
	return (
		<AddStageFormContainer onSubmit={handleSubmit}>
		<Flex gap={6} style={{marginBottom: 25, flexDirection: "column" }}>
					<label className={"input-label"} htmlFor="title">
						Nome da fase*:
					</label>
					<TextArea
							id="nome"
							showCount
							placeholder="Informe o nome da fase"
							maxLength={100}
							value={stageName}
							onChange={(e) => setStageName(e.target.value)}
							autoSize={{ minRows: 1, maxRows: 3 }}
							disabled={isLoading}
					/>
			</Flex>

			<AddDivider />

			<StageInput
				label="Adicione uma descrição"
				value={stageDescription}
				setValue={setStageDescription}
				placeholder="Descreva a fase"
				isLoading={isLoading}
				width="100%"
				height="130px"
				limitCharacters={400}
				type="textarea"
			/>

			<StageInput
				label="Ordem"
				value={stageOrder}
				setValue={handleOrderChange}
				placeholder="0"
				isLoading={isLoading}
				width="12%"
				type="number"
			/>

			<footer>
				<DefaultButton
					width="125px"
					height="36px"
					fontSize="1em"
					withOutBackground={true}
					params={{
						onClick: () => setOpen(false),
					}}
				>
					Voltar
				</DefaultButton>
				<DefaultButton
					width="125px"
					height="36px"
					fontSize="1em"
					params={{
						type: "submit",
					}}
				>
					Cadastrar
				</DefaultButton>
			</footer>
		</AddStageFormContainer>
	);
}

export default AddStageForm;
