import { Badge, Button, Flex } from "antd";
import generateUUID from "../../../../shared/constants/uuid";
import { StageDto } from "../../../../shared/dto/StageDto";
import { StageListContainer } from "./styles";
import React from "react";

interface StageListProps {
  stages: StageDto[];
}

function StageList({ stages }: StageListProps): JSX.Element {
  const [orderedStages, setOrderedStages] = React.useState<StageDto[]>([]);
  const [selectedStage, setSelectedStage] = React.useState<string>("");
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    const orderedStages = [...stages].sort((a, b) => a.order - b.order);
    setOrderedStages(orderedStages);
  }, [stages]);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if ((event.target as HTMLElement).closest('.stage-list-container') === null) {
      setIsVisible(false);
    }
  };

  React.useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  return (
    <>
      <StageListContainer className={`stage-list-container ${isVisible ? 'visible' : ''}`} onClick={handleToggle}>
        {orderedStages.map((stage) => (
          <li key={generateUUID()}>
            <Flex gap={5} style={{ alignItems: "center" }}>
              <div>
                <Button
                  type="link"
                  style={{
                    border: selectedStage === stage.stageId ? "1px solid #1351b4" : "none",
                    cursor: "unset"
                  }}
                >
                  <span style={{ color: "#303030" }}>{stage.title}</span>
                  {stage.totalIdeas ? (
                    <Badge
                      count={stage.totalIdeas}
                      showZero
                      color="#1351b4"
                      style={{ padding: "0 14px", borderRadius: "6px" }}
                    />
                  ) : ""}
                </Button>
              </div>
            </Flex>
          </li>
        ))}
      </StageListContainer>
    </>
  );
}

export default StageList;
