import React, { useEffect, useState, useCallback } from "react";
import {
    EditCategoryFormContainer,
    EditDivider,
    ShowCategoryCoverImage,
} from "./styles";
import CategoryInput from "../../CategoryInput";
import { Upload, UploadFile, message, ConfigProvider, UploadProps, GetProp } from "antd";
import DefaultButton from "../../../../../../../shared/util/DefaultButton";
import { FaTrash } from "react-icons/fa";
import CategoryProvider from "../../../../../../../shared/provider/CategoryProvider";
import { CategoryDto } from "../../../../../../../shared/dto/CategoryDto";
import TextArea from "antd/es/input/TextArea";
import { Flex, Spin } from "antd";
import ImgCrop from "antd-img-crop";
import ptBR from "antd/lib/locale/pt_BR";

function EditCategoryForm({
    setOpen,
    isLoading = false,
    setLoading,
    setSuccessMessage,
    requireCategoryList,
    category,
    setEditMode,
}: {
    readonly setOpen: React.Dispatch<boolean>;
    readonly isLoading?: boolean;
    readonly setLoading: React.Dispatch<boolean>;
    readonly setSuccessMessage: React.Dispatch<string>;
    readonly requireCategoryList: () => Promise<void>;
    readonly category: CategoryDto;
    readonly setEditMode: React.Dispatch<boolean>;
}) {
    const [coverImageUrl, setCoverImageUrl] = useState<string>(category.coverImage ?? "");
    const [categoryName, setCategoryName] = useState<string>(category.name ?? "");
    const [categoryDescription, setCategoryDescription] = useState<string>(category.description ?? "");
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);

    const categoryProvider = new CategoryProvider();

    useEffect(() => {
        if (fileList[0] && fileList[0].status === "done") {
            setCoverImageUrl(fileList[0].url as string);
        }
    }, [fileList]);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        updateCategory();
    }

    async function updateCategory() {
        if (!categoryName || !categoryDescription) {
            message.error("Preencha todos os campos obrigatórios");
            return;
        }
        if (!category.coverImage && !fileList[0]) {
            message.error("Insira uma imagem de capa");
            return;
        }

        if (categoryName === category.name && categoryDescription === category.description && !fileList[0]) {
            message.info("Nenhuma alteração foi feita");
            return;
        }

        setLoading(true);

        try {
            const result = await categoryProvider.update({
                categoryId: category.categoryId,
                name: categoryName,
                description: categoryDescription,
            });
            if (result && fileList[0]) {
                const { categoryId } = result;
                if (categoryId) {
                    await saveCoverImage();
                }
            }
            setSuccessMessage("Alterações salvas com sucesso!");
            setTimeout(() => requireCategoryList(), 550);
        } catch (error) {
            message.error("Erro ao criar categoria");
        } finally {
            setLoading(false);
        }
    }

    function deleteLocalCover() {
        setCoverImageUrl("");
        setFileList([]);
    }

    async function saveCoverImage() {
        if (!category.categoryId || !fileList[0]) {
            message.error("Falha ao salvar imagem de capa");
            return;
        }
        const file = fileList[0];

        if (file.originFileObj && file.originFileObj.size > 5000000) {
            message.info("Imagem muito grande. Insira uma imagem de até 5MB");
            return;
        }

        if (file && file.type) {
            const supportedTypes = ["image/jpeg", "image/jpg", "image/png"];
            if (!supportedTypes.includes(file.type)) {
                message.error("Formato de arquivo não suportado. Por favor, selecione um arquivo JPG, JPEG ou PNG.");
                return;
            }
        }

        setLoading(true);

        if (file) {
            try {
                await categoryProvider.saveCoverImage(category.categoryId, file);
                message.success("Imagem de capa salva com sucesso");
                setTimeout(() => requireCategoryList(), 550);
            } catch (error) {
                message.error("Erro ao salvar imagem de capa");
            } finally {
                setLoading(false);
            }
        } else {
            message.error("Erro ao salvar imagem de capa");
            setLoading(false);
        }
    }

    const beforeUpload = (file: File) => {
        const isImage = file.type.startsWith("image/");
        if (!isImage) {
            message.error("Você só pode fazer upload de imagens!");
            return Upload.LIST_IGNORE;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const img = new Image();
            img.src = reader.result as string;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                if (ctx) {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    canvas.toBlob(
                        (blob) => {
                            if (blob) {
                                const newFile: UploadFile<any> = {
                                    uid: new Date().getTime().toString(),
                                    name: file.name,
                                    status: "done",
                                    url: URL.createObjectURL(blob),
                                };
                                setFileList((prevFileList) => [...prevFileList, newFile]);
                            }
                        },
                        file.type
                    );
                }
            };
        };
        return false;
    };

    const onChangeFileUpload: UploadProps["onChange"] = ({ fileList: newFileList }) => {
        if (newFileList.length > fileList.length) return;
        setFileList(newFileList);
    };

    return (
        <EditCategoryFormContainer onSubmit={handleSubmit}>
            <Flex gap={6} style={{ marginBottom: 20, flexDirection: "column" }}>
                <label className={"input-label"} htmlFor="title">
                    Nome da categoria*:
                </label>
                <TextArea
                    id="nome"
                    showCount
                    placeholder="Digite aqui"
                    maxLength={200}
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    autoSize={{ minRows: 1, maxRows: 3 }}
                />
            </Flex>

            <ImgCrop aspect={16 / 9} rotationSlider>
                <Upload
                    beforeUpload={beforeUpload}
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChangeFileUpload}
                    maxCount={1}
                    showUploadList={false}
                >
                    {fileList[0] ? null : <span>Enviar imagem (1200x675)<br />Formatos aceitos: .jpg, .jpeg, .png</span>}
                </Upload>
            </ImgCrop>

            {!fileList[0] ? (
                <span>Insira uma imagem de capa para esta categoria</span>
            ) : (
                <ShowCategoryCoverImage
                    coverImage={`${coverImageUrl}`}
                ></ShowCategoryCoverImage>
            )}


            <ConfigProvider locale={ptBR}>
                <div id="upload-zone">
                    <button
                        className="category-delete-cover"
                        type="button"
                        onClick={deleteLocalCover}
                        disabled={!fileList[0]}
                        style={{ marginTop: "10px" }}
                    >
                        {" "}
                        <FaTrash /> Excluir capa
                    </button>
                </div>
            </ConfigProvider>

            <EditDivider />

            <CategoryInput
                label="Adicione uma descrição (obrigatório)"
                value={categoryDescription}
                setValue={setCategoryDescription}
                placeholder="Digite aqui"
                isLoading={isLoading}
                width="100%"
                height="130px"
                limitCharacters={400}
                type="textarea"
            />

            <footer>
                <DefaultButton
                    width="125px"
                    height="36px"
                    fontSize="1em"
                    withOutBackground={true}
                    params={{
                        onClick: () => setEditMode(false),
                    }}
                >
                    Voltar
                </DefaultButton>
                <DefaultButton
                    width="125px"
                    height="36px"
                    fontSize="1em"
                    params={{
                        type: "submit",
                        disabled: isLoading,
                    }}
                >
                    {isLoading ? <Spin /> : "Salvar"}
                </DefaultButton>
            </footer>
        </EditCategoryFormContainer>
    );
}

export default EditCategoryForm;
