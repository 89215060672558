import React from "react";
import { Card, Col, Row, Typography, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ChallengeDto from "../../../../shared/dto/ChallengeDto";
import styled from "styled-components";
import { useSelection } from "../../../../shared/context/selection/selectionProvider";

const { Title, Paragraph } = Typography;

interface ChallengeCardProps {
  challenge: ChallengeDto;
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({ challenge }) => {
  const navigate = useNavigate();
  const { setChallenge } = useSelection();

  function handleCardClick() {
    setChallenge(challenge);
    navigate(`/?idDesafio=${challenge.challengeId}`);
  }

  return (
    <StyledCard hoverable onClick={handleCardClick}>
      <StyledRow>
        <Col xs={24} lg={8}>
          <img
            src={`${window.global.env.reactAppApiPublicUrl}/${challenge.coverImage}`}
            alt={challenge.title}
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
          />
        </Col>
        <Col xs={24} lg={16}>
          <div style={{ padding: "0 20px" }}>
            <Title level={4}>{challenge.title}</Title>
            <Paragraph>{challenge.shortDescription}</Paragraph>
            <div>
              <Tag color="blue">{moment(challenge.startDate).format("DD/MM/YYYY")}</Tag>
              <Tag color="red">{moment(challenge.endDate).format("DD/MM/YYYY")}</Tag>
            </div>
          </div>
        </Col>
      </StyledRow>
    </StyledCard>
  );
};

export default ChallengeCard;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
  margin: 0 15px;
`;

const StyledRow = styled(Row)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;