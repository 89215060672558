import { Avatar, ConfigProvider, Divider, Flex, Image, List, Tag } from "antd";
import React from "react";
import { IdeiaDto } from "../../../../../../shared/dto/IdeiaDto";
import { ViewH2, ViewH4, ViewMetaInfo } from "../../styles";
import ptBR from 'antd/lib/locale/pt_BR'
import { useIdeia } from "../../../../../../shared/context/ideia/provider";

interface DetailsViewProps {
  idea: IdeiaDto
}

function DetailsView({ idea }: Readonly<DetailsViewProps>) {
  const { images } = useIdeia();

  return (
    <div>
      <ViewMetaInfo>
        {idea.category && <Tag color="blue">{idea.category.name}</Tag>}
      </ViewMetaInfo>

      <Flex gap={15} style={{flexDirection:'column', marginTop: 20}}>
        <div>
          <ViewH2>Problema identificado</ViewH2>
          {idea.reportedProblem}
          <Divider />
        </div>

        <div>
          <ViewH2>Proposta de solução</ViewH2>
          <div dangerouslySetInnerHTML={{__html: idea.solution}} />
          <Divider />
        </div>

         <ConfigProvider locale={ptBR}>
          <Flex gap={6}>
            <Image.PreviewGroup>
                {images.length > 0 && images.map((pic) => (
                  <Image key={pic.id} width={200} src={`${window.global.env.reactAppApiPublicUrl}/${pic.url}?t${Date.now()}`} />
                ))}
              </Image.PreviewGroup>
          </Flex>
         </ConfigProvider>

        <div>
          <ViewH4>Público alvo</ViewH4>
          {idea.targetAudience}
          <Divider />
        </div>

        {!!idea.coAuthors?.length && <div>
          <ViewH4>Coautores</ViewH4>
          <List
            dataSource={idea.coAuthors}
            renderItem={(item) => (
              <List.Item key={item.email}>
                <List.Item.Meta
                  avatar={<Avatar />}
                  title={<a href="#">{item.name}</a>}
                  description={item.email}
                />
              </List.Item>
            )}
          />
          <Divider />
        </div>}
      </Flex>
    </div>
  );
}

export default DetailsView;