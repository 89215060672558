import React from "react";
import {
	EditarUsuarioContainer,
	EditarUsuarioImagem,
	UserDetails,
} from "./styles";
import UserProvider from "../../../../shared/provider/UserProvider";
import { IUsuarioSimplificado } from "../../../../shared/dto/UsuarioSimplificado";
import EditarUsuarioForm from "./components/editUserForm";
import { UpdateUserDto } from "../../../../shared/dto/UpdateUserDto";
import DefaultButton from "../../../../shared/util/DefaultButton";
import ExcluirUsuarioModal from "../ExcluirUsuario";
import { FaChevronLeft } from "react-icons/fa";
import AuthProvider from "../../../../shared/provider/AuthProvider";
import { useNavigate } from "react-router-dom";
import getLetterColor from "../../../../shared/util/GetLetterColor";
import { EditarFormDivider } from "./components/styles";
import EditUserTabMenu from "./components/TabMenu";
import { Modal } from "antd";

function EditarUsuario({
	open,
	setOpen,
	user,
	requireUserList,
}: {
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	user: IUsuarioSimplificado;
	requireUserList(): Promise<void>;
}) {
	const userProvider = new UserProvider();
	const authProvider = new AuthProvider();
	const [loading, setLoading] = React.useState(false);
	const [openExcluirUsuario, setOpenExcluirUsuario] = React.useState(false);
	const navigator = useNavigate();
	const [successMessage, setSuccessMessage] = React.useState("");
	const [editUserTabActive, setEditUserTabActive] = React.useState("Perfíl");

	React.useEffect(() => {
		if (!open) {
			setSuccessMessage("");
		}
	}, [open]);

	async function updateUser(dto: Partial<UpdateUserDto>) {
		try {
			const data = await userProvider.update(user.id, dto);

			if (!data) return false;
			return true;
		} catch (error) {}
	}

	async function requestUpdate(data: Partial<UpdateUserDto>) {
		setLoading(true);
		const response = await updateUser(data);
		if (response) {
			await requireUserList();
			setSuccessMessage("Alterações salvas com sucesso!");
		}
		const isLogged = await authProvider.checkLogin();
		if (!isLogged) {
			setOpen(false);
			navigator("/entrar");
		}
		setLoading(false);
	}

	async function handleExcluirUsuario() {
		setTimeout(async () => requireUserList(), 250);
		setOpen(false);
	}

	return (
		<EditarUsuarioContainer>
			<Modal
				open={open}
				title={!successMessage ? <h2>Editar usuário</h2> : <></>}
				maskClosable={false}
				onCancel={() => setOpen(false)}
				okButtonProps={{ style: { display: "none"} }}
				cancelButtonProps={{ style: { display: "none"} }}
				width={1280}
			>
				<ExcluirUsuarioModal
					open={openExcluirUsuario}
					setOpen={setOpenExcluirUsuario}
					user={user}
					onFinished={handleExcluirUsuario}
					breadcrumbElement={
						<>
							<FaChevronLeft />
							Voltar para Editar Usuário
						</>
					}
				/>
				{user && !successMessage && (
					<>
						<EditUserTabMenu
							active={editUserTabActive}
							setActive={setEditUserTabActive}
						/>
						{editUserTabActive === "Perfíl" && (
							<>
								<UserDetails>
								<EditarUsuarioImagem
									className={loading ? "skeleton-loading" : ""}
									letterColor={user && user.name ? getLetterColor(user.name.charAt(0)) : ""}
									url={user.profileImage ? `data:image/png;base64,${user.profileImage}` : ""}
								>
									{!user.profileImage && user.name && user.name.length > 0 && user.name.charAt(0)}
								</EditarUsuarioImagem>

									<div>
										<h3>{user.name}</h3>
										<p>{user.position ? `Cargo: ${user.position}` : ""}</p>
										{user.document && <p>CPF: {user.document}</p>}
										<p></p>
									</div>
								</UserDetails>
								<EditarUsuarioForm
									user={user}
									setOpen={setOpen}
									requestUpdate={requestUpdate}
									isLoading={loading}
									handleExcluirUsuario={() => setOpenExcluirUsuario(true)}
								/>
							</>
						)}
					</>
				)}

				{successMessage && (
					<div className="edit-user-success-step">
						<h2>{successMessage}</h2>
						<div>
							<DefaultButton
								withOutBackground={true}
								width="125px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: () => setOpen(false),
								}}
							>
								Fechar
							</DefaultButton>
						</div>
					</div>
				)}
			</Modal>
		</EditarUsuarioContainer>
	);
}

export default EditarUsuario;
