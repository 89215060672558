import React from "react";
import { ChallengeListContainer } from "./styles";
import ChallengeListItem from "./components/ChallengeList";

function ChallengeList({
  openAddChallenge,
  setOpenAddChallenge,
}: Readonly<{
  openAddChallenge: boolean;
  setOpenAddChallenge: React.Dispatch<React.SetStateAction<boolean>>;
}>): JSX.Element {
  return (
    <ChallengeListContainer>
      <ChallengeListItem
        openAddChallenge={openAddChallenge}
        setOpenAddChallenge={setOpenAddChallenge}
      />
    </ChallengeListContainer>
  );
}

export default ChallengeList;