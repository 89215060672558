import React from "react";
import {
	ViewModalContainer,
	ViewModalSection,
	ViewBody,
} from "./styles";
import { ConfigProvider, Modal, Tabs, List, message, Button } from "antd";
import PermissionValidator from "../../../../shared/guard/PermissionValidator";
import { PERMISSIONS } from "../../../../shared/guard/Permissions";
import DetailsView from "./components/Details";
import DefaultButton from "../../../../shared/util/DefaultButton";
import ptBR from "antd/lib/locale/pt_BR";
import { CommentsView } from "./components/Comments";
import { IdeiaDto } from "../../../../shared/dto/IdeiaDto";
import RatingView from "./components/Rating";
import RatingProvider from "../../../../shared/provider/RatingProvider";
import {
	CreateRatingItemDTO,
	RatingDto,
} from "../../../../shared/dto/RatingDto";
import StageProvider from "../../../../shared/provider/StageProvider";
import { StageDto } from "../../../../shared/dto/StageDto";
import RelatedView from "./components/Related";
import { TabItems } from "../ViewModal/constants/tabs";
import IdeaProvider from "../../../../shared/provider/IdeaProvider";

export interface UserValue {
	label: string;
	value: string;
}

interface IdeiaProps {
	ideia: IdeiaDto;
	openModal: boolean;
	setOpenModal: (value: boolean) => void;
	fetchStages: () => void;
}

export interface RatingPayload {
	ideaId: string;
	items: CreateRatingItemDTO[];
	type: "draft" | "final";
}

function NormalView({ ideia, openModal, setOpenModal, fetchStages: countStages}: IdeiaProps): JSX.Element {
  const permissionValidator = new PermissionValidator();
  const ratingProvider = new RatingProvider();
  const ideaProvider = new IdeaProvider();
  const [rating, setRating] = React.useState<RatingDto>({} as RatingDto);
  const [ratingPayload, setRatingPayload] = React.useState<RatingPayload>({} as RatingPayload);
  const [tabKey, setTabKey] = React.useState("details");
  const [openStageModal, setOpenStageModal] = React.useState(false);
  const [stages, setStages] = React.useState<StageDto[]>([]);
  const [currentIdeia, setCurrentIdeia] = React.useState<IdeiaDto>(ideia);

	const [showSuccess, setShowSuccess] = React.useState(false);
	const [successMessage, setSuccessMessage] = React.useState("");

	const [confirmationModalVisible, setConfirmationModalVisible] = React.useState(false);
	const [confirmationAction, setConfirmationAction] = React.useState<() => void>(() => {});
	const [confirmationMessage, setConfirmationMessage] = React.useState("");

	const [isRatingChanged, setIsRatingChanged] = React.useState(false);
	const [pendingTabKey, setPendingTabKey] = React.useState<string | null>(null);

	const confirmationActionRef = React.useRef<() => void>(() => {});

	React.useEffect(() => {
		if (
			!permissionValidator.hasPermission(PERMISSIONS.ACESSAR_DETALHES_IDEIA)
		) {
			setOpenModal(false);
		}
	}, []);

	function onInit() {
		setPendingTabKey(null);
		setIsRatingChanged(false);
		setTimeout(() => setTabKey("details"), 200);
		fetchStages();
	}

	async function getRatingByIdeaId(ideaId: string) {
		try {
			const response = await ratingProvider.getRatingByIdeaId(ideaId);
			setRating(response);
		} catch (error) {
			console.error("Erro ao buscar avaliação:", error);
		}
	}

	async function deleteDraft(ideaId: string) {
		try {
			await ratingProvider.deleteDraft(ideaId);
			await getRatingByIdeaId(ideaId);
			message.success("Rascunho deletado com sucesso!");
		} catch (error) {
			console.error("Erro ao deletar rascunho:", error);
		}
	}

	async function assignScore(ideaId: string) {
		try {
			const response = await ratingProvider.assignScore(ideaId);
			setRating(response);
		} catch (error) {
			console.error("Erro ao atribuir pontuação:", error);
		}
	}

	async function rateIdea(params: RatingPayload) {
		try {
			const { ideaId, items, type } = params;

			if (!ideaId || !items || !items.length) return;

			let response;
			if (type === "draft") {
				response = await ratingProvider.saveDraft(ideaId, items);
			}
			if (type === "final") {
				response = await ratingProvider.saveRating(ideaId, items);
			}
			await assignScore(ideaId);
			if (response) {
				setSuccessMessage(
					params.type === "draft"
						? "Rascunho salvo com sucesso!"
						: "Avaliação salva com sucesso!"
				)
				setShowSuccess(true);
				getRatingByIdeaId(ideaId);
			}
		} catch (error) {
			console.error("Erro ao avaliar ideia:", error);
		}
	}
	React.useEffect(() => {
		if (openModal) {
			onInit();
		}
		setRating({} as RatingDto);
	}, [openModal]);

	React.useEffect(() => {
		if (tabKey === "rating") {
			getRatingByIdeaId(ideia.ideaId as string);
		}
	}, [tabKey]);

	async function fetchStages() {
		const stageProvider = new StageProvider();
		try {
			const stages = await stageProvider.getAll();
			setStages(stages);
		} catch (error) {
			console.error("Erro ao obter todas as fases:", error);
		}
	}

	async function changeIdeaStage(ideiaId: string, stageId: string) {
		const stageProvider = new StageProvider();
		try {
			await stageProvider.changeIdeaStage(ideiaId, stageId);
			message.success("Fase alterada com sucesso!");
			setCurrentIdeia((prevIdeia) => ({
				...prevIdeia,
				stage: stages.find((stage) => stage.stageId === stageId),
			}));
			countStages();
			setOpenStageModal(false);
		} catch (error) {
			message.error("Erro ao alterar a fase da ideia.");
		}
	}

	function onTabChange(key: string) {
		console.log({ key, isRatingChanged });
		if (isRatingChanged) {
			confirmationActionRef.current = handleTabChangeConfirm;
			setPendingTabKey(key);
			setConfirmationModalVisible(true);
		} else {
			setTabKey(key);
		}
	}

	function handleTabChangeConfirm() {
		if (pendingTabKey) {
			setTabKey(pendingTabKey);
			setPendingTabKey(null);
			setIsRatingChanged(false);
		}
		setConfirmationModalVisible(false);
	}

	async function handleDeleteIdea() {
		try {
			await ideaProvider.delete(ideia.ideaId?.toString() || "");
			message.success("Ideia deletada com sucesso!");
			setOpenModal(false);
		} catch (error) {
			message.error("Erro ao deletar ideia.");
		}
	}

	function showDeleteConfirm() {
		Modal.confirm({
			title: "Você tem certeza que deseja excluir esta ideia?",
			content: "Esta ação não pode ser desfeita.",
			okText: "Sim",
			okType: "danger",
			cancelText: "Não",
			onOk() {
				handleDeleteIdea().then(() => {
					fetchStages();
				});
			},
		});
	}

	function showConfirmationModal(action: () => void, message: string) {
		confirmationActionRef.current = action;
		setConfirmationMessage(message);
		setConfirmationModalVisible(true);
	}

	function onSuccessConfirm() {
		setShowSuccess(false);
	}

	function onCloseNormalViewModal() {
		if (isRatingChanged) {
			showConfirmationModal(closeModal, "As alterações serão perdidas. Deseja continuar?");
		}
		else {
			setOpenModal(false);
		}
	
	}

	function closeModal() {
		console.log("chamou o close modals")
		setOpenModal(false);
		setConfirmationModalVisible(false);
	}

	return (
		<ViewModalContainer>
			<Modal
				className="normal-view-modal"
				title={<h2>{currentIdeia.title}</h2>}
				open={openModal}
				onOk={onCloseNormalViewModal}
				onCancel={onCloseNormalViewModal}
				maskClosable={false}
				width={1280}
				footer={[
					<div key="normalview-footer">
            {permissionValidator.hasPermission(PERMISSIONS.REMOVER_IDEIA) && tabKey === "details" && (
                <Button
                  key={"delete"}
                  onClick={showDeleteConfirm}
                  className="normalview-footer-button"
                  type="primary"
                  danger
                  style={{ borderRadius: "30px", width: "150px", height: "35px", fontSize: "1em", margin: "6px 10px 6px 0" }}
                >
                  Excluir Ideia
                </Button>
              )}
            {tabKey === "rating" && rating?.status === "RASCUNHO" && permissionValidator.hasPermission(PERMISSIONS.AVALIAR_IDEIAS) &&
                  <DefaultButton
                    key={"delete"}
                    width="150px"
                    height="35px"
                    fontSize="1em"
                    withOutBackground={true}
                    params={{
                      type: "button",
                      onClick: () => deleteDraft(ideia.ideaId as string),
                      style: { margin: "6px 10px 6px 0" },
                      className: "normalview-footer-button"
                    }}
                  >
                    Deletar Rascunho
                  </DefaultButton>
                }
            {tabKey === "rating" ? <>
              <DefaultButton
                key={"back"}
                width="150px"
                height="35px"
                fontSize="1em"
                withOutBackground={true}
                params={{
                  type: "button",
                  onClick: () => rateIdea({...ratingPayload, type: 'draft'}),
                  className: "normalview-footer-button",
                  style: { margin: "6px 10px 6px 0" }
                }}
              >
                Salvar Rascunho
              </DefaultButton>
              <DefaultButton
                key={"submit"}
                width="150px"
                height="35px"
                fontSize="1em"
                params={{
                  type: "button",
                  style: { margin: "6px 10px 6px 0" },
                  className: "normalview-footer-button",
                  onClick: () => rateIdea({...ratingPayload, type: 'final'}),
                }} 
              >
                Salvar e Publicar
              </DefaultButton>
            </> :
            tabKey === "details" && <DefaultButton
              key={"back"}
              width="150px"
              height="35px"
              fontSize="1em"
              withOutBackground={true}
              params={{
                type: "button",
                onClick: () => setOpenStageModal(true),
                style: { margin: "6px 10px 6px 0" },
                className: "normalview-footer-button"
              }}
            >
              Alterar Fase
            </DefaultButton>}
            {!permissionValidator.hasPermission(PERMISSIONS.ALTERAR_FASE_IDEIA) ? null :
              <DefaultButton
                key={"submit"}
                width="150px"
                height="35px"
                fontSize="1em"
                params={{
                  type: "button",
                  onClick: onCloseNormalViewModal,
                  style: { margin: "6px 10px 6px 0" },
                  className: "normalview-footer-button"
                }}
              >
                Fechar
            </DefaultButton>
            }
          </div>
				]}
				centered
			>
				<ConfigProvider locale={ptBR}>
					<ViewModalSection>
						<Tabs
							defaultActiveKey="1"
							items={TabItems}
							onChange={onTabChange}
							activeKey={tabKey}
							style={{ margin: "0 28px"}}
						/>
						<ViewBody>
							{tabKey === "details" && <DetailsView idea={ideia} />}
							{tabKey === "comments" && <CommentsView idea={ideia} />}
							{tabKey === "rating" && (
								<RatingView
									idea={ideia}
									ratingPayload={ratingPayload}
									setRatingPayload={setRatingPayload}
									rating={rating}
									setIsRatingChanged={setIsRatingChanged}
								/>
							)}
							{tabKey === "related" && <RelatedView idea={ideia} />}
						</ViewBody>
					</ViewModalSection>
				</ConfigProvider>
			</Modal>

			<Modal
          title={<h3 style={{ textAlign: "center" }}>{successMessage}</h3>}
          open={showSuccess}
          onCancel={onSuccessConfirm}
          footer={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <DefaultButton
                key={"close"}
                width="150px"
                height="35px"
                fontSize="1em"
                params={{
                  type: "button",
                  onClick: onSuccessConfirm,
                }}
              >
                Fechar
              </DefaultButton>
            </div>
          }
          centered
        />

			<Modal
				title="Alterar Fase"
				open={openStageModal}
				onOk={() => setOpenStageModal(false)}
				onCancel={() => setOpenStageModal(false)}
				footer={null}
			>
				<List
					dataSource={stages}
					renderItem={(stage) => (
						<List.Item>
							<Button
								type={
									stage.stageId === ideia.stage?.stageId ? "primary" : "default"
								}
								onClick={() =>
									changeIdeaStage(
										ideia.ideaId as string,
										stage.stageId as string
									)
								}
								style={{ width: "100%" }}
							>
								{stage.title}
							</Button>
						</List.Item>
					)}
				/>
			</Modal>

			<Modal
				title={<h3 style={{ textAlign: "center", width: "100%" }}>Confirmação</h3>}
				open={confirmationModalVisible}
				onCancel={() => setConfirmationModalVisible(false)}
				footer={
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<DefaultButton
							key="cancel"
							width="150px"
							height="35px"
							fontSize="1em"
							withOutBackground={true}
							params={{
								type: 'button',
								onClick: () => setConfirmationModalVisible(false),
							}}
						>
							Não
						</DefaultButton>
						<DefaultButton
							key="confirm"
							width="150px"
							height="35px"
							fontSize="1em"
							params={{
								type: 'button',
								onClick: confirmationActionRef.current,
							}}
						>
							Sim
						</DefaultButton>
					</div>
				}
				centered
			>
				<p style={{ textAlign: "center" }}>As alterações serão perdidas. Deseja continuar?</p>
			</Modal>
		</ViewModalContainer>
	);
}

export default NormalView;
