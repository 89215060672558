import { FaTrash } from "react-icons/fa";
import  ChallengeDto from "../../../../../../../shared/dto/ChallengeDto";
import { ChallengeItem } from "../../../../../../../shared/dto/ChallengeItemDto";
import { Tooltip } from "antd";

interface MapProps {
	dto: ChallengeDto[];
	deleteFn: (dimension: ChallengeDto) => void;
	viewFn: (dimension: ChallengeDto) => void;
}

function mapChallengeDtoListToItems({ dto, deleteFn, viewFn }: MapProps): ChallengeItem[] {
	return dto
		.sort((a, b) => new Date(b.createdAt ?? 0).getTime() - new Date(a.createdAt ?? 0).getTime())
		.map((item) => ({
			...item,
			action: <>
				<Tooltip title="Deletar categoria">
          <span
						className="list-delete-button"
						onClick={() => deleteFn(item)}
						title="Deletar categoria"
					>
            <FaTrash />
          </span>
				</Tooltip>
				<span
					className="list-editar-button"
					onClick={() => viewFn(item)}
				>
          Editar
        </span>
			</>
		}));
}

export default mapChallengeDtoListToItems;