import React from "react";
import { UserCardContainer, UserCardImage } from "./styles";
import { IUsuarioSimplificado } from "../../../../dto/UsuarioSimplificado";
import getLetterColor from "../../../GetLetterColor";
import VisualizarUsuario from "./components/VisualizarUsuario";

interface UserCardProps {
	user: IUsuarioSimplificado;
}

function UserCard({ user }: Readonly<UserCardProps>): JSX.Element {
	const [open, setOpen] = React.useState(false);
	function getFirstName(name: string) {
		const names = name.split(" ");
		return `${names[0]}`;
	}

	return (
		<>
			<VisualizarUsuario
				open={open}
				setOpen={setOpen}
				user={user}
			/>
			<UserCardContainer onClick={() => setOpen(true)}>
			<UserCardImage
					letterColor={user && user.name ? getLetterColor(user.name.charAt(0)) : ""}
					image={user.profileImage ? `data:image/png;base64,${user.profileImage}` : ""}
				>
					{!user.profileImage && user.name && user.name.length > 0 && user.name.charAt(0)}
				</UserCardImage>
				<span id="user-name-greetings">Olá, <b>{getFirstName(user.name)}</b></span>
			</UserCardContainer>
		</>
	);
}

export default UserCard;
