import styled from "styled-components";

export const AdministracaoPageContainer = styled.div`
    margin: 0 10%;
    h1 {
        padding: 6px 0;
    }
    position: relative;
    @media (max-width: 500px) {
        h1 {
            text-align: center;
        }
    }

    section {
        @media (max-width: 1530px) {
            padding-top: 50px;
        }
    }
    .header-criar-role {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 40px;
        top: -11px;

        @media (max-width: 500px) {
            position: relative;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            right: auto;
        }

        button {
            margin-top: 14px;
            padding: 1.5rem;
        }
    }

`;