import { ROLES } from "../../../../../shared/guard/Roles";

export interface MenuItem {
  title: string;
  path?: string;
  protected?: ROLES[];
  disabled?: boolean;
  queryString: string;
}

const normalizeTitle = (title: string) => {
	return title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s+/g, '');
};

const MenuItems: MenuItem[] = [
  { title: "Permissões", queryString: normalizeTitle("Permissões") },
  {
    title: "Usuários",
    path: "/adm/gestao-usuario",
    queryString: normalizeTitle("Usuários"),
  },
  { title: "Categorias", queryString: normalizeTitle("Categorias") },
  { title: "Fases", queryString: normalizeTitle("Fases") },
  { title: "Dimensão", queryString: normalizeTitle("Dimensão") },
  { title: "Desafios", queryString: normalizeTitle("Desafios") },
  { title: "Termos de uso", queryString: normalizeTitle("Termos de uso") },
  { title: "Relatório", queryString: normalizeTitle("Relatório") },
];

export default MenuItems;