import styled from "styled-components";

export const TabMenuContainer = styled.nav`
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    font-size: 1em;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    @media (max-width: 1530px) {
      display: none;
    }

    @media (max-width: 950px) {
      max-width: 750px;
      width: 100%;
      overflow-x: auto;
    }

    .menu-disabled {
      color: ${({ theme }) => theme.colors.lightGray};
      opacity: 0.9;
      cursor: not-allowed;
      &:hover {
        background-color: transparent;
      }
    }
    div {
      padding: 15px 24px;
      text-decoration: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
      white-space: nowrap;
      &:hover {
        background-color: ${({ theme }) => theme.colors.lightGray};
      }
    }
    .active-nav-tab-item {
      color: ${({ theme }) => theme.colors.primary};
      border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    }
  }
`;