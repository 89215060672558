import { StageDto } from '../dto/StageDto';
import Envoy from '../util/Envoy';

interface IPaginationDTO {
  page?: number;
  itemsPerPage?: number;
}

class StageProvider extends Envoy {
  public async getAll(): Promise<Array<StageDto>> {
    try {
      const token = localStorage.getItem("token");
			if (!token) {
				throw new Error("Token não encontrado.");
			}

			this.conn.defaults.headers.Authorization = `Bearer ${token}`;
      const response = await this.conn.get(`/idea/stage`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao obter todas as fases:', error);
      throw error;
    }
  }

  public async createOrUpdate(stage: StageDto): Promise<StageDto> {
    try {
      const token = localStorage.getItem("token");
			if (!token) {
				throw new Error("Token não encontrado.");
			}

			this.conn.defaults.headers.Authorization = `Bearer ${token}`;
      const response = await this.conn.post('/idea/stage', stage);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error) {
      console.error('Erro ao criar fase:', error);
      throw error;
    }
  }

  public async delete(stageId: string): Promise<void> {
    try {
      const response = await this.conn.delete(`/idea/stage/${stageId}`);
      if (response.data.messages?.length) {
        throw new Error(response.data.messages.join('\n'));
      }
    } catch (error) {
      console.error('Erro ao deletar fase:', error);
      throw error;
    }
  }

  public async changeIdeaStage(ideaId: string, stageId: string): Promise<boolean> {
    try {
      const response = await this.conn.put(`/idea/stage/idea/${ideaId}/${stageId}`);
      if (response.data.messages?.length) {
        throw new Error(response.data.messages.join('\n'));
      }
      return response.data.data;
    } catch (error) {
      console.error('Erro ao alterar a fase da ideia:', error);
      throw error;
    }
  }
}

export default StageProvider;