import styled from "styled-components";

export const QuemInovaContainer = styled.div`
  padding: 20px 10% 0 10%;

  .goldone {
    height: 112px;
    z-index: 200;
  }

  .silverone {
    margin-right: -11px;
    border-bottom-right-radius: 0;
  }

  .bronzeone {
    margin-left: -9px;
    border-bottom-left-radius: 0;
  }
`;

export const PodiumContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const PodiumItem = styled.div<{ position: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: 133px;
  height: 95px;
  background: linear-gradient(to bottom, #328bae, #273373);
  padding: 10px 10px 10px 5px;
  border-radius: 10px;
  position: relative;
  color: white;
  z-index: 150;

  h4 {
    font-size: 0.75rem;
    text-align: center;
    word-break: break-word; /* Permite a quebra de palavras longas */
    overflow: hidden; /* Evita que ultrapasse o tamanho da caixa */
    display: -webkit-box;
    font-weight: 400;
    -webkit-line-clamp: 2; /* Limita a 2 linhas */
    -webkit-box-orient: vertical;

    @media (max-width: 500px) {
      font-size: 0.60rem;
    }
  }



`;

export const PositionCircle = styled.div<{ position: number }>`
  position: absolute;
  flex-shrink: 0;
  top: -22px;
  width: 3.6vh;
  height: 3.6vh;
  border-radius: 50%;
  background-color: ${({ position }) =>
    position === 1 ? "gold" : position === 2 ? "silver" : "#cd7f32"};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: bold;
  color: black;

`;

export const PodiumPosition = styled.div`
  font-size: 0.9rem;
  font-weight: bold;

`;

export const PodiumDetails = styled.div`
  text-align: center;
  display: flex;
  gap: 55px;
  font-size: 0.60rem;
  margin-top: -15px;
`;