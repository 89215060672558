import { message, Upload } from "antd";
import { UploadFile } from "antd/lib";

const beforeUploadImages = (file: File, setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>) => {
  const isImage = file.type.startsWith("image/");
  if (!isImage) {
      message.error("Você só pode fazer upload de imagens!");
      return Upload.LIST_IGNORE;
  }
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          if (ctx) {
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);
              canvas.toBlob(
                  (blob) => {
                      if (blob) {
                          const newFile: UploadFile<any> = {
                              uid: new Date().getTime().toString(),
                              name: file.name,
                              status: "done",
                              url: URL.createObjectURL(blob),
                          };
														setFileList((prevFileList) => [...prevFileList, newFile]);
                            return true;
                      }
                  },
                  file.type
              );
          }
      };
  };
  return false;
};

export default beforeUploadImages;