import styled from "styled-components";

export const ExcluirChallengeContainer = styled.div`
    .excluir-modal-breadcrumb-message {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        font-size: 0.6em;

        svg {
            margin-right: 5px;
        }
    }

    h3 {
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        word-wrap: break-word;
        word-break: break-word; 
        white-space: normal;
        svg {
            background: none;
            margin-right: 15px;
            font-size: 1.2em;
        }
    }

    p {
        font-size: 0.855em;
    }

    footer {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 15px;
        margin-top: 2.5rem;

        #voltar-delete {
            border: none;
            color: ${({ theme }) => theme.colors.primary};
            cursor: pointer;
            font-weight: 600;
            margin-left: 5px;
        }
    }

    .modal-content {
        border-radius: 10px; /* Rounded corners */
        padding: 20px; /* Space around content */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    }

    .success-message-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .centered-message {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    
    .edit-user-success-step {
        text-align: center;
    }

    @media (max-width: 768px) {
        .modal-content {
            width: 90%; 
            margin: 0 auto;
        }
    }
`;