import Envoy from '../util/Envoy';

export interface PodiumDTO {
  name: string;
  profileImage: string;
  position: number;
  submittedIdeas: number;
  interaction: number;
  likes: number;
  comments: number;
}

class PodiumProvider extends Envoy {
  public async getPodium(): Promise<Array<PodiumDTO>> {
    try {
      const token = localStorage.getItem("token");
			if (!token) {
				throw new Error("Token não encontrado.");
			}

			this.conn.defaults.headers.Authorization = `Bearer ${token}`;
      const response = await this.conn.get(`/podium`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao obter pódio de inovação:', error);
      throw error;
    }
  }

  public async getPodiumByPeriod(startDate: string, endDate: string): Promise<Array<PodiumDTO>> {
    try {
      const token = localStorage.getItem("token");
			if (!token) {
				throw new Error("Token não encontrado.");
			}

			this.conn.defaults.headers.Authorization = `Bearer ${token}`;
      const queryString = this.buildQueryString({ startDate, endDate });
      const response = await this.conn.get(`/podium/period${queryString}`);

      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao obter pódio de inovação por período:', error);
      throw error;
    }
  }
}

export default PodiumProvider;