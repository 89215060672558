import React from 'react';
import { Modal } from 'antd';
import DefaultButton from '../DefaultButton';

interface CustomModalProps {
  title: React.ReactNode;
  visible: boolean;
  onCancel: () => void;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  showWarning?: boolean;
  showSuccess?: boolean;
  warningMessage?: string;
  successMessage?: string;
  onWarningConfirm?: () => void;
  onWarningCancel?: () => void;
  onSuccessConfirm?: () => void;
  width?: number;
  showSuccessOk?: ()  => void;
}

const CustomModal: React.FC<CustomModalProps> = ({
  title,
  visible,
  onCancel,
  onConfirm,
  confirmText = 'Confirmar',
  cancelText = 'Cancelar',
  children,
  footer,
  showWarning = true,
  showSuccess = true,
  warningMessage = "Ao fechar o formulário as informações serão apagadas. Deseja fechar a janela?",
  successMessage = "Criado com sucesso",
  onWarningConfirm,
  onSuccessConfirm,
  onWarningCancel,
  width = 700,
  showSuccessOk,
}) => {

  return (
    <>
      <Modal
        title={title}
        open={visible}
        onCancel={onCancel}
        width={width}
        footer={
          footer ? (
            footer
          ) : (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <DefaultButton
                key="cancel"
                width="150px"
                height="35px"
                fontSize="1em"
                withOutBackground={true}
                params={{
                  type: 'button',
                  onClick: onCancel,
                }}
              >
                {cancelText}
              </DefaultButton>
              {onConfirm && (
                <DefaultButton
                  key="confirm"
                  width="150px"
                  height="35px"
                  fontSize="1em"
                  params={{
                    type: 'button',
                    onClick: onConfirm,
                  }}
                >
                  {confirmText}
                </DefaultButton>
              )}
            </div>
          )
        }
        centered
      >
        {children}
      </Modal>

      
        <Modal
          title={<h3 style={{ textAlign: "center", width: "100%" }}>Confirmação</h3>}
          open={showWarning}
          onCancel={onWarningCancel}
          footer={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <DefaultButton
                key="cancel"
                width="150px"
                height="35px"
                fontSize="1em"
                withOutBackground={true}
                params={{
                  type: 'button',
                  onClick: onWarningCancel,
                }}
              >
                Não
              </DefaultButton>
              {onWarningConfirm && (
                <DefaultButton
                  key="confirm"
                  width="150px"
                  height="35px"
                  fontSize="1em"
                  params={{
                    type: 'button',
                    onClick: onWarningConfirm,
                  }}
                >
                  Sim
                </DefaultButton>
              )}
            </div>
          }
          centered
        >
          <p style={{ textAlign: "center" }}>{warningMessage}</p>
        </Modal>
      

      {showSuccess && (
        <Modal
          title={<h3 style={{ textAlign: "center" }}>{successMessage}</h3>}
          open={showSuccess}
          onCancel={showSuccessOk || onCancel}
          footer={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <DefaultButton
                key={"close"}
                width="150px"
                height="35px"
                fontSize="1em"
                params={{
                  type: "button",
                  onClick: onSuccessConfirm,
                }}
              >
                Fechar
              </DefaultButton>
            </div>
          }
          centered
        />
      )}
    </>
  );
};

export default CustomModal;