import React, {useEffect} from "react";
import {
    EditUserFormContainer,
    EditarFormButtonsWrapper,
    EditarFormCheckboxContainer,
} from "./styles";
import RoleInput from "../../RoleInput";
import {OptionValue} from "../../../../../shared/dto/OptionValue";
import RoleProvider from "../../../../../shared/provider/RoleProvider";
import DefaultButton from "../../../../../shared/util/DefaultButton";
import {CreateRoleDto} from "../../../../../shared/dto/CreateRoleDto";
import {PERMISSIONS} from "../../../../../shared/guard/Permissions";
import Checkbox from "../../../../../shared/util/Checkbox";
import TextArea from "antd/es/input/TextArea";
import { Flex, message } from "antd";

interface CheckboxItem {
    label: string;
    checked: boolean;
}

function AddRoleForm({
                         setOpen,
                         isLoading = false,
                         setLoading,
                         setSuccessMessage,
                         requireRoleList,
                     }: {
    setOpen: React.Dispatch<boolean>;
    isLoading?: boolean;
    setLoading: React.Dispatch<boolean>;
    setSuccessMessage: React.Dispatch<string>;
    requireRoleList: () => Promise<void>;
}) {
    const [nome, setNome] = React.useState("");

    const roleProvider = new RoleProvider();

    const [checkboxes, setCheckboxes] = React.useState<{
        [key: string]: CheckboxItem;
    }>({});

    React.useEffect(() => {
        const fetchApiData = async () => {
            const data = await roleProvider.getPermissionOptions();
            const initialCheckboxesState: { [key: string]: CheckboxItem } =
                data.reduce((acc: any, item: OptionValue) => {
                    acc[item.value] = {label: item.label, checked: false};
                    return acc;
                }, {});
            setCheckboxes(initialCheckboxesState);
        };

        fetchApiData();
    }, []);

    function clearFields() {
        setNome("");
        setCheckboxes((prev) => {
            const newState = {...prev};
            Object.keys(newState).forEach((key) => {
                newState[key].checked = false;
            });
            return newState;
        });
    }

    function checkAllFields() {
        setCheckboxes((prev) => {
            const newState = {...prev};
            Object.keys(newState).forEach((key) => {
                newState[key].checked = true;
            });
            return newState;
        });
    }

    const handleCheckboxChange = (value: string) => {
        setCheckboxes((prev) => ({
            ...prev,
            [value]: {
                ...prev[value],
                checked: !prev[value].checked,
            },
        }));
    };

    async function createRole(data: CreateRoleDto) {
        setLoading(true);
        const response = await roleProvider.createRole(data);
        if (response) {
            await requireRoleList();
            setSuccessMessage(`O perfíl "${response}" foi criado com sucesso!`);
            clearFields();
        } else {
            message.error("Erro ao cadastrar usuário");
        }
        setLoading(false);
    }

    async function onInit() {
    }

    useEffect(() => {
        onInit();
    }, []);

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (!nome) {
            message.error("O nome é obrigatório");
            return;
        }

        const newRole: CreateRoleDto = {
            name: nome,
            permissions: Object.keys(checkboxes).filter(
                (key) => checkboxes[key] && checkboxes[key].checked
            ) as PERMISSIONS[],
        };

        createRole(newRole);
    }

    return (
        <EditUserFormContainer onSubmit={handleSubmit}>

            <Flex gap={6} style={{marginBottom: 20, flexDirection: "column" }}>
					<label className={"input-label"} htmlFor="title">
                        Nome do perfíl*:
					</label>
					<TextArea
							id="nome"
							showCount
							placeholder="Digite aqui"
							maxLength={100}
							value={nome}
							onChange={(e) => setNome(e.target.value)}
							autoSize={{ minRows: 1, maxRows: 3 }}
                            disabled={isLoading}
					/>
			</Flex>
            <EditarFormCheckboxContainer>
                <h3>Permissões</h3>
                <p>Funcionalidades que este perfíl terá acesso</p>
                <section>
                    {Object.keys(checkboxes)
                        .sort((a, b) =>
                            checkboxes[a].label.localeCompare(checkboxes[b].label)
                        )
                        .map((key) => (
                            <ul key={key}>
                                <Checkbox
                                    checked={checkboxes[key].checked}
                                    onChange={handleCheckboxChange}
                                    label={checkboxes[key].label}
                                    checkboxKey={key}
                                />
                            </ul>
                        ))}
                </section>
            </EditarFormCheckboxContainer>

            <EditarFormButtonsWrapper>
                <div>
                    <DefaultButton
                        width="220px"
                        height="30px"
                        fontSize="1em"
                        params={{
                            type: "button",
                            onClick: () => clearFields(),
                        }}
                        padding="1.5rem"
                        withOutBackground={true}
                    >
                        Limpar todos os campos
                    </DefaultButton>

                    <DefaultButton
                        width="220px"
                        height="30px"
                        fontSize="1em"
                        params={{
                            type: "button",
                            onClick: () => checkAllFields(),
                        }}
                        padding="1.5rem"
                        withOutBackground={true}
                    >
                        Marcar todos os campos
                    </DefaultButton>

                    <DefaultButton
                        width="150px"
                        height="30px"
                        fontSize="1em"
                        params={{
                            type: "submit",
                            disabled: isLoading,
                        }}
                        padding="1.5rem"
                    >
                        Salvar alterações
                    </DefaultButton>
                </div>
            </EditarFormButtonsWrapper>
        </EditUserFormContainer>
    );
}

export default AddRoleForm;
