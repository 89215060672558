import React, { useState } from "react";
import { ResponseUseTermDto } from "../../../../../../shared/dto/ResponseUseTermDto";
import UseTermProvider from "../../../../../../shared/provider/UseTermProvider";
import DefaultButton from "../../../../../../shared/util/DefaultButton";
import CustomModal from "../../../../../../shared/util/AntModal/CustomModal";
import { ModalContent } from "./styles";
import { useSelection } from "../../../../../../shared/context/selection/selectionProvider";

const useTermProvider = new UseTermProvider();

const AcceptUseTermModal: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { setUseTermModal, useTermModal, useTerm, handleLogout } = useSelection();

  const handleAccept = async () => {
    setLoading(true);
    try {
      await useTermProvider.acceptUseTerm(useTerm.useTermId);
      setSuccessMessage("Termo de uso aceito com sucesso");
    } catch (error) {
      console.error("Erro ao aceitar termo de uso:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = (leaving = true) => {
    if (leaving) {
      setUseTermModal(false);
      handleLogout();
    } else {
      setUseTermModal(false);
      setSuccessMessage("");
    }
  };

  return (
    <CustomModal
      title={<h2>Termo de Uso</h2>}
      visible={useTermModal}
      onCancel={handleCancel}
      showWarning={false}
      showSuccess={!!successMessage}
      showSuccessOk={() => handleCancel(false)}
      successMessage={successMessage}
      onSuccessConfirm={() => handleCancel(false)}
      footer={
        !successMessage && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DefaultButton
              key="cancel"
              width="150px"
              height="35px"
              fontSize="1em"
              withOutBackground={true}
              params={{
                type: "button",
                onClick: handleCancel,
              }}
            >
              Cancelar
            </DefaultButton>
            <DefaultButton
              key="accept"
              width="150px"
              height="35px"
              fontSize="1em"
              params={{
                type: "button",
                onClick: handleAccept,
                loading: loading,
              }}
            >
              Aceitar
            </DefaultButton>
          </div>
        )
      }
    >
      <ModalContent dangerouslySetInnerHTML={{ __html: useTerm.description }} />
    </CustomModal>
  );
};

export default AcceptUseTermModal;