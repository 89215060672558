import React from "react";
import {
	VisualizarUsuarioContainer,
	VisualizarUsuarioImagem,
	UserDetails,
} from "./styles";
import ViewUserTabMenu from "./components/TabMenu";
import getLetterColor from "../../../../../GetLetterColor";
import { IUsuarioSimplificado } from "../../../../../../dto/UsuarioSimplificado";
import VisualizarUsuarioForm from "./components/userView";
import MinhasIdeias from "./components/MinhasIdeias";
import { Modal } from "antd";
import DefaultButton from "../../../../../DefaultButton";

function VisualizarUsuario({
	open,
	setOpen,
	user,
}: Readonly<{
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	user: IUsuarioSimplificado;
}>) {
	const [loading] = React.useState(false);
	const [successMessage, setSuccessMessage] = React.useState("");
	const [viewUserTabActive, setViewUserTabActive] =
		React.useState("Meus dados");

	React.useEffect(() => {
		if (!open) {
			setSuccessMessage("");
		}
	}, [open]);

	let titleModal =
		viewUserTabActive === "Meus dados" ? "Meu perfil" : "Minhas ideias";

	return (
		<VisualizarUsuarioContainer>
			<Modal
				open={open}
				title={<h2>{titleModal}</h2>}
				onCancel={!successMessage ? () => setOpen(false) : undefined}
				footer={[
					<DefaultButton
						key="back"
						width="150px"
						height="35px"
						fontSize="1em"
						params={{
							type: "button",
							onClick: () => setOpen(false),
						}}
					>
						Fechar
				</DefaultButton>,
				]}
				maskClosable={false}
				width={1280}
        centered
			>
				{user && (
					<>
						<ViewUserTabMenu
							active={viewUserTabActive}
							setActive={setViewUserTabActive}
						/>
						{viewUserTabActive === "Meus dados" && (
							<>
								<UserDetails>
								<VisualizarUsuarioImagem
									className={loading ? "skeleton-loading" : ""}
									letterColor={user && user.name ? getLetterColor(user.name.charAt(0)) : ""}
									url={user.profileImage ? `data:image/png;base64,${user.profileImage}` : ""}
								>
									{!user.profileImage && user.name && user.name.length > 0 && user.name.charAt(0)}
								</VisualizarUsuarioImagem>

									<div>
										<h3>{user.name}</h3>
										<p>{user.position ? `Cargo: ${user.position}` : ""}</p>
										{user.document && <p>CPF: {user.document}</p>}
										</div>
								</UserDetails>
								<VisualizarUsuarioForm
									user={user}
									setOpen={setOpen}
									isLoading={loading}
								/>
							</>
						)}
						{viewUserTabActive === "Minhas Ideias" && (
							<MinhasIdeias />
						)}
					</>
				)}
			</Modal>
		</VisualizarUsuarioContainer>
	);
}

export default VisualizarUsuario;
