import React from "react";
import { EditarChallengeContainer } from "./styles";
import Modal from "../../../../../../shared/util/Modal";
import EditChallengeForm from "./components/editChallengeForm";
import DefaultButton from "../../../../../../shared/util/DefaultButton";
import ChallengeDto from "../../../../../../shared/dto/ChallengeDto";
import ShowChallenge from "./components/showChallenge";

function EditChallenge({
  open,
  setOpen,
  handleDeleteChallengeClick,
  requireChallengeList,
  challenge,
}: Readonly<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteChallengeClick: (challenge: ChallengeDto) => void;
  requireChallengeList(): Promise<void>;
  challenge: ChallengeDto;
}>) {
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [editMode, setEditMode] = React.useState(false);

  const isEditMode = editMode ? <h2>Editar desafio</h2> : <h2>Desafio</h2>;

  React.useEffect(() => {
    if (open) {
      setEditMode(false);
      setSuccessMessage("");
    }
  }, [open]);

  React.useEffect(() => {
    if (!open) {
      setSuccessMessage("");
    }
  }, [open]);

  const title = !successMessage ? isEditMode : <></>;

  return (
    <EditarChallengeContainer>
      
          <EditChallengeForm
            challengeId={challenge.challengeId || ""}
            open={open}
            setOpen={setOpen}
            isLoading={loading}
            setLoading={setLoading}
            setSuccessMessage={setSuccessMessage}
            requireChallengeList={requireChallengeList}
            setEditMode={setEditMode}
            challenge={challenge}
          />
        
    </EditarChallengeContainer>
  );
}

export default EditChallenge;