import { BellOutlined } from '@ant-design/icons';
import { IUsuarioSimplificado } from '../../../../dto/UsuarioSimplificado';
import UserProvider from '../../../../provider/UserProvider';
import React from 'react';
import { IdeaEventTypeIcons, NotificationDto } from '../../../../dto/NotificationDto';
import { Dropdown, List, Button, notification, Checkbox } from 'antd';
import { NotificationListContainer, NotificationItem, NotificationIcon, MarkAllAsReadContainer } from './styles';
import { useSelection } from '../../../../context/selection/selectionProvider';
import IdeaProvider from '../../../../provider/IdeaProvider';
import { useNavigate } from 'react-router-dom';

interface NotificationBellProps {
  user: IUsuarioSimplificado
}

function NotificationBell(params: NotificationBellProps): JSX.Element {
  const [notifications, setNotifications] = React.useState<NotificationDto[]>([]);
  const [visible, setVisible] = React.useState(false);
  const [markAllAsReadChecked, setMarkAllAsReadChecked] = React.useState(false);

  const ideaProvider = new IdeaProvider();
  const userProvider = new UserProvider();

  const navigate = useNavigate();

  const {
    setIdea,
    setOpenIdeasModal,
  } = useSelection();

  async function getNotifications() {
    try {
      const response = await userProvider.getLastsNotifications();
      setNotifications(response);
    } catch (error) {
      console.error("Erro ao buscar notificações:", error);
    }
  }

  async function getIdeaSelected(ideaId: number) {
    try {
      const response = await ideaProvider.getOne(ideaId.toString());
      if (response) {
        setIdea(response);
        setOpenIdeasModal(true);
      }
    } catch (error) {
      console.error("Erro ao buscar ideia selecionada:", error);
    }
  }

  React.useEffect(() => {
    getNotifications();
    const interval = setInterval(() => {
      getNotifications();
      setMarkAllAsReadChecked(false);
    }, 30000);

    return () => clearInterval(interval); 
  }, []);

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  const markAllAsRead = async () => {
    try {
      await userProvider.markAllAsRead();
      getNotifications();
      setMarkAllAsReadChecked(false);
      notification.success({
        message: 'Todas as notificações foram marcadas como lidas',
      });
    } catch (error) {
      console.error("Erro ao marcar todas as notificações como lidas:", error);
    }
  };

  const markAsRead = async (notification: NotificationDto) => {
    try {
      await userProvider.markAsRead(notification.notificationId.toString());
      getNotifications();
      navigate('/');
      await getIdeaSelected(notification.event?.ideaId);
    } catch (error) {
      console.error("Erro ao marcar notificação como lida:", error);
    }
  }

  const notificationList = (
    <NotificationListContainer>
      <List
        dataSource={notifications}
        locale={{ emptyText: 'Sem notificações' }}
        renderItem={item => {
          const Icon = IdeaEventTypeIcons[item.event?.eventType as unknown as keyof typeof IdeaEventTypeIcons];
          return (
            <NotificationItem key={item.notificationId} isRead={item.read}>
              <NotificationIcon>{Icon}</NotificationIcon>
              {!!item.event && !!item.event.title && (
                <List.Item.Meta
                  title={<div onClick={() => markAsRead(item)}>{item.event.title} #{item.event.ideaId}</div>}
                />
              )}
            </NotificationItem>
          )
        }}
      />
      {notifications.length > 0 && (
        <MarkAllAsReadContainer>
          <Checkbox
          value={markAllAsReadChecked}
          onChange={(e) => {
            if (e.target.checked) {
            markAllAsRead();
            setMarkAllAsReadChecked(true);
            }
          }}
          >
            Marcar todas como lidas
          </Checkbox>
        </MarkAllAsReadContainer>
      )}
    </NotificationListContainer>
  );

  return (
    <Dropdown
      overlay={notificationList}
      trigger={['click']}
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <BellOutlined
          style={{
            fontSize: '24px',
            color: 'blue',
            cursor: 'pointer',
            transition: 'color 0.3s',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = 'darkblue')}
          onMouseLeave={(e) => (e.currentTarget.style.color = 'blue')}
        />
        {notifications.length > 0 && <span
          style={{
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            background: 'red',
            borderRadius: '50%',
            color: 'white',
            padding: '2px 6px',
            fontSize: '12px',
          }}
        >
          {notifications.length}
        </span>}
      </div>
    </Dropdown>
  );
}

export default NotificationBell;