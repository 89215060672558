import React from "react";
import {
  LoginBoxContainer,
  LoginBoxContent,
  LoginBoxFooter,
} from "./styles";
import DefaultButton from "../../../../shared/util/DefaultButton";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../../../../shared/provider/AuthProvider";

interface LoginBoxProps {
  className?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserNotFound: React.Dispatch<React.SetStateAction<boolean>>;
}

function LoginBox({ className, open, setOpen, setUserNotFound }: LoginBoxProps): JSX.Element {
  React.useEffect(() => {
    if (!open) setUserNotFound(false);
  }, [open]);

  React.useEffect(() => {
    if (open) {
      window.localStorage.removeItem("tenantName");
    }
  }, [open]);

  function handleGov() {
    try {
      if (window.global.env.reactAppLocal) {
        setOpen(true);
      } else {
        window.location.href = `${window.global.env.reactAppOauthLoginUrl}`;
      }
    } catch (error) {
      console.log({error})
    }
  }

  return (
    <LoginBoxContainer className={className}>
      <LoginBoxContent>
        <h3>
          Já faz parte da nossa comunidade?
        </h3>

        <p><b>Entre e cadastre uma ideia!</b></p>

        <DefaultButton
        width="250px"
        marginTop="12px"
          params={{
            onClick: () => handleGov(),
          }}
        >
          <span>Entrar com o <span style={{fontWeight: 800}}>gov.br</span></span>
        </DefaultButton>
      </LoginBoxContent>

      <LoginBoxFooter>
      </LoginBoxFooter>
    </LoginBoxContainer>
  );
}

export default LoginBox;
