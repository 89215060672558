import styled from "styled-components";

export const ExplorerChallengesContainer = styled.div`
  padding: 20px;
  margin: 0 10%;

  @media (max-width: 768px) {
    margin: 0 2%;
  }
`;

export const Sidebar = styled.div`
  padding: 20px;
  background-color: #f0f2f5;
  border-radius: 8px;
  margin: 10px 0 20px 0;
  @media (max-width: 768px) {
    margin: 2% 5%;
  }
`;

export const Content = styled.div`
  border-radius: 8px;
`;