import styled from "styled-components";

export const ChallengeCarouselContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const ChallengeImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
`;

export const ChallengeContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  cursor: pointer;

  h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
  }

  p {
    margin: 10px 0 0;
    font-size: 16px;
  }

  @media (max-width: 1200px) {
    text-align: center;
  }
`;

export const ChallengeFooter = styled.div`
  margin-top: auto;

  p {
    margin: 0;
    font-size: 14px;
  }
`;