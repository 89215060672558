import React, { useState, useEffect } from "react";
import { Form, Row, Col, Checkbox, Select, DatePicker, Table, Input, Spin, ConfigProvider } from "antd";
import moment from "moment";
import IdeaProvider, { IReportParams } from "../../../../shared/provider/IdeaProvider";
import UserProvider from "../../../../shared/provider/UserProvider";
import { IUsuarioSimplificado } from "../../../../shared/dto/UsuarioSimplificado";
import * as XLSX from 'xlsx';
import get from 'lodash/get';
import styled from "styled-components";
import 'jspdf-autotable';
import ptBR from "antd/lib/locale/pt_BR";
import DefaultButton from "../../../../shared/util/DefaultButton";
import StageProvider from "../../../../shared/provider/StageProvider";
import { StageDto } from "../../../../shared/dto/StageDto";
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

const ideaProvider = new IdeaProvider();
const userProvider = new UserProvider();
const stageProvider = new StageProvider();

const columnsOptions = [
  { label: "ID", value: "ideaId" },
  { label: "Título", value: "title" },
  { label: "Data do Cadastro", value: "createdAt" },
  { label: "Autor", value: "author.name" },
  { label: "Fase", value: "stage.title" },
  { label: "Nota", value: "ratings.score" },
  { label: "Resumo", value: "shortDescription" },
  { label: "Oportunidade", value: "reportedProblem" },
  { label: "Solução", value: "solution" },
  { label: "Desafio", value: "challenge.title" },
  { label: "Data de Início da Ideação", value: "startIdeationDate" },
  { label: "Data Fim da Ideação", value: "endIdeationDate" },
];

const Report: React.FC = () => {
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);
  const [startPhaseDate, setStartPhaseDate] = useState<moment.Moment | null>(null);
  const [endPhaseDate, setEndPhaseDate] = useState<moment.Moment | null>(null);
  const [author, setAuthor] = useState<string>("");
  const [minScore, setMinScore] = useState<number | undefined>(undefined);
  const [maxScore, setMaxScore] = useState<number | undefined>(undefined);
  const [includeNoScore, setIncludeNoScore] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(["ideaId", "title", "stage.title"]);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [authors, setAuthors] = useState<IUsuarioSimplificado[]>([]);
  const [stages, setStages] = useState<StageDto[]>([]);
  const [selectedStages, setSelectedStages] = useState<number[]>([]);

  useEffect(() => {
    handleSearch();
    fetchStages();
  }, []);

  const handleSearch = async () => {
    setLoading(true);
    const params: IReportParams = {
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      authorId: author ? parseInt(author) : undefined,
      startStageDate: startPhaseDate?.toISOString(),
      endStageDate: endPhaseDate?.toISOString(),
      withoutScore: includeNoScore,
      minScore: minScore,
      maxScore: maxScore,
      stageIds: selectedStages.length ? selectedStages : undefined,
    };

    try {
      const reportData = await ideaProvider.generateReport(params);
      setData(reportData);
    } catch (error) {
      console.error("Erro ao gerar relatório:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStages = async () => {
    try {
      const response = await stageProvider.getAll();
      if (response && response.length) {
        setStages(response);
      }
    } catch (error) {
      console.error("Erro ao obter estágios:", error);
    }
  };

  React.useEffect(() => {
    if (includeNoScore) {
      setMinScore(undefined);
      setMaxScore(undefined);
    }
  }, [includeNoScore]);

  const handleExportExcel = () => {
    const exportData = data.map((record) => {
      const rowData: any = {};
      selectedColumns.forEach((col) => {
        const value = get(record, col);
        const label = columnsOptions.find((option) => option.value === col)?.label;
        if (!label) return;
        rowData[label] = col.includes("Data") && value ? moment(value).format("DD/MM/YYYY") : value;
      });
      return rowData;
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Relatório");
    const dateStr = moment().format('DDMMYY_HHmm');
    XLSX.writeFile(workbook, `Relatório_Ideias_${dateStr}.xlsx`);
  };

  const handleAuthorSearch = async (searchString: string) => {
    try {
      const data = searchString
        ? await userProvider.search({
            page: 1,
            itemsPerPage: 10,
            query: searchString,
          })
        : await userProvider.getAllUsers({ page: 1, itemsPerPage: 10 });
      if (!data) return;

      setAuthors(data.data);
    } catch (error) {
      console.error("Erro ao buscar autores:", error);
    }
  };

  const handleSelectAllColumns = () => {
    if (selectedColumns.length === columnsOptions.length) {
      setSelectedColumns([]);
    } else {
      setSelectedColumns(columnsOptions.map((option) => option.value));
    }
  };

  const columns = selectedColumns.map((col) => ({
    title: columnsOptions.find((option) => option.value === col)?.label,
    dataIndex: col,
    key: col,
    render: (text: any, record: any) => {
      const value = get(record, col);
      if (col.includes("Date") || col.includes("At")) {
        return value ? moment(value).format("DD/MM/YYYY") : "";
      }
      return value;
    },
  }));

  return (
    <Container>
      {loading && (
        <Spin indicator={<LoadingOutlined spin />} size="large" fullscreen/>
      )}
      <Row justify="start">
      <ConfigProvider locale={ptBR}>
        <Col xs={24} lg={24}>
          <Form layout="vertical">
            <Row gutter={16}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Data Inicial">
                  <DatePicker
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    format="DD/MM/YYYY"
                    placeholder="dd/mm/aaaa"
                    style={{ width: "100%", height: "35px" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Data Final">
                  <DatePicker
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    format="DD/MM/YYYY"
                    placeholder="dd/mm/aaaa"
                    style={{ width: "100%", height: "35px" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Autor">
                  <Select
                    showSearch
                    value={author}
                    onSearch={handleAuthorSearch}
                    onChange={(value) => setAuthor(value)}
                    placeholder="Informe o autor"
                    style={{ width: "100%", height: "35px" }}
                    filterOption={false}
                  >
                    {authors.map((author) => (
                      <Option key={author.id} value={author.id.toString()}>
                        {author.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item style={{ marginTop: 30 }}>
                  <Checkbox
                    checked={includeNoScore}
                    onChange={(e) => setIncludeNoScore(e.target.checked)}
                  >
                    Ideias sem nota
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Data de Início da Fase da Ideia">
                  <DatePicker
                    value={startPhaseDate}
                    onChange={(date) => setStartPhaseDate(date)}
                    format="DD/MM/YYYY"
                    placeholder="dd/mm/aaaa"
                    style={{ width: "100%", height: "35px" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Data Final da Fase da Ideia">
                  <DatePicker
                    value={endPhaseDate}
                    onChange={(date) => setEndPhaseDate(date)}
                    format="DD/MM/YYYY"
                    placeholder="dd/mm/aaaa"
                    style={{ width: "100%", height: "35px" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Nota Mínima">
                  <Input
                    type="number"
                    value={minScore}
                    onChange={(e) => setMinScore(e.target.value ? Number(e.target.value) : undefined)}
                    placeholder="Nota mínima"
                    style={{ width: "100%", height: "35px" }}
                    disabled={includeNoScore}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Nota Máxima">
                  <Input
                    type="number"
                    value={maxScore}
                    onChange={(e) => setMaxScore(e.target.value ? Number(e.target.value) : undefined)}
                    placeholder="Nota máxima"
                    style={{ width: "100%", height: "35px" }}
                    disabled={includeNoScore}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item label="Fases">
                  <Select
                    mode="multiple"
                    value={selectedStages}
                    onChange={(value) => setSelectedStages(value)}
                    placeholder="Selecione as fases"
                    style={{ width: "100%", height: "35px" }}
                  >
                    {stages.map((stage) => (
                      <Option key={stage.stageId} value={stage.stageId}>
                        {stage.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item label="Colunas">
                  <Select
                    mode="multiple"
                    value={selectedColumns}
                    onChange={(value) => setSelectedColumns(value)}
                    placeholder="Selecione as colunas"
                    style={{ width: "100%", height: "35px" }}
                    dropdownRender={(menu) => (
                      <>
                        <Option
                          key="selectAll"
                          onClick={handleSelectAllColumns}
                        >
                          {selectedColumns.length === columnsOptions.length
                            ? "Desmarcar todos"
                            : "Selecionar todos"}
                        </Option>
                        {menu}
                      </>
                    )}
                  >
                    {columnsOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} justify={"end"}>
              <Col xs={24} sm={6} lg={4} style={{ textAlign: "center" }}>
              <DefaultButton
                  width="100%"
                  height="30px"
                  fontSize="1em"
                  params={{
                    type: "button",
                    onClick: () => handleSearch(),
                  }}
                  padding="1.5rem"
                >
                  Pesquisar
                </DefaultButton>
              </Col>
            </Row>
          </Form>

          <TableContainer>
            <Table
              columns={columns}
              dataSource={data}
              rowKey="ideaId"
              style={{ marginTop: 20 }}
              scroll={{ x: true }}
              loading={false}
            />
          </TableContainer>
          <Row gutter={6} justify="end" style={{ marginTop: 20 }}>
              <Col xs={24} sm={6} lg={4} style={{ textAlign: "center" }}>
              <DefaultButton
                    width="100%"
                    height="25px"
                    fontSize="1em"
                    params={{
                        className: "export-excel",
                        type: "button",
                        onClick: () => handleExportExcel(),
                    }}
                    padding="1.5rem"
                >
                    Exportar para Excel
                </DefaultButton>
          </Col>
          </Row>
        </Col>
        </ConfigProvider>
      </Row>
    </Container>
  );
};

export default Report;

const Container = styled.div`
  padding: 20px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const FullScreenSpin = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
`;