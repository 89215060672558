export enum PERMISSIONS {
  ADMINISTRACAO = "ADMINISTRACAO",
  ADICIONAR_USUARIO = "ADICIONAR_USUARIO",
  ALTERAR_USUARIO = "ALTERAR_USUARIO",
  EXCLUIR_USUARIO = "EXCLUIR_USUARIO",
  CONSULTAR_USUARIO = "CONSULTAR_USUARIO",

  ADICIONAR_PAPEL = "ADICIONAR_PAPEL",
  ALTERAR_PAPEL = "ALTERAR_PAPEL",
  EXCLUIR_PAPEL = "EXCLUIR_PAPEL",

  ADICIONAR_TENANT = "ADICIONAR_TENANT",
  ALTERAR_TENANT = "ALTERAR_TENANT",
  EXCLUIR_TENANT = "EXCLUIR_TENANT",

  INSERIR_IDEIA = "INSERIR_IDEIA",
  EDITAR_PROPRIA_IDEIA = "EDITAR_PROPRIA_IDEIA",
  PESQUISAR_IDEIAS_POR_FILTROS = "PESQUISAR_IDEIAS_POR_FILTROS",
  LISTAR_IDEIAS_PAGINA_INICIAL = "LISTAR_IDEIAS_PAGINA_INICIAL",
  ACESSAR_DETALHES_IDEIA = "ACESSAR_DETALHES_IDEIA",
  DAR_LIKE_IDEIA = "DAR_LIKE_IDEIA",
  SEGUIR_IDEIAS = "SEGUIR_IDEIAS",
  ACESSAR_FICHA_TECNICA_IDEIA = "ACESSAR_FICHA_TECNICA_IDEIA",
  COMENTAR_IDEIAS = "COMENTAR_IDEIAS",
  VISUALIZAR_AVALIACOES = "VISUALIZAR_AVALIACOES",
  RELACIONAR_IDEIAS = "RELACIONAR_IDEIAS",
  VISUALIZAR_LINHA_DO_TEMPO_IDEIAS = "VISUALIZAR_LINHA_DO_TEMPO_IDEIAS",
  VER_ENTRAR_FASES_IDEIAS = "VER_ENTRAR_FASES_IDEIAS",
  ALTERAR_FASE_IDEIA = "ALTERAR_FASE_IDEIA",
  EDITAR_IDEIA = "EDITAR_IDEIA",
  REMOVER_IDEIA = "REMOVER_IDEIA",

  VER_PERFIL_FOTO = "VER_PERFIL_FOTO",
  VISUALIZAR_PERFIL = "VISUALIZAR_PERFIL",
  VISUALIZAR_QUEM_INOVA = "VISUALIZAR_QUEM_INOVA",

  RECEBER_NOTIFICACOES = "RECEBER_NOTIFICACOES",

  VER_PARTICIPAR_DESAFIOS = "VER_PARTICIPAR_DESAFIOS",
  VER_DESAFIOS = "VER_DESAFIOS",
  ACESSAR_IDEIAS_DESAFIO = "ACESSAR_IDEIAS_DESAFIO",
  CADASTRAR_IDEIA_DESAFIO = "CADASTRAR_IDEIA_DESAFIO",
  CADASTRAR_DESAFIOS = "CADASTRAR_DESAFIOS",
  EDITAR_DESAFIOS = "EDITAR_DESAFIOS",
  EXCLUIR_DESAFIOS = "EXCLUIR_DESAFIOS",
  INATIVAR_DESAFIOS = "INATIVAR_DESAFIOS",

  VER_TERMOS_DE_USO = "VER_TERMOS_DE_USO",
  VISUALIZAR_TERMOS_DE_USO = "VISUALIZAR_TERMOS_DE_USO",
  EDITAR_TERMOS_DE_USO = "EDITAR_TERMOS_DE_USO",

  VISUALIZAR_CATEGORIAS = "VISUALIZAR_CATEGORIAS",
  CRIAR_CATEGORIAS_IDEIAS = "CRIAR_CATEGORIAS_IDEIAS",
  EDITAR_CATEGORIAS = "EDITAR_CATEGORIAS",
  EXCLUIR_CATEGORIAS = "EXCLUIR_CATEGORIAS",
  UPLOAD_IMAGEM_CATEGORIAS = "UPLOAD_IMAGEM_CATEGORIAS",

  VISUALIZAR_DIMENSOES = "VISUALIZAR_DIMENSOES",
  CRIAR_DIMENSOES = "CRIAR_DIMENSOES",
  EDITAR_DIMENSOES = "EDITAR_DIMENSOES",
  EXCLUIR_DIMENSOES = "EXCLUIR_DIMENSOES",
  INATIVAR_DIMENSOES = "INATIVAR_DIMENSOES",

  CONSULTAR_RELATORIO = "CONSULTAR_RELATORIO",
  VISUALIZAR_RELATORIO = "VISUALIZAR_RELATORIO",
  EXPORTAR_DADO_EM_TELA_RELATORIO = "EXPORTAR_DADO_EM_TELA_RELATORIO",

  AVALIAR_IDEIAS = "AVALIAR_IDEIAS",

  FASES_IDEIA = "FASES_IDEIA",

  ADMINISTRACAO_NO_TENANT = "ADMINISTRACAO_NO_TENANT",
}