import React from "react";
import Modal from "../../../../../../shared/util/Modal";
import DefaultButton from "../../../../../../shared/util/DefaultButton";
import { AdicionarChallengeContainer } from "./styles";
import AddChallengeForm from "./components/AddChalengeForm";

function AddChallenge({
  open,
  setOpen,
  requireCategoryList,
}: Readonly<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  requireCategoryList: () => Promise<void>;
}>) {
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  React.useEffect(() => {
    if (!open) {
      setSuccessMessage("");
    }
  }, [open]);

  return (
    <AdicionarChallengeContainer>
      <AddChallengeForm
        open={open}
        setOpen={setOpen}
        isLoading={loading}
        setLoading={setLoading}
        setSuccessMessage={setSuccessMessage}
      />
    </AdicionarChallengeContainer>
  );
}

export default AddChallenge;