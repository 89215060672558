import React, { useState, useEffect } from "react";
import { Upload, message, Switch, ConfigProvider, Flex, UploadFile, UploadProps, DatePicker } from "antd";
import { RcFile } from "antd/lib/upload";
import ChallengeProvider from "../../../../../../../shared/provider/ChallengeProvider";
import ChallengeDto from "../../../../../../../shared/dto/ChallengeDto";
import { EditChallengeFormContainer } from "./styles";
import ptBR from "antd/lib/locale/pt_BR";
import ImgCrop from "antd-img-crop";
import CustomModal from "../../../../../../../shared/util/AntModal/CustomModal";
import { CategoryDto } from "../../../../../../../shared/dto/CategoryDto";
import CategoryProvider from "../../../../../../../shared/provider/CategoryProvider";
import beforeUploadImages from "../../../../../../../shared/util/BeforeUpload";
import TextArea from "antd/es/input/TextArea";
import { StyledSelect } from "../../../../../../ideia/page/styles";
import moment from "moment";
import { Option } from "antd/es/mentions";
import styled from "styled-components";
import { CloudUploadOutlined } from "@ant-design/icons";

interface EditChallengeFormProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
  requireChallengeList: () => Promise<void>;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  challengeId: string;
  challenge: ChallengeDto;
}

const EditChallengeForm: React.FC<EditChallengeFormProps> = ({
  open,
  setOpen,
  isLoading,
  setLoading,
  setSuccessMessage,
  requireChallengeList,
  setEditMode,
  challengeId,
  challenge,
}) => {
  const [image, setImage] = useState<RcFile | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [challengeName, setChallengeName] = useState<string>(challenge.title);
  const [shortDescription, setShortDescription] = useState<string>(challenge.shortDescription);
  const [description, setDescription] = useState<string>(challenge.description);
  const [startDate, setStartDate] = useState<moment.Moment | null>(moment(challenge.startDate));
  const [endDate, setEndDate] = useState<moment.Moment | null>(moment(challenge.endDate));
  const [featured, setFeatured] = useState<boolean>(challenge.featured);
  const [active, setActive] = useState<boolean>(challenge.active);
  const [selectedCategory, setSelectedCategory] = useState<CategoryDto>(challenge.category || {} as CategoryDto);
  const [categories, setCategories] = useState<CategoryDto[]>([] as CategoryDto[]);
  const categoryProvider = new CategoryProvider();

  useEffect(() => {
    fetchCategories();
    fillData();
  }, []);

  useEffect(() => {
    fillData();
  }, [challenge, open]);

  function fillData() {
    setChallengeName(challenge.title);
    setShortDescription(challenge.shortDescription);
    setDescription(challenge.description);
    setStartDate(moment(challenge.startDate));
    setEndDate(moment(challenge.endDate));
    setFeatured(challenge.featured);
    setSelectedCategory(challenge.category || {} as CategoryDto);
    setActive(challenge.active);
  }

  async function fetchCategories() {
    try {
      const response = await categoryProvider.getAll({ page: 1, itemsPerPage: 1000 });
      if (response && response.data.length) {
        setCategories(response.data);
      }
    } catch (error) {
      console.error("Erro ao obter categorias:", error);
    }
  }

  function handleCategoryChange(value: number) {
    if (categories.length) {
      const category = categories.find((category) => Number(category.categoryId) === value);
      if (category) {
        setSelectedCategory(category);
      }
    }
  }

  const handleSubmit = async () => {
    if (!haveValuesChanged()) {
      message.error("Preencha todos os campos obrigatórios");
      return;
    }
    setLoading(true);
    try {
      const challengeProvider = new ChallengeProvider();
      const updatedChallenge: ChallengeDto = {
        challengeId: challengeId,
        title: challengeName,
        shortDescription: shortDescription,
        description: description,
        startDate: startDate?.toISOString() || new Date().toISOString(),
        endDate: endDate?.toISOString() || new Date().toISOString(),
        category: selectedCategory,
        featured: featured,
        active: active,
      };
      const response = await challengeProvider.update(updatedChallenge, fileList[0]);
      setImage(null);
      setSuccessModalVisible(true);
      requireChallengeList();
    } catch (error) {
      console.error("Erro ao atualizar desafio:", error);
      message.error("Erro ao atualizar desafio.");
    } finally {
      setLoading(false);
    }
  };

  const clearBeforeLeave = () => {
    setChallengeName("");
    setShortDescription("");
    setDescription("");
    setFileList([]);
    setStartDate(null);
    setEndDate(null);
    setFeatured(false);
    setSelectedCategory({} as CategoryDto);
    setActive(false);
  }

  const defaultValues = {
    challengeName: challenge.title,
    shortDescription: challenge.shortDescription,
    description: challenge.description,
    fileList: [{ uid: '-1', name: 'image.png', status: 'done', url: challenge.coverImage }] as UploadFile[],
    startDate: moment(challenge.startDate),
    endDate: moment(challenge.endDate),
    featured: challenge.featured,
    selectedCategory: challenge.category,
    active: challenge.active,
  };

  const haveValuesChanged = () => {
    return (
      challengeName !== defaultValues.challengeName ||
      shortDescription !== defaultValues.shortDescription ||
      description !== defaultValues.description ||
      fileList.length !== defaultValues.fileList.length ||
      startDate !== defaultValues.startDate ||
      endDate !== defaultValues.endDate ||
      featured !== defaultValues.featured || 
      selectedCategory.categoryId !== (defaultValues.selectedCategory?.categoryId ?? null)
    );
  };

  const handleClose = () => {
    if (haveValuesChanged()) {
      setWarningModalVisible(true);
    } else {
      setOpen(false);
    }
  };

  const closeAllModals = () => {
    setSuccessModalVisible(false);
    setWarningModalVisible(false);
    setOpen(false);
  };

  const beforeUpload = (file: File) => {
    return beforeUploadImages(file, setFileList);
  };

  const onChangeFileUpload: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList.length > fileList.length) return;
    setFileList(newFileList);
  };

  const UploadStyled = styled(Upload)`
    .ant-upload-list-item {
      width: 100%;
      padding-top: 56.25%; /* 16:9 Aspect Ratio */
      position: relative;
    }

    .ant-upload-list-item-info {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .ant-upload-list-item-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `;

  return (
    <EditChallengeFormContainer>
      <CustomModal
        title={<h2>Editar Desafio</h2>}
        visible={open}
        onCancel={handleClose}
        onConfirm={handleSubmit}
        confirmText="Salvar desafio"
        successMessage={"Desafio atualizado com sucesso!"}
        footer={null}
        showWarning={warningModalVisible}
        showSuccess={successModalVisible}
        onWarningConfirm={() => {
          clearBeforeLeave();
          setWarningModalVisible(false);
          setOpen(false);
        }}
        onWarningCancel={() => setWarningModalVisible(false)}
        onSuccessConfirm={closeAllModals}
      >
        <EditChallengeFormContainer onSubmit={handleSubmit}>
          <Flex gap={6} style={{ marginBottom: 20, flexDirection: "column" }}>
            <label className={"input-label"} htmlFor="title">
              Título*:
            </label>
            <TextArea
              id="nome"
              showCount
              placeholder="Digite aqui"
              maxLength={140}
              value={challengeName}
              onChange={(e) => setChallengeName(e.target.value)}
              autoSize={{ minRows: 1, maxRows: 3 }}
            />
          </Flex>

          <Flex gap={30} style={{ marginBottom: 20 }}>
            <Flex gap={6} vertical style={{ flex: 3 }}>
              <label className={"input-label"} htmlFor="title">
                Imagem/Banner*:
              </label>
              <ImgCrop aspect={6 / 2} rotationSlider>
                <UploadStyled
                  beforeUpload={beforeUpload}
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChangeFileUpload}
                  maxCount={1}
                >
                  {fileList[0] ? null : <span>
                    <CloudUploadOutlined style={{fontSize: 24}} /><br />
                    Clique ou arraste a imagem (1200x300)<br />
                    .jpg, .jpeg, .png
                  </span>}
                </UploadStyled>
              </ImgCrop>
            </Flex>
            <div style={{ flex: 2, maxWidth: 500 }}>
              <Flex gap={6} vertical flex={1}>
                  <label className={"input-label"} htmlFor="dataInicio">
                    Data início*:
                  </label>
                  <ConfigProvider locale={ptBR}>
                      <DatePicker
                        className="date-picker"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        placeholder="Data de Início"
                        format="DD/MM/YYYY"
                        style={{ width: "100%" }}
                      />
                    <label className={"input-label"} htmlFor="dataFim">
                      Data fim*:
                    </label>
                      <DatePicker
                        className="date-picker"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        placeholder="Data de Fim"
                        format="DD/MM/YYYY"
                        style={{ width: "100%" }}
                      />
                  </ConfigProvider>
              </Flex>
            </div>
          </Flex>

          <Flex gap={6} style={{ marginBottom: 20, flexDirection: "column" }}>
            <label className={"input-label"} htmlFor="shortDescription">
              Resumo*:
            </label>
            <TextArea
              id="shortDescription"
              showCount
              placeholder="Digite aqui"
              maxLength={200}
              value={shortDescription}
              onChange={(e) => setShortDescription(e.target.value)}
              autoSize={{ minRows: 1, maxRows: 3 }}
            />
          </Flex>
          <Flex gap={6} style={{ marginBottom: 20, flexDirection: "column" }}>
            <label className={"input-label"} htmlFor="description">
              Descrição*:
            </label>
            <TextArea
              id="description"
              showCount
              placeholder="Digite aqui"
              maxLength={1500}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              autoSize={{ minRows: 3 }}
            />
          </Flex>
          
          <Flex gap={6} style={{ marginBottom: 20, flexDirection: "column" }}>
            <label className={"input-label"} htmlFor="categoria">
              Categoria*:
            </label>
            <StyledSelect
              className="category-select"
              placeholder="Categoria"
              value={selectedCategory && selectedCategory.categoryId}
              onChange={(value) => handleCategoryChange(value as number)}
            >
              {categories.map((category) => (
                <Option key={category.categoryId} value={category.categoryId}>
                  {category.name}
                </Option>
              ))}
            </StyledSelect>
          </Flex>
        </EditChallengeFormContainer>
        <Flex gap={6} vertical style={{ margin: "2px 0 10px 0" }}>
          <div>
            <label className={"input-label"} htmlFor="featured">
              É destaque*:
            </label>
            <Flex gap={12}>
              <Switch checked={featured} onChange={(checked) => setFeatured(checked)} /> {featured ? "Ativo" : "Desativado"}
            </Flex>
          </div>
          <div>
            <label className={"input-label"} htmlFor="active">
              Desafio ativo*:
            </label>
            <Flex gap={12}>
              <Switch checked={active} onChange={(checked) => setActive(checked)} /> {active ? "Ativo" : "Desativado"}
            </Flex>
          </div>
        </Flex>
      </CustomModal>
    </EditChallengeFormContainer>
  );
};

export default EditChallengeForm;