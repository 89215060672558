import React, { useEffect, useState } from "react";
import { Table, Spin, ConfigProvider } from "antd";
import { QuemInovaContainer, PodiumContainer, PodiumItem, PodiumPosition, PodiumDetails, PositionCircle } from "./styles";
import PodiumProvider, { PodiumDTO } from "../../../shared/provider/PodiumProvider";
import ptBR from "antd/lib/locale/pt_BR";
import AvatarComponent from "../../../shared/util/Avatar";
import { LoadingOutlined } from '@ant-design/icons';

const podiumProvider = new PodiumProvider();

const QuemInovaPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [podiumData, setPodiumData] = useState<PodiumDTO[]>([]);
  const [tableData, setTableData] = useState<PodiumDTO[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await podiumProvider.getPodium();
        setPodiumData(data.slice(0, 3));
        setTableData(data.slice(3, 13));
      } catch (error) {
        console.error("Erro ao obter pódio de inovação:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      title: "Posição",
      dataIndex: "position",
      key: "position",
      render: (position: number) => {
        return <strong>{position}º</strong>;
      }
    },
    {
      title: "Nome do usuário",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Números de ideias enviadas",
      dataIndex: "submittedIdeas",
      key: "submittedIdeas",
    },
    {
      title: "Números de likes e/ou comentários recebidos nas ideias",
      dataIndex: "interaction",
      key: "interaction",
    },
  ];

  const getInitials = (name: string) => {
    if (!name) return "";
    const [firstName, lastName] = name.split(" ");
    return lastName ? `${firstName[0]}${lastName[0]}` : `${firstName[0]}`;
  };

  return (
    <QuemInovaContainer>
      <ConfigProvider locale={ptBR}>
        {loading ? (
          <Spin indicator={<LoadingOutlined spin />} size="large" fullscreen/>
        ) : (
            <>
              <PodiumContainer>
                {podiumData.length > 1 && (
                  <PodiumItem position={2} className="silverone">
                    <PositionCircle position={2}>
                      <PodiumPosition>2º</PodiumPosition>
                    </PositionCircle>
                    {podiumData[1].profileImage ? (
                      <AvatarComponent shape="square" size={"37px"} src={`data:image/png;base64,${podiumData[1].profileImage}`} />
                    ) : (
                      <AvatarComponent shape="square" size={"37px"} initials={getInitials(podiumData[1].name)} />
                    )}
                    <PodiumDetails>
                      <div><b>{podiumData[1].submittedIdeas}</b><br />Ideias</div>
                      <div><b>{podiumData[1].likes}<br /></b>Likes</div>
                    </PodiumDetails>
                    <h4 style={{ color: "white", marginTop: 6 }}>{podiumData[1].name}</h4>
                  </PodiumItem>
                )}
                {podiumData.length > 0 && (
                  <PodiumItem position={1} className="goldone">
                    <PositionCircle position={1}>
                      <PodiumPosition>1º</PodiumPosition>
                    </PositionCircle>
                    {podiumData[0].profileImage ? (
                      <AvatarComponent shape="square" size={"37px"} src={`data:image/png;base64,${podiumData[0].profileImage}`} />
                    ) : (
                      <AvatarComponent shape="square" size={"37px"} initials={getInitials(podiumData[0].name)} />
                    )}
                    <PodiumDetails>
                      <div><b>{podiumData[0].submittedIdeas}</b><br />Ideias</div>
                      <div><b>{podiumData[0].likes}</b><br />Likes</div>
                    </PodiumDetails>
                    <h4 style={{ color: "white", marginTop: 6 }}>{podiumData[0].name}</h4>
                  </PodiumItem>
                )}
                {podiumData.length > 2 && (
                  <PodiumItem position={3} className="bronzeone">
                    <PositionCircle position={3}>
                      <PodiumPosition>3º</PodiumPosition>
                    </PositionCircle>
                    {podiumData[2].profileImage ? (
                      <AvatarComponent shape="square" size={"37px"} src={`data:image/png;base64,${podiumData[2].profileImage}`} />
                    ) : (
                      <AvatarComponent shape="square" size={"37px"} initials={getInitials(podiumData[2].name)} />
                    )}
                    <PodiumDetails>
                      <div><b>{podiumData[2].submittedIdeas}</b><br />Ideias </div>
                      <div><b>{podiumData[2].likes}</b><br />Likes</div>
                    </PodiumDetails>
                    <h4 style={{ color: "white", marginTop: 6 }}>{podiumData[2].name}</h4>
                  </PodiumItem>
                )}
              </PodiumContainer>
              <Table columns={columns} dataSource={tableData} rowKey="position" pagination={{ pageSize: 10 }} />
            </>
        )}
      </ConfigProvider>
    </QuemInovaContainer>
  );
};

export default QuemInovaPage;