import React, { useEffect } from "react";
import { AdministracaoPageContainer } from "./styles";
import DefaultButton from "../../../shared/util/DefaultButton";
import { FaPlus } from "react-icons/fa";
import RoleList from "../components/RoleList";
import AdminTabMenu from "../components/TabMenu";
import CategoryList from "../components/Category";
import DimensionList from "../components/Dimension";
import Report from "../components/Report";
import StageList from "../components/Stage";
import UserList from "../../usuario/components/UserList";
import ChallengeList from "../components/Challenge";
import UseTermList from "../components/UseTerm/components/UseTermList";
import { useSelection } from "../../../shared/context/selection/selectionProvider";

function AdministracaoPage(): JSX.Element {
  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [openAddRole, setOpenAddRole] = React.useState(false);
  const [openAddCategory, setOpenAddCategory] = React.useState(false);
  const [openAddUseTerm, setOpenAddUseTerm] = React.useState(false);
  const [openAddDimension, setOpenAddDimension] = React.useState(false);
  const [openAddStage, setOpenAddStage] = React.useState(false);
  const [openAddChallenge, setOpenAddChallenge] = React.useState(false);
  const [menuActive, setMenuActive] = React.useState("permissoes");

  const { administrationActiveMenu } = useSelection();

  const createButtonVisibleWhen = ["usuarios", "permissoes", "categorias", "dimensao", "fases", "desafios", "termosdeuso"];

  useEffect(() => {
    if (administrationActiveMenu) {
      setMenuActive(administrationActiveMenu);
    }
  }, [administrationActiveMenu]);

  function handleCreateButton(value: boolean) {
    if (menuActive === "usuarios") setOpenAddUser(value);
    if (menuActive === "permissoes") setOpenAddRole(value);
    if (menuActive === "categorias") setOpenAddCategory(value);
    if (menuActive === "dimensao") setOpenAddDimension(value);
    if (menuActive === "fases") setOpenAddStage(value);
    if (menuActive === "desafios") setOpenAddChallenge(value);
    if(menuActive === "termosdeuso") setOpenAddUseTerm(value);
  }

  function renderCreateButtonName() {
    if (menuActive === "usuarios") return "Novo Usuário";
    if (menuActive === "permissoes") return "Nova Permissão";
    if (menuActive === "categorias") return "Nova Categoria";
    if (menuActive === "desafios") return "Novo Desafio";
    if (menuActive === "dimensao") return "Nova Dimensão";
    if (menuActive === "fases") return "Nova Fase";
    if (menuActive === "termosdeuso") return "Novo Termo de Uso";
  }

  return (
    <AdministracaoPageContainer>
      <div className="header-criar-role">
        {createButtonVisibleWhen.includes(menuActive) ? (
          <DefaultButton
            width="220px"
            height="35px"
            fontSize="1em"
            params={{
              onClick: () => handleCreateButton(true),
            }}
          >
            <FaPlus />
            {renderCreateButtonName()}
          </DefaultButton>
        ) : (
          menuActive !== "relatorio" && (
            <DefaultButton
            width="220px"
            height="35px"
            fontSize="1em"
            padding="1.5rem"
            params={{
              disabled: true,
            }}
          >
            <FaPlus />
            {renderCreateButtonName()}
          </DefaultButton>
          )
        )}
      </div>

      <section>
        <AdminTabMenu
          active={menuActive}
          setActive={setMenuActive}
        />
        {menuActive === "usuarios" && (
          <UserList
            openAddUser={openAddUser}
            setOpenAddUser={setOpenAddUser}
          />
        )}

        {menuActive === "permissoes" && (
          <RoleList
            openAddRole={openAddRole}
            setOpenAddRole={setOpenAddRole}
          />
        )}

        {menuActive === "categorias" && (
          <CategoryList
            openAddCategory={openAddCategory}
            setOpenAddCategory={setOpenAddCategory}
          />
        )}

        {menuActive === "dimensao" && (
          <DimensionList
            openAddDimension={openAddDimension}
            setOpenAddDimension={setOpenAddDimension}
          />
        )}

        {menuActive === "fases" && (
          <StageList
            openAddStage={openAddStage}
            setOpenAddStage={setOpenAddStage}
          />
        )}

        {menuActive === "desafios" && (
          <ChallengeList
            openAddChallenge={openAddChallenge}
            setOpenAddChallenge={setOpenAddChallenge}
          />
        )}

        {menuActive === "termosdeuso" && (
          <UseTermList
            openAddUseTerm={openAddUseTerm}
            setOpenAddUseTerm={setOpenAddUseTerm}
          />
        )}


        {menuActive === "relatorio" && (
          <Report/>
        )}
      </section>
    </AdministracaoPageContainer>
  );
}

export default AdministracaoPage;