import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import CustomThemeProvider from "./styles/context/customThemeProvider";
import { RouterProvider } from "react-router-dom";
import IdeiaXRouter from "./router";
import NotifyContainer from "./shared/util/NotifyContainer";
import IdeiaContextProvider from "./shared/context/ideia/provider";
import SelectionProvider from "./shared/context/selection/selectionProvider";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<CustomThemeProvider>
		<IdeiaContextProvider>
			<SelectionProvider>
				<React.StrictMode>
					<NotifyContainer />
					<RouterProvider router={IdeiaXRouter}></RouterProvider>
				</React.StrictMode>
			</SelectionProvider>
		</IdeiaContextProvider>
	</CustomThemeProvider>
);

reportWebVitals();
