import styled from "styled-components";

export const ChallengeListContainer = styled.div`
  &:hover {
    background-color: #f0f0f0; 
  }
`;

export const AddModalContainer = styled.div``;

export const AddModalForm = styled.form`
  .ant-upload-select, .ant-upload-list-item-done {
    width: 100% !important;
  }
  .ant-upload-list-item-container {
    width: 100% !important;
  }
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding-right: 10px;
    min-height: 490px;
    overflow-y: auto;

    label {
        font-size: 15px;
        margin-bottom: 3px;
    }

    .ant-input {
        white-space: pre-wrap;
    }
`;