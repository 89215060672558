import { Link, RouteObject, createBrowserRouter } from "react-router-dom";
import LoginPage from "../modules/login/page";
import Ideia from "../modules/ideia/page";
import GestaoUsuario from "../modules/usuario/page";
import Protected from "./protected";
import Layout from "./layout";
import { ROLES } from "../shared/guard/Roles";
import GestaoPerfil, {
	loader as GestaoPerfilLoader,
} from "../modules/usuario/page/Perfil";
import EditarGestaoPerfil from "../modules/usuario/page/Perfil/Editar";
import AdministracaoPage from "../modules/administracao/page";
import Callback from "./callback";
import ExplorarDesafios from "../modules/explorarDesafios/page";
import QuemInovaPage from "../modules/quemInova/page";

export const routes: RouteObject[] = [
	{
		path: "/entrar",
		element: <LoginPage />,
		handle: {
			crumb: () => <Link to="/entrar">Entrar</Link>,
		},
	},
	{
		path: "/login-success",
		element: <Callback />,
		handle: {
			crumb: () => <Link to="/login-success">Home</Link>,
		},
	},
	{
		path: "/",
		element: <Protected page={<Layout />} />,
		children: [
			{
				index: true,
				element: <Ideia />,
				handle: {
					crumb: () => <Link to="/">Home</Link>,
				},
			},
			{
				path: "explorar-desafios",
				element: (
					<Protected
						roles={[ROLES.ADMIN ,ROLES.USER]}
						page={<ExplorarDesafios />}
					/>
				),
				handle: {
					crumb: () => <Link to="/explorar-desafios">Explorar desafios</Link>,
				},
			},
			{
				path: "quem-inova",
				element: (
					<Protected
						roles={[ROLES.ADMIN]}
						page={<QuemInovaPage />}
					/>
				),
				handle: {
					crumb: () => <Link to="/explorar-desafios">Explorar desafios</Link>,
				},
			},
			{
				path: "adm/gestao-usuario",
				element: (
					<Protected
						roles={[ROLES.ADMIN]}
						page={<GestaoUsuario />}
					/>
				),
				handle: {
					crumb: () => <Link to="/adm/gestao-usuario">Gestão de usuário</Link>,
				},
			},
			{
				path: "adm/gestao-usuario/perfil/:profileId",
				element: (
					<Protected
						roles={[ROLES.ADMIN]}
						page={<GestaoPerfil />}
					/>
				),
				loader: GestaoPerfilLoader,
				handle: {
					crumb: () => [
						<Link to="/adm/gestao-usuario">Gestão de usuário</Link>,
						<span>Ver perfíl</span>,
					],
				},
			},
			{
				path: "adm/gestao-usuario/perfil/:profileId/editar",
				element: (
					<Protected
						roles={[ROLES.ADMIN]}
						page={<EditarGestaoPerfil />}
					/>
				),
				loader: GestaoPerfilLoader,
				handle: {
					crumb: () => [
						<Link to="/adm/gestao-usuario">Gestão de usuário</Link>,
						<span>Perfíl do usuário</span>,
						<span>Editar perfíl</span>,
					],
				},
			},
			{
				path: "administracao",
				element: (
					<Protected
						roles={[ROLES.ADMIN]}
						page={<AdministracaoPage />}
					/>
				),
				handle: {
					crumb: () => [<span>Administração</span>],
				},
			},
		],
	},
];

const IdeiaXRouter = createBrowserRouter(routes);
export default IdeiaXRouter;
