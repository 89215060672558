import { FaLightbulb, FaComment, FaThumbsUp, FaThumbsDown, FaReply, FaStar, FaCheck, FaExchangeAlt, FaFileContract } from 'react-icons/fa';

export interface NotificationDto {
  notificationId: number;
  eventId: number;
  event: EventDto;
  userId: number;
  read: boolean;
  createdAt: string; // ISO 8601 date string
  updatedAt: string; // ISO 8601 date string
}

export interface EventDto {
  eventId: number;
  title: string;
  notify: boolean;
  ideaId: number;
  eventType: IdeaEventType;
  stage: string;
  createdAt: string; // ISO 8601 date string
  updatedAt: string; // ISO 8601 date string
}

export enum IdeaEventType {
  CRIOU_IDEIA = "criou uma nova ideia.",
  COMENTOU_IDEIA = "comentou na sua ideia.",
  GOSTOU_IDEIA = "gostou da sua ideia.",
  NAO_GOSTOU_IDEIA = "não gostou da sua ideia.",
  RESPONDEU_COMENTARIO = "respondeu o seu comentário.",
  FAVORITOU_IDEIA = "favoritou sua ideia.",
  AVALIOU_IDEIA = "avaliou a sua ideia.",
  MUDANCA_FASE_IDEIA = "Houve atualização na fase da sua ideia",
  TERMOS_USO = "termos de uso."
}
export const IdeaEventTypeIcons = {
  ["CRIOU_IDEIA"]: <FaLightbulb style={{marginRight: 6, color: '#1351b4', fontSize: 18 }}/>,
  ["COMENTOU_IDEIA"]: <FaComment style={{marginRight: 6, color: '#1351b4', fontSize: 18 }}/>,
  ["GOSTOU_IDEIA"]: <FaThumbsUp style={{marginRight: 6, color: '#1351b4', fontSize: 18 }}/>,
  ["NAO_GOSTOU_IDEIA"]: <FaThumbsDown style={{marginRight: 6, color: '#1351b4', fontSize: 18 }}/>,
  ["RESPONDEU_COMENTARIO"]: <FaReply style={{marginRight: 6, color: '#1351b4', fontSize: 18 }}/>,
  ["FAVORITOU_IDEIA"]: <FaStar style={{marginRight: 6, color: '#1351b4', fontSize: 18 }}/>,
  ["AVALIOU_IDEIA"]: <FaCheck style={{marginRight: 6, color: '#1351b4', fontSize: 18 }}/>,
  ["MUDANCA_FASE_IDEIA"]: <FaExchangeAlt style={{marginRight: 6, color: '#1351b4', fontSize: 18 }}/>,
  ["TERMOS_USO"]: <FaFileContract style={{marginRight: 6, color: '#1351b4', fontSize: 18 }}/>,
};