import { UploadFile } from 'antd/lib';
import ChallengeDto from '../dto/ChallengeDto';
import { IPageData } from '../dto/PageData';
import Envoy from '../util/Envoy';
import { RcFile } from 'antd/es/upload/interface';

interface IPaginationDTO {
    page?: number;
    itemsPerPage?: number;
    active?: boolean;
    featured?: boolean;
}

class ChallengeProvider extends Envoy {

    public async getById(challengeId: string): Promise<ChallengeDto> {
        try {
            const response = await this.conn.get(`/challenge/${challengeId}`);

            if (response.data.messages?.length)
                throw new Error(response.data.messages.join("\n"));

            return response.data.data;
        } catch (error) {
            console.error("Erro ao obter desafio por ID:", error);
            throw error;
        }
    }

    public async getAll(
        {
            page,
            itemsPerPage,
            active,
            featured,
        }: IPaginationDTO): Promise<IPageData<Array<ChallengeDto>>> {
        try {
            const token = localStorage.getItem("token");

            if (!token) {
                throw new Error("Token não encontrado.");
            }

            this.conn.defaults.headers.Authorization = `Bearer ${token}`;

            const paginationString = this.buildQueryString({ page, itemsPerPage, active, featured });

            const response = await this.conn.get(`/challenge${paginationString}`);
            
            if (response.data.messages?.length)
                throw new Error(response.data.messages.join("\n"));

            return response.data.data;
        } catch (error) {
            console.error("Erro ao obter todos os desafios:", error);
            //throw error;
            return {} as IPageData<Array<ChallengeDto>>;
        }
    }

    public async create(challenge: ChallengeDto, image: UploadFile): Promise<ChallengeDto> {
        try {
			const formData = await this.convertUploadFileToFormData(image);
            formData.append('challenge', JSON.stringify(challenge));

            const response = await this.conn.post("/challenge", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data.messages?.length)
                throw new Error(response.data.messages.join("\n"));
            return response.data.data;
        } catch (error) {
            console.error("Erro ao criar desafio:", error);
            throw error;
        }
    }

    public async update(challenge: ChallengeDto, image: UploadFile | undefined): Promise<ChallengeDto> {
        try {
            let formData: FormData;

            if (image) {
                formData = await this.convertUploadFileToFormData(image);
                formData.append('challenge', JSON.stringify(challenge));
            } else {
                formData = new FormData();
                formData.append('challenge', JSON.stringify(challenge));
            }

            const response = await this.conn.post(`/challenge/${challenge.challengeId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data.messages?.length)
                throw new Error(response.data.messages.join("\n"));
            return response.data.data;
        } catch (error) {
            console.error("Erro ao atualizar desafio:", error);
            throw error;
        }
    }

    public async saveCoverImage(challengeId: string, image: RcFile): Promise<void> {
        try {
            const formData = new FormData();
            formData.append('file', image);

            const response = await this.conn.post(`/challenge/${challengeId}/coverImage`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data.messages?.length) {
                throw new Error(response.data.messages.join("\n"));
            }
        } catch (error) {
            console.error("Erro ao salvar imagem de capa:", error);
            throw error;
        }
    }

    public async deleteCoverImage(challengeId: string): Promise<void> {
        try {
            const response = await this.conn.delete(`/challenge/${challengeId}/coverImage`);
            if (response.data.messages?.length) {
                throw new Error(response.data.messages.join("\n"));
            }
        } catch (error) {
            console.error("Erro ao deletar imagem de capa:", error);
            throw error;
        }
    }

    public async delete(challengeId: string): Promise<void> {
        try {
            const response = await this.conn.delete(`/challenge/${challengeId}`);
            if (response.data.messages?.length) {
                throw new Error(response.data.messages.join("\n"));
            }
        } catch (error) {
            console.error("Erro ao deletar desafio:", error);
            throw error;
        }
    }

    private async convertUploadFileToFormData(uploadFile: UploadFile): Promise<FormData> {
		const formData = new FormData();

		// Verifica se o arquivo existe e é do tipo RcFile
		if (uploadFile.originFileObj) {
			const file = uploadFile.originFileObj as RcFile;
			const blob = new Blob([file], { type: file.type });
			formData.append("file", blob, file.name);
		} else if (uploadFile.url) {
			const response = await fetch(uploadFile.url);
			const blob = await response.blob();
			formData.append("file", blob, uploadFile.name);
		} else {
			console.error("O arquivo não é válido ou não foi encontrado.");
		}

		return formData;
	}
}

export default ChallengeProvider;