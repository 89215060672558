import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RoleProvider from '../shared/provider/RoleProvider';

const Callback: React.FC = () => {
  const navigate = useNavigate();
  const roleProvider = new RoleProvider();

  useEffect(() => {
    init();
  }, [navigate]);

  async function init(): Promise<void> {
    const response = storeParams(new URLSearchParams(window.location.search));
    response && await getPermissions();
    response && navigate('/');
    !response && navigate('/entrar');
  }

  async function getPermissions(): Promise<void> {
    try {
      await roleProvider.getUserRole();
      await roleProvider.getUserPermissions();
    } catch (error) {
      console.error('Erro ao buscar permissões:', error);
    }
  }

  function storeParams(params: URLSearchParams): boolean {
    const token = params.get('token');
    const tenant = params.get('tenant');
    const multipleProfiles = params.get('multiProfile');
    if (token) {
      localStorage.setItem('token', token);
      tenant && localStorage.setItem('tenantName', tenant);
      multipleProfiles && multipleProfiles !== 'false' && localStorage.setItem('multipleProfiles', multipleProfiles);
      return true;
    }
    return false;
  }

  return (
    <div>
      <p>Redirecionando...</p>
    </div>
  );
};

export default Callback;