import React, { useState, useEffect, useRef } from "react";
import { TabMenuContainer } from "./styles";
import MenuItems from "./config/MenuItems";
import PermissionValidator from "../../../../guard/PermissionValidator";
import { NavLink, useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useSelection } from "../../../../context/selection/selectionProvider";

function TabMenu(): JSX.Element {
	const permissions = new PermissionValidator();
	const navigate = useNavigate();
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [activeMenu, setActiveMenu] = useState<string | null>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const { setAdministrationActiveMenu, administrationActiveMenu, mobileMenuOpen, setMobileMenuOpen } = useSelection();

	const toggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	const handleMenuClick = (item: any) => {
		if (item.childs) {
			setActiveMenu(activeMenu === item.title ? null : item.title);
		} else {
			setActiveMenu(null);
		}
	};

	const handleChildClick = (parentPath: string, queryString: string) => {
		setAdministrationActiveMenu(queryString);
		navigate(`${parentPath}?${queryString}`);
		setDropdownOpen(false);
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
			setDropdownOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (mobileMenuOpen) {
			setDropdownOpen(true);
		}
	}, [mobileMenuOpen]);

	useEffect(() => {
		if (!isDropdownOpen) {
			setActiveMenu(null);
			setMobileMenuOpen(false);
		}
	}, [isDropdownOpen]);

	return (
		<TabMenuContainer>
			<ul className="main-dropdown">
				{MenuItems.map((item) => {
					if (item.protected && !permissions.hasPermission(item.protected)) {
						return null;
					}
					return (
						<li
							aria-disabled={item.disabled}
							key={item.path}
						>
							{item.disabled && <a href="#">{item.title}</a>}
							{!item.disabled && (
								<NavLink
									to={item.path}
									className={({ isActive }) =>
										isActive ? "active-nav-tab-item" : ""
									}
								>
									{item.icon}
									{item.title}
								</NavLink>
							)}
						</li>
					);
				})}
			</ul>
			<div ref={dropdownRef} className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}>
				{MenuItems.map((item) => {
					if (item.protected && !permissions.hasPermission(item.protected)) {
						return null;
					}
					return (
						<li
							aria-disabled={item.disabled}
							key={item.path}
							onClick={() => handleMenuClick(item)}
						>
							{item.disabled && <a href="#">{item.title}</a>}
							{!item.disabled && (
								<>
									{item.childs ? (
										<a href="#">
											{item.icon}
											{item.title}
											{activeMenu === item.title ? <FaChevronUp style={{marginLeft: 6, fontSize: 12}} /> : <FaChevronDown style={{marginLeft: 6, fontSize: 12}} />}
										</a>
									) : (
										<NavLink
											to={item.path}
											className={({ isActive }) =>
												isActive ? "active-nav-tab-item" : "item-menu"
											}
										>
											{item.icon}
											{item.title}
										</NavLink>
									)}
								</>
							)}
						</li>
					);
				})}
				{activeMenu && (
					<ul style={{ flexDirection: 'column', zIndex: 900 }}>
						{MenuItems.find(item => item.title === activeMenu)?.childs?.map((child) => (
							<li key={child.title} onClick={() => handleChildClick(MenuItems.find(item => item.title === activeMenu)?.path as string, child.queryString)}>
								{child.icon}
								{child.title}
							</li>
						))}
					</ul>
				)}
			</div>
		</TabMenuContainer>
	);
}

export default TabMenu;
