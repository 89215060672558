import React, { useEffect } from "react";
import { Row, Col, Typography, Divider, Button } from "antd";
import { useNavigate } from "react-router-dom";
import ChallengeProvider from "../../../../shared/provider/ChallengeProvider";
import ChallengeDto from "../../../../shared/dto/ChallengeDto";
import { ChallengeDetailContainer } from "./styles";
import moment from "moment";
import DefaultButton from "../../../../shared/util/DefaultButton";
import { useSelection } from "../../../../shared/context/selection/selectionProvider";
import { useIdeia } from "../../../../shared/context/ideia/provider";

const { Title, Paragraph, Text } = Typography;

interface ChallengeDetailProps {
  challengeId: string;
}

function ChallengeDetail({ challengeId }: ChallengeDetailProps): JSX.Element {
  const challengeProvider = new ChallengeProvider();
  const navigate = useNavigate();
  const { challenge, setChallenge } = useSelection();
  const { setOpenModal } = useIdeia();


  useEffect(() => {
    fetchChallenge();
  }, [challengeId]);

  async function fetchChallenge() {
    try {
      const data = await challengeProvider.getById(challengeId);
      if (!data) return;
      setChallenge(data);
    } catch (error) {
      console.error("Erro ao buscar desafio:", error);
    }
  }

  function handleBack() {
      setChallenge({} as ChallengeDto);
      navigate(-1);
  }

  function handleCreateIdea() {
    setOpenModal(true);
  }

  return (
    <ChallengeDetailContainer>
      <Row justify="center">
        <Col span={24}>
          <Title level={2} style={{ textAlign: "center" }}>{challenge.title}</Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <Paragraph style={{ textAlign: "center" }}>{challenge.shortDescription}</Paragraph>
        </Col>
      </Row>
      <Divider />
      <Row justify="center">
        <Col span={24}>
          <Paragraph>{challenge.description}</Paragraph>
        </Col>
      </Row>
      <Divider />
      <Row justify="center">
        <Col span={12} style={{ textAlign: "center" }}>
          <Text strong>Data Início: </Text>
          <Text>{moment(challenge.startDate).format("DD/MM/YYYY")}</Text>
        </Col>
        <Col span={12} style={{ textAlign: "center" }}>
          <Text strong>Data Fim: </Text>
          <Text>{moment(challenge.endDate).format("DD/MM/YYYY")}</Text>
        </Col>
      </Row>
      <Divider />
      <Row justify="center">
        <Col span={12} style={{ textAlign: "center" }}>
        <DefaultButton
            width="250px"
            height="36px"
            fontSize="1em"
            withOutBackground={true}
            params={{
              onClick: () => handleBack(),
            }}
          >
            Voltar a página inicial
          </DefaultButton>
        </Col>
        <Col span={12} style={{ textAlign: "center" }}>
        <DefaultButton
            width="250px"
            height="36px"
            fontSize="1em"
            params={{
              onClick: () => handleCreateIdea(),
              disabled: !challenge.active,
            }}
          >
            Enviar ideia
          </DefaultButton>
        </Col>
      </Row>
    </ChallengeDetailContainer>
  );
}

export default ChallengeDetail;