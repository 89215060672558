import styled from "styled-components";

export const UserCardContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
  padding: 0px 10px;

  @media (max-width: 1530px) {
    #user-name-greetings {
      display: none;
    }
  }

  span {
    font-size: 1em;
    font-weight: 500;
    background: none;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.softGray};
  }
`;

export const UserCardImage = styled.div<{ image: string, letterColor: string }>`
  background: ${({ image, letterColor }) => (image ? `url(${image})` : letterColor)};
  background-size: ${({ image }) => (image ? 'cover' : 'initial')};
  background-position: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.background};
  user-select: none;
  margin-right: 10px;
  cursor: pointer;
  flex-shrink: 0;

  @media (max-width: 1530px) {
    margin-right: -25px;
  }
`;