import Envoy from '../util/Envoy';
import { RequestUseTermDto } from '../dto/RequestUseTermDto';
import { ResponseUseTermDto } from '../dto/ResponseUseTermDto';
import { IPageData } from '../dto/PageData';
import { message } from 'antd';

class UseTermProvider extends Envoy {

  public async createUseTerm(data: RequestUseTermDto): Promise<ResponseUseTermDto> {
    try {
      const response = await this.conn.post('/use-term', data);
      return response.data;
    } catch (error: any) {
      console.log({error})

      if (error.response && error.response.status === 500 && error.response.data.includes("TDU0001")) {
        message.error("Termo de uso neste período já cadastrado");
      }
      throw error;
    }
  }

  public async getUseTermById(id: number): Promise<ResponseUseTermDto> {
    const response = await this.conn.get(`/use-term/${id}`);
    return response.data;
  }

  public async getAllUseTerms(page: number = 1, size: number = 10): Promise<IPageData<ResponseUseTermDto[]>> {
    const response = await this.conn.get(`/use-term?p=${page}&size=${size}`);
    return response.data.data;
  }

  public async updateUseTerm(id: number, data: RequestUseTermDto): Promise<ResponseUseTermDto> {
    try {
      const response = await this.conn.put(`/use-term/${id}`, data);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 500 && error.response.data.includes("TDU0001")) {
        message.error("Termo de uso neste período já cadastrado");
      }
      throw error;
    }
  }

  public async deleteUseTerm(id: number): Promise<string> {
    const response = await this.conn.delete(`/use-term/${id}`);
    return response.data;
  }

  public async updateUseTermState(id: number, state: string): Promise<string> {
    const response = await this.conn.patch(`/use-term/${id}/state`, { state });
    return response.data;
  }

  public async toggleUseTermActive(id: number, active: boolean): Promise<ResponseUseTermDto> {
    const response = await this.conn.patch(`/use-term/${id}/active`, { active });
    return response.data;
  }

  public async acceptUseTerm(id: number): Promise<string> {
    try {
      const token = localStorage.getItem('token');

      if (!localStorage.getItem('token')) {
        throw new Error("Usuário não autenticado");
      }

      this.conn.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const response = await this.conn.post(`/use-term/${id}/accept`);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 400 && error.response.data.includes("Termo de uso não encontrado")) {
        message.error("Termo de uso não encontrado");
      } else if (error.response && error.response.status === 400 && error.response.data.includes("Termo de uso ainda não está disponível")) {
        message.error("Termo de uso ainda não está disponível");
      } else if (error.response && error.response.status === 400 && error.response.data.includes("Termo de uso expirado")) {
        message.error("Termo de uso expirado");
      } else {
        message.error("Erro ao aceitar termo de uso");
      }
      throw error;
    }
  }

  public async getActiveUseTerm(): Promise<ResponseUseTermDto> {
    const response = await this.conn.get('/use-term/active');
    return response.data.data;
  }
}

export default UseTermProvider;