import styled from "styled-components";

export const StageListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;

  li {
    display: flex;
    min-width: 90px;
    span {
      font-weight: 600;
      font-size: 12px;
    }
  }

  @media (max-width: 930px) {
    position: fixed;
    top: 50%; /* Centraliza verticalmente */
    transform: translateY(-50%); /* Ajusta para centralizar considerando a altura do item */
    left: -240px; /* Escondido deixando apenas 10px visíveis */
    width: 250px;
    background: white;
    transition: left 0.3s ease-in-out;
    flex-direction: column;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 2px solid ${({ theme }) => theme.colors.primary};
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    &:after {
      content: "›";
      position: absolute;
      top: 50%;
      left: 240px;
      transform: translateY(-50%);
      width: 10px;
      height: 100%;
      color: ${({ theme }) => theme.colors.primary};
      border-right: 2px solid ${({ theme }) => theme.colors.primary};
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &.visible:after {
      content: "‹";
    }

    &.visible {
      left: 0;
    }
  }
`;
