import { createContext } from "react";
import ChallengeDto from "../../dto/ChallengeDto";
import { IdeiaDto } from "../../dto/IdeiaDto";
import { ResponseUseTermDto } from "../../dto/ResponseUseTermDto";

export interface SelectionContextData {
  challenge: ChallengeDto;
  setChallenge: (challenge: ChallengeDto) => void;
  idea: IdeiaDto;
  setIdea: (idea: IdeiaDto) => void;
  stageId: number;
  setStageId: (stageId: number) => void;
  categoryId: number;
  setCategoryId: (categoryId: number) => void;
  tenantId: number;
  setTenantId: (tenantId: number) => void;
  profileId: number;
  setProfileId: (profileId: number) => void;
  clearAll(): void
  openCreateIdeaModal: boolean;
  setOpenCreateIdeaModal: (open: boolean) => void;
  useTermModal: boolean;
  setUseTermModal: (open: boolean) => void;
  useTerm: ResponseUseTermDto;
  setUseTerm: (term: ResponseUseTermDto) => void;
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (open: boolean) => void;
  administrationActiveMenu: string;
  setAdministrationActiveMenu: (menu: string) => void;
  handleLogout: () => void;
  openIdeasModal: boolean;
  setOpenIdeasModal: (open: boolean) => void;
}

const SelectionContext = createContext<SelectionContextData>({} as SelectionContextData);

export default SelectionContext;