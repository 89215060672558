import React, { useState, useContext } from "react";
import SelectionContext, { SelectionContextData } from "./context";
import ChallengeDto from "../../dto/ChallengeDto";
import { IdeiaDto } from "../../dto/IdeiaDto";
import { ResponseUseTermDto } from "../../dto/ResponseUseTermDto";
import AuthProvider from "../../provider/AuthProvider";

const SelectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [challenge, setChallenge] = useState<ChallengeDto>({} as ChallengeDto);
  const [idea, setIdea] = useState<IdeiaDto>({} as IdeiaDto);
  const [stageId, setStageId] = useState<number>(0);
  const [categoryId, setCategoryId] = useState<number>(0);
  const [tenantId, setTenantId] = useState<number>(0);
  const [profileId, setProfileId] = useState<number>(0);
  const [openCreateIdeaModal, setOpenCreateIdeaModal] = useState<boolean>(false);
  const [useTermModal, setUseTermModal] = useState(false);
  const [useTerm, setUseTerm] = useState<ResponseUseTermDto>({} as ResponseUseTermDto);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [administrationActiveMenu, setAdministrationActiveMenu] = useState<string>("");
  const [openIdeasModal, setOpenIdeasModal] = useState<boolean>(false);
  const authProvider = new AuthProvider();

  function clearAll() {
    setChallenge({} as ChallengeDto);
    setIdea({} as IdeiaDto);
    setStageId(0);
    setCategoryId(0);
    setTenantId(0);
    setProfileId(0);
  }

  async function handleLogout() {
		try {
			await authProvider.logout();
			window.location.href= "/entrar";
		} catch (error) {
			console.error("Erro ao fazer logout:", error);
		}
	}

  console.log({idea})

  const value: SelectionContextData = {
    challenge,
    setChallenge,
    idea,
    setIdea,
    stageId,
    setStageId,
    categoryId,
    setCategoryId,
    tenantId,
    setTenantId,
    profileId,
    setProfileId,
    clearAll,
    openCreateIdeaModal,
    setOpenCreateIdeaModal,
    useTermModal,
    setUseTermModal,
    setUseTerm,
    useTerm,
    handleLogout,
    mobileMenuOpen,
    setMobileMenuOpen,
    administrationActiveMenu,
    setAdministrationActiveMenu,
    openIdeasModal,
    setOpenIdeasModal,
  };
  console.log({administrationActiveMenu})

  return (
    <SelectionContext.Provider value={value}>
      {children}
    </SelectionContext.Provider>
  );
};
const useSelection = (): SelectionContextData => {
  const context = useContext(SelectionContext);

  if (!context) {
    throw new Error("useSelection must be used within a SelectionProvider");
  }

  return context;
};

export default SelectionProvider;
export { useSelection };