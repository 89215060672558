import React from 'react';
import { AvatarContainer, AvatarImg, AvatarDiv } from './styles';

interface AvatarComponentProps {
  shape: 'square' | 'circle';
  src?: string;
  size: number | string;
  initials?: string;
}

const AvatarComponent: React.FC<AvatarComponentProps> = ({ shape, src, size, initials }) => {
  return (
    <AvatarContainer>
      {src ? (
        <AvatarImg shape={shape} src={src} size={size} />
      ) : (
        <AvatarDiv shape={shape} size={size}>
          {initials}
        </AvatarDiv>
      )}
    </AvatarContainer>
  );
};

export default AvatarComponent;