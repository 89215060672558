import React from "react";
import {
	ViewModalContainer,
	ViewModalSection,
	FooterButtons,
	FooterButtonGroup,
	ViewBody,
} from "./styles";
import { useIdeia } from "../../../../shared/context/ideia/provider";
import { ConfigProvider, Modal, Tabs, message } from "antd";
import PermissionValidator from "../../../../shared/guard/PermissionValidator";
import { PERMISSIONS } from "../../../../shared/guard/Permissions";
import { TabItems } from "./constants/tabs";
import DetailsView from "./components/Details";
import DefaultButton from "../../../../shared/util/DefaultButton";
import ptBR from "antd/lib/locale/pt_BR";
import CommentsView from "../NormalView/components/Comments";
import RatingView from "../NormalView/components/Rating";
import RelatedView from "../NormalView/components/Related";
import { CreateRatingItemDTO, RatingDto } from "../../../../shared/dto/RatingDto";
import IdeaProvider from "../../../../shared/provider/IdeaProvider";
import { IdeiaDto } from "../../../../shared/dto/IdeiaDto";
import RatingProvider from "../../../../shared/provider/RatingProvider";

export interface UserValue {
	label: string;
	value: string;
}

export interface RatingPayload {
	ideaId: string;
	items: CreateRatingItemDTO[];
	type: "draft" | "final";
}

function ViewModal(): JSX.Element {
	const permissionValidator = new PermissionValidator();
	const {
		openView: openModal,
		setOpenView: setOpenModal,
		ideia,
		setOpenEdit,
	} = useIdeia();
	const ideaProvider = new IdeaProvider();
  const ratingProvider = new RatingProvider();

	const [tabKey, setTabKey] = React.useState("details");
	const [rating, setRating] = React.useState<RatingDto>({} as RatingDto);
	const [ratingPayload, setRatingPayload] = React.useState<RatingPayload>(
		{} as RatingPayload
	);

	const [showSuccess, setShowSuccess] = React.useState(false);
	const [successMessage, setSuccessMessage] = React.useState("");
	const [isRatingChanged, setIsRatingChanged] = React.useState(false);
	const [pendingTabKey, setPendingTabKey] = React.useState<string | null>(null);
	const [confirmationModalVisible, setConfirmationModalVisible] = React.useState(false);

	React.useEffect(() => {
		if (!permissionValidator.hasPermission(PERMISSIONS.INSERIR_IDEIA)) {
			setOpenModal(false);
		}
	}, []);

	function onInit() {
		setTimeout(() => setTabKey("details"), 200);
	}

	React.useEffect(() => {
		if (openModal) {
			onInit();
		}
	}, [openModal]);

	function onTabChange(key: string) {
		if (isRatingChanged) {
			setPendingTabKey(key);
			setConfirmationModalVisible(true);
		} else {
			setTabKey(key);
		}
	}

	function handleTabChangeConfirm() {
		if (pendingTabKey) {
			setTabKey(pendingTabKey);
			setPendingTabKey(null);
			setIsRatingChanged(false);
		}
		setConfirmationModalVisible(false);
	}

	async function getRatingByIdeaId(ideaId: string) {
		try {
			const response = await ratingProvider.getRatingByIdeaId(ideaId);
			setRating(response);
		} catch (error) {
			console.error("Erro ao buscar avaliação:", error);
		}
	}

	async function deleteDraft(ideaId: string) {
		try {
			await ratingProvider.deleteDraft(ideaId);
			await getRatingByIdeaId(ideaId);
			message.success("Rascunho deletado com sucesso!");
		} catch (error) {
			console.error("Erro ao deletar rascunho:", error);
		}
	}

	async function handleDeleteIdea() {
		try {
			await ideaProvider.delete(ideia.ideaId?.toString() || "");
			message.success("Ideia deletada com sucesso!");
			setOpenModal(false);
		} catch (error) {
			message.error("Erro ao deletar ideia.");
		}
	}

	function showDeleteConfirm() {
		Modal.confirm({
			title: 'Você tem certeza que deseja excluir esta ideia?',
			content: 'Esta ação não pode ser desfeita.',
			okText: 'Sim',
			okType: 'danger',
			cancelText: 'Não',
			onOk() {
				handleDeleteIdea();
			},
		});
	}

	async function assignScore(ideaId: string) {
		try {
			const response = await ratingProvider.assignScore(ideaId);
			setRating(response);
		} catch (error) {
			console.error("Erro ao atribuir pontuação:", error);
		}
	}

	async function rateIdea(params: RatingPayload) {
		try {
			const { ideaId, items, type } = params;

			if (!ideaId || !items || !items.length) return;

			let response;
			if (type === "draft") {
				response = await ratingProvider.saveDraft(ideaId, items);
			}
			if (type === "final") {
				response = await ratingProvider.saveRating(ideaId, items);
			}
			await assignScore(ideaId);
			if (response) {
				setSuccessMessage(
					params.type === "draft"
						? "Rascunho salvo com sucesso!"
						: "Avaliação salva com sucesso!"
				)
				setShowSuccess(true);
				getRatingByIdeaId(ideaId);
			}
		} catch (error) {
			console.error("Erro ao avaliar ideia:", error);
		}
	}

	function onSuccessConfirm() {
		setShowSuccess(false);
	}

	return (
		<ViewModalContainer>
			<Modal
				title={<h2>{ideia.title}</h2>}
				className="user-view-modal"
				open={openModal}
				onOk={() => setOpenModal(false)}
				onCancel={() => setOpenModal(false)}
				maskClosable={false}
				width={1280}
				footer={[
					<FooterButtons key="footer-buttons">
						{permissionValidator.hasPermission(PERMISSIONS.REMOVER_IDEIA) && tabKey === "details" && (
							<button
								key={"delete"}
								onClick={showDeleteConfirm}
								className="delete-idea"
								style={{ margin: "6px 10px 6px 0" }}
							>
								Excluir Ideia
							</button>
						)}
						{tabKey === "rating" && rating?.status === "RASCUNHO" && permissionValidator.hasPermission(PERMISSIONS.AVALIAR_IDEIAS) && (
							<DefaultButton
								key={"delete"}
								width="150px"
								height="35px"
								fontSize="1em"
								withOutBackground={true}
								params={{
									type: "button",
									onClick: () => deleteDraft(ideia.ideaId as string),
									style: { margin: "6px 10px 6px 0" },
									className: "normalview-footer-button"
								}}
							>
								Deletar Rascunho
							</DefaultButton>
						)}
						{tabKey === "rating" ? (
							<>
								<DefaultButton
									key={"back"}
									width="150px"
									height="35px"
									fontSize="1em"
									withOutBackground={true}
									params={{
										type: "button",
										onClick: () => rateIdea({ ...ratingPayload, type: 'draft' }),
										className: "normalview-footer-button",
										style: { margin: "6px 10px 6px 0" }
									}}
								>
									Salvar Rascunho
								</DefaultButton>
								<DefaultButton
									key={"submit"}
									width="150px"
									height="35px"
									fontSize="1em"
									params={{
										type: "button",
										style: { margin: "6px 10px 6px 0" },
										className: "normalview-footer-button",
										onClick: () => rateIdea({ ...ratingPayload, type: 'final' }),
									}}
								>
									Salvar e Publicar
								</DefaultButton>
							</>
						) : (
							<DefaultButton
								key={"edit"}
								width="150px"
								height="35px"
								fontSize="1em"
								withOutBackground={true}
								params={{
									type: "button",
									onClick: () => setOpenEdit(true),
									style: { margin: "6px 10px 6px 0" },
								}}
							>
								Editar
							</DefaultButton>
						)}
						<DefaultButton
							key={"submit"}
							width="150px"
							height="35px"
							fontSize="1em"
							params={{
								style: { margin: "6px 10px 6px 0" },
								type: "button",
								onClick: () => setOpenModal(false),
							}}
						>
							Fechar
						</DefaultButton>
					</FooterButtons>,
				]}
				centered
			>
				<ConfigProvider locale={ptBR}>
					<ViewModalSection>
						<Tabs
							defaultActiveKey="1"
							items={TabItems}
							style={{ margin: "0 28px"}}
							onChange={onTabChange}
							activeKey={tabKey}
						/>
						<ViewBody>
							{tabKey === "details" && <DetailsView idea={ideia} />}
							{tabKey === "comments" && <CommentsView idea={ideia} />}
							{tabKey === "rating" && (
								<RatingView
									idea={ideia}
									ratingPayload={ratingPayload}
									setRatingPayload={setRatingPayload}
									rating={rating}
									setIsRatingChanged={setIsRatingChanged}
								/>
							)}
							{tabKey === "related" && <RelatedView idea={ideia} />}
						</ViewBody>
					</ViewModalSection>
				</ConfigProvider>
			</Modal>

			<Modal
				title={<h3 style={{ textAlign: "center" }}>{successMessage}</h3>}
				open={showSuccess}
				onCancel={onSuccessConfirm}
				footer={
					<div style={{ display: "flex", justifyContent: "center" }}>
						<DefaultButton
							key={"close"}
							width="150px"
							height="35px"
							fontSize="1em"
							params={{
								type: "button",
								onClick: onSuccessConfirm,
							}}
						>
							Fechar
						</DefaultButton>
					</div>
				}
				centered
			/>

			<Modal
				title={<h3 style={{ textAlign: "center", width: "100%" }}>Confirmação</h3>}
				open={confirmationModalVisible}
				onCancel={() => setConfirmationModalVisible(false)}
				footer={
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<DefaultButton
							key="cancel"
							width="150px"
							height="35px"
							fontSize="1em"
							withOutBackground={true}
							params={{
								type: 'button',
								onClick: () => setConfirmationModalVisible(false),
							}}
						>
							Não
						</DefaultButton>
						<DefaultButton
							key="confirm"
							width="150px"
							height="35px"
							fontSize="1em"
							params={{
								type: 'button',
								onClick: handleTabChangeConfirm,
							}}
						>
							Sim
						</DefaultButton>
					</div>
				}
				centered
			>
				<p style={{ textAlign: "center" }}>As alterações serão perdidas. Deseja continuar?</p>
			</Modal>
		</ViewModalContainer>
	);
}

export default ViewModal;
