import { Crypter } from "./crypter";

function Recover({
	fieldName,
	decrypt = false,
}: {
	fieldName: string;
	decrypt?: boolean;
}) {
	try {
		const stringfied = localStorage.getItem(fieldName);

		if (stringfied) {
			let text = stringfied;
			if (decrypt) {
				text = Crypter.decriptar(stringfied);
			}
			const data = JSON.parse(text);
			return data;
		}

		return {};
	} catch (error) {
		localStorage.removeItem(fieldName);
		return {};
	}
}

function Insert({
	packet,
	fieldName,
	encrypt = false,
}: {
	packet: any;
	fieldName: string;
	encrypt?: boolean;
}) {
	try {
		const stringfied = JSON.stringify(packet);
		const encryptedData = encrypt ? Crypter.encriptar(stringfied) : stringfied;
		localStorage.setItem(fieldName, encryptedData);
	} catch (error) {
		return;
	}
}

/**
 * Classe responsável por lidar com a persistência de dados.
 */
class PersistManager {
	encrypt = !!window.global.env.reactAppCryptoKey;
	fieldName = "";

	constructor(fieldName: string) {
		this.fieldName = fieldName;
	}

	get(key: string) {
		const data = Recover({ decrypt: this.encrypt, fieldName: this.fieldName });

		if (data && data[key]) {
			return data[key];
		}

		return;
	}

	set(key: string, value: unknown) {
		const data = Recover({ decrypt: this.encrypt, fieldName: this.fieldName });

		data[key] = value;
		Insert({ packet: data, encrypt: this.encrypt, fieldName: this.fieldName });
	}

	remove(key: string) {
		const data =
			Recover({ fieldName: key, decrypt: this.encrypt }) ||
			Recover({ fieldName: this.fieldName, decrypt: this.encrypt });

		if (data && data[key]) {
			delete data[key];
			Insert({
				packet: data,
				encrypt: this.encrypt,
				fieldName: this.fieldName,
			});
		}
	}
}

export { PersistManager };
