import React from "react";
import { EditarUseTermContainer } from "./styles";
import DefaultButton from "../../../../../../shared/util/DefaultButton";
import { ResponseUseTermDto } from "../../../../../../shared/dto/ResponseUseTermDto";
import EditUseTermForm from "./components/EditUseTermForm";
import CustomModal from "../../../../../../shared/util/AntModal/CustomModal";

function EditUseTerm({
  open,
  setOpen,
  handleDeleteUseTermClick,
  requireUseTermList,
  useTerm
}: Readonly<{
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  handleDeleteUseTermClick: (useTerm: ResponseUseTermDto) => void;
  requireUseTermList(): Promise<void>;
  useTerm: ResponseUseTermDto;
}>) {
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [editMode, setEditMode] = React.useState(true);

  const isEditMode = editMode ? <h2>Editar Termo de Uso</h2> : <h2>Termo de Uso</h2>;

  React.useEffect(() => {
    if (open) {
      setEditMode(true);
      setSuccessMessage("");
    }
  }, [open]);

  React.useEffect(() => {
    if (!open) {
      setSuccessMessage("");
    }
  }, [open]);

  const title = !successMessage ? isEditMode : <></>;

  return (
    <EditarUseTermContainer>
      <CustomModal
        title={title}
        visible={open}
        onCancel={() => setOpen(false)}
        showWarning={false}
        warningMessage="Ao fechar o formulário as informações serão apagadas. Deseja fechar a janela?"
        onWarningConfirm={() => setOpen(false)}
        showSuccess={!!successMessage}
        successMessage={successMessage}
        onSuccessConfirm={() => setOpen(false)}
        width={1280}
        footer={<></>}
      >
          <EditUseTermForm
            useTerm={useTerm}
            setOpen={setOpen}
            isLoading={loading}
            setLoading={setLoading}
            setSuccessMessage={setSuccessMessage}
            setEditMode={setEditMode}
            requireUseTermList={requireUseTermList}
          />
      </CustomModal>
    </EditarUseTermContainer>
  );
}

export default EditUseTerm;