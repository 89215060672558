import { CategoryDto } from "../dto/CategoryDto";
import { CreateCategoryDto } from "../dto/CreateCategoryDto";
import { IPageData } from "../dto/PageData";
import { UpdateCategoryDto } from "../dto/UpdateCategoryDto";
import Envoy from "../util/Envoy";
import { UploadFile } from "antd";
import { RcFile } from "antd/es/upload";

interface IPaginationDTO {
	page?: number;
	itemsPerPage?: number;
}

class CategoryProvider extends Envoy {
	public async getAll(
		{
			page,
			itemsPerPage,
		}
			: IPaginationDTO): Promise<IPageData<Array<CategoryDto>>> {
		try {
			const paginationString = this.buildQueryString({ page, itemsPerPage });

			const response = await this.conn.get(`/category${paginationString}`);

			if (response.data.messages?.length)
				throw new Error(response.data.messages.join("\n"));

			return response.data.data;
		} catch (error) {
			console.error("Erro ao obter todas as categorias:", error);
			throw error;
		}
	}

	public async create(category: CreateCategoryDto): Promise<CategoryDto> {
		try {
			const response = await this.conn.post("/category", category);

			if (response.data.messages?.length)
				throw new Error(response.data.messages.join("\n"));
			return response.data.data;
		} catch (error) {
			console.error("Erro ao criar categoria:", error);
			throw error;
		}
	}

	public async saveCoverImage(categoryId: string, image: UploadFile): Promise<void> {
		try {
			const formData = await this.convertUploadFileToFormData(image);

			const response = await this.conn.post(`/category/${categoryId}/coverImage`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			if (response.data.messages?.length) {
				throw new Error(response.data.messages.join("\n"));
			}
		} catch (error) {
			console.error("Erro ao salvar imagem de capa:", error);
			throw error;
		}
	}

	public async deleteCoverImage(categoryId: string): Promise<void> {
		try {
			const response = await this.conn.delete(`/category/${categoryId}/coverImage`);
			if (response.data.messages?.length) {
				throw new Error(response.data.messages.join("\n"));
			}
		} catch (error) {
			console.error("Erro ao deletar imagem de capa:", error);
			throw error;
		}
	}

	public async update(category: UpdateCategoryDto): Promise<CategoryDto> {
		try {
			const response = await this.conn.put(`/category/${category.categoryId}`, category);

			if (response.data.messages?.length)
				throw new Error(response.data.messages.join("\n"));
			return response.data.data;
		} catch (error) {
			console.error("Erro ao atualizar categoria:", error);
			throw error;
		}
	}

	public async delete(categoryId: string): Promise<void> {
		try {
			const response = await this.conn.delete(`/category/${categoryId}`);
			if (response.data.messages?.length) {
				throw new Error(response.data.messages.join("\n"));
			}
		} catch (error) {
			console.error("Erro ao deletar categoria:", error);
			throw error;
		}
	}

	private async convertUploadFileToFormData(uploadFile: UploadFile): Promise<FormData> {
		const formData = new FormData();

		// Verifica se o arquivo existe e é do tipo RcFile
		if (uploadFile.originFileObj) {
			const file = uploadFile.originFileObj as RcFile;
			const blob = new Blob([file], { type: file.type });
			formData.append("file", blob, file.name);
		} else if (uploadFile.url) {
			const response = await fetch(uploadFile.url);
			const blob = await response.blob();
			formData.append("file", blob, uploadFile.name);
		} else {
			console.error("O arquivo não é válido ou não foi encontrado.");
		}

		return formData;
	}

	// // public async getIdeasWithCategory(): Promise<Array<CardIdeasProps>> {
	// // 	try {
	// // 		const response = await this.conn.get("/category/ideas-with-category");
	// //
	// // 		if (response.data.messages?.length)
	// // 			throw new Error(response.data.messages.join("\n"));
	// //
	// // 		return response.data.data;
	// // 	} catch (error) {
	// // 		console.error("Erro ao obter ideias com categoria:", error);
	// // 		throw error;
	// // 	}
	// }

}

export default CategoryProvider;