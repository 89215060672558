import styled from "styled-components";

export const LoginContainer = styled.div`
	justify-content: space-between;
	align-items: center;
	height: 100vh;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	.image-light {
    padding: 0;
		display: none;
		@media screen and (max-width: 800px) {
    display: block;
		box-shadow: 0 22px 100px 70px rgb(6, 29, 58),inset 0px 20px 60px 10px rgb(6, 29, 58);
    background: 
            url("/assets/images/slide/lampadaMobile.png"); /* Imagem */
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;

  }
	}

	@media screen and (max-width: 800px) {
    justify-content: center;
    align-items: center;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
	}
`;

export const HeaderContainer = styled.div`
	display: flex;
	padding: 0 12px;
	//aspect-ratio: 21 / 9;
	justify-content: space-between;
	background-image: url("/assets/images/slide/backgroundLogin.jpg");
	background-size: cover;
	//background-position: bottom;
	background-position: 50% calc((100% - 8vh) / 2);
	background-repeat: no-repeat;
	flex-wrap: wrap;
	transition: height 0.3s ease-in-out;
	height: 82vh;

	/* @media (min-width: 2000px) {
		background-position: center;
		//height: 81.5vh;
	} */

	.hide-on-mobile {
		@media screen and (max-width: 800px) {
			display: none;
		}
	}

	@media screen and (max-width: 800px) {
  padding: unset;
  background: 
  linear-gradient(to top, rgba(0, 33, 65, 1), rgba(28, 19, 40, 1));
  background-blend-mode: overlay;
  background-size: cover;
  background-position: center;
	//background-position: 50% calc((100% - 75vh) / 2);
  background-repeat: no-repeat;
  width: 100%;
	height: auto;
  aspect-ratio: 16 / 9;
}

	.header-container {
		color: #fff;
		padding: 4rem 4rem 4rem 7rem;
		max-width: 860px;
		width: 100%;

		@media (max-width: 1370px) {
			max-width: 600px;
			padding: 2rem 4rem 2rem 7rem;

		}

		h2 {
			img {
				width: 100%;
				max-width: 300px;
				height: auto;

				@media (max-width: 1370px) {
					max-width: 220px;
				}

			}
			padding: 0 1rem;
			display: inline-flex;
			gap: 8px;
			align-items: center;
			justify-content: center;
			font-size: 2vmin;
			width: 100%;
		}

		p {
			margin-top: 10px;
			text-align: left;
			line-height: 1.8;
			font-size: 2vmin;

			@media (max-width: 1370px) {
				font-size: 1em;
			}

			@media (max-width: 800px) {
				text-align: justify;
			}
		}

		a {
			text-align: left;
			color: #fff;
			text-decoration: underline;
			font-weight: bold;
			font-size: 1.5em;
			margin-top: 8px;
			display: inline-flex;
			align-self: flex-start;
			@media (max-width: 1370px) {
				font-size: 1em;
			}

		}

		@media screen and (max-width: 500px) {
			width: 100%;
			padding: 1rem;
			margin-bottom: 4rem;
			h2 {
				font-size: 1.2em;
			}

			p {
				font-size: 1em;
				line-height: 1.6;
			}
		}
	}

	.secund-logo {
		padding: 3rem;
		img {
			width: 125px;
			max-width: 300px;
			height: auto;
		}

		@media screen and (max-width: 500px) {
			padding: 0;
			img {
				max-width: 150px;
			}
		}
	}

	.mobile-login-box {
		display: none;
		@media screen and (max-width: 800px) {
			display: block;
			margin-top: 1rem;
			h3 {
				color: #fff;
			}
			p {
				text-align: center;
			}
		}
	}
`;

export const LoginBoxWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	//margin-bottom: 5px;
	img {
		max-height: 12vh;
		height: 100%;
	}

	.hide-on-mobile {
		@media screen and (max-width: 800px) {
			display: none;
		}
	}

	@media screen and (max-width: 800px) {
		justify-content: center;
		flex-direction: column;
		margin-bottom: unset;
	}

	.desktop-login-box {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		@media screen and (max-width: 800px) {
			display: none;
		}
	}
`;
