import styled from "styled-components";

export const AdicionarChallengeContainer = styled.div`
    transition: all 0.5 ease-in-out;
    header {
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 1.5em;
            margin-bottom: 20px;
        }
    }

    .edit-challenge-success-step {
        text-align: center;
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 15px;
        }
    }

    .adicionar-challenge-modal-container {
        max-width: 700px;
        width: 100%;
        border-radius: 10px;
    }
`;