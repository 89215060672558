import axios, { AxiosInstance } from 'axios';
import { IIdeiasDTO } from '../../provider/IdeaProvider';

export interface IPaginationDTO {
  page?: number
  itemsPerPage?: number
  orderBy?: string
  direction?: 'asc' | 'desc'
}

axios.defaults.withCredentials = true;

class Envoy {
  protected url: string;
  protected conn: AxiosInstance;
  
  constructor() {
    this.url = window.global.env.reactAppApiUrl|| '';

    this.conn = axios.create({
      baseURL: this.url,
      timeout: 60000,
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
    });

    const token = localStorage.getItem('token');
    if (token) {
      this.conn.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      this.conn.defaults.headers.common['X-TOKEN-IDEAX'] = `${localStorage.getItem('token')}`;
    }
  }

  protected buildQueryString(params: IIdeiasDTO): string {
    const queryParams = [];
    if (params.page) {
      queryParams.push(`p=${params.page}`);
    }
    if (params.itemsPerPage) {
      queryParams.push(`i=${params.itemsPerPage}`);
    }
    if (params.orderBy) {
      queryParams.push(`ord=${params.orderBy}`);
    }
    if (params.direction) {
      queryParams.push(`dir=${params.direction}`);
    }
    if (params.q) {
      queryParams.push(`q=${params.q}`);
    }
    if (params.stageId) {
      queryParams.push(`stageId=${params.stageId}`);
    }
    if (params.stageIds) {
      queryParams.push(`stageIds=${params.stageIds.join(',')}`);
    }
    if (params.query) {
      queryParams.push(`query=${params.query}`);
    }
    if (params.ideaId) {
      queryParams.push(`ideaId=${params.ideaId}`);
    }
    if (params.startDate) {
      queryParams.push(`startDate=${params.startDate}`);
    }
    if (params.endDate) {
      queryParams.push(`endDate=${params.endDate}`);
    }
    if (params.categoriesId) {
      queryParams.push(`categoriesId=${params.categoriesId.join(',')}`);
    }
    if (params.active !== undefined) {
      queryParams.push(`active=${params.active}`);
    }
    if (params.featured) {
      queryParams.push(`featured=${params.featured}`);
    }
    if (params.challengeId) {
      queryParams.push(`challengeId=${params.challengeId}`);
    }
    if (params.authorId) {
      queryParams.push(`authorId=${params.authorId}`);
    }
    if (params.startStageDate) {
      queryParams.push(`startStageDate=${params.startStageDate}`);
    }
    if (params.endStageDate) {
      queryParams.push(`endStageDate=${params.endStageDate}`);
    }
    if (params.withoutScore) {
      queryParams.push(`withoutScore=${params.withoutScore}`);
    }
    if (params.minScore) {
      queryParams.push(`minScore=${params.minScore}`);
    }
    if (params.maxScore) {
      queryParams.push(`maxScore=${params.maxScore}`);
    }

    return queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
  }

}

export default Envoy;