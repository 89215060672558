import React, { useEffect, useState } from "react";
import {
	EditUserFormContainer,
	EditarFormButtonsWrapper,
	EditarFormDivider,
} from "./styles";
import UserInput from "../../UserInput";
import AutoCompleteSelect from "../../../../../shared/util/Autocomplete";
import { OptionValue } from "../../../../../shared/dto/OptionValue";
import RoleProvider from "../../../../../shared/provider/RoleProvider";
import TenantProvider from "../../../../../shared/provider/TenantProvider";
import { FaUser, FaEnvelope, FaSearch } from "react-icons/fa";
import { IUsuarioSimplificado } from "../../../../../shared/dto/UsuarioSimplificado";
import DefaultButton from "../../../../../shared/util/DefaultButton";
import { UpdateUserDto } from "../../../../../shared/dto/UpdateUserDto";
import validateEditUserUpdateDto from "./validation/validateEditUserUpdateDto";
import { message } from "antd";

function EditarUsuarioForm({
	user,
	setOpen,
	requestUpdate,
	isLoading = false,
	handleExcluirUsuario,
}: {
	user: IUsuarioSimplificado;
	setOpen: React.Dispatch<boolean>;
	requestUpdate(data: Partial<UpdateUserDto>): Promise<void>;
	isLoading?: boolean;
	handleExcluirUsuario: () => void;
}) {
	const [nome, setNome] = React.useState(user.name || "");
	const [email, setEmail] = React.useState(user.email || "");
	const [active, setActive] = React.useState(user.active || false);

	const [roleOptions, setRoleOptions] = useState([] as Array<OptionValue>);
	const [tenantOptons, setTenantOptions] = useState([] as Array<OptionValue>);

	const roleProvider = new RoleProvider();
	const tenantProvider = new TenantProvider();

	const [selectedRoleOption, setSelectedRoleOption] = useState(
		{} as { value: string; label: string }
	);

	const [selectedTenantOption, setSelectedTenantOption] = useState(
		{} as { value: string; label: string }
	);

	const [selectedActiveOption, setSelectedActiveOption] = useState(
		{} as { value: string; label: string }
	);

	const ActiveOptions = [
		{ value: "true", label: "Ativo" },
		{ value: "false", label: "Inativo" },
	];
	const isUserActive = user.active ? "Ativo" : "Inativo";
	const [roleValue, setRoleValue] = useState(selectedRoleOption.label || "");
	const [tenantValue, setTenantValue] = useState(
		selectedTenantOption.label || ""
	);
	const [activeValue, setActiveValue] = useState(
		selectedActiveOption.label || ""
	);

	async function requireRoleOptions() {
		try {
			const data = await roleProvider.getAll();
			if (!data) return [] as Array<OptionValue>;

			setRoleOptions(data.data);
			return data.data;
		} catch (error) {
			console.error("Erro ao buscar roles:", error);
			return [] as Array<OptionValue>;
		}
	}

	async function requireTenantOptions() {
		try {
			const data = await tenantProvider.getAll();
			if (!data) return [] as Array<OptionValue>;

			setTenantOptions(data);
			return data;
		} catch (error) {
			console.error("Erro ao buscar tenants:", error);
			return [] as Array<OptionValue>;
		}
	}

	function setSelectsDefaults(roles: OptionValue[], tenants: OptionValue[]) {
		const selectedRole = roles.find((role) => role.label === user.roleName);
		const selectedTenant = tenants.find(
			(tenant) => tenant.label === user.tenantName
		);

		setSelectedRoleOption(
			selectedRole || ({} as { value: string; label: string })
		);
		setSelectedTenantOption(
			selectedTenant || ({} as { value: string; label: string })
		);
		setSelectedActiveOption(
			ActiveOptions.find((option) => option.value === active.toString()) ||
				({} as { value: string; label: string })
		);
	}

	async function onInit() {
		const roles = await requireRoleOptions();
		const tenants = await requireTenantOptions();
		setSelectsDefaults(roles, tenants);
	}

	useEffect(() => {
		onInit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		const updatedUser: Partial<UpdateUserDto> = {
			...(selectedActiveOption.label &&
				selectedActiveOption.label !== isUserActive && {
					ativo: selectedActiveOption.value === "true" ? true : false,
				}),
			...(selectedRoleOption.label &&
				selectedRoleOption.label !== user.roleName && {
					roleId: selectedRoleOption.value,
				}),
			...(selectedTenantOption.label &&
				selectedTenantOption.label !== user.tenantName && {
					tenantId: selectedTenantOption.value,
				}),
		};

		const errors = validateEditUserUpdateDto(updatedUser);

		if (errors.length) {
			errors.forEach((error) => message.error(error));
			return;
		}

		if (!Object.keys(updatedUser).length) {
			message.info("Nenhuma alteração foi feita");
			return;
		}

		requestUpdate(updatedUser);
	}

	return (
		<EditUserFormContainer onSubmit={handleSubmit}>
			<EditarFormDivider />

			<main>
				<UserInput
					value={nome}
					setValue={setNome}
					label="Nome:"
					placeholder="Nome completo"
					icon={<FaUser />}
					type="username"
					width="100%"
					isLoading={isLoading}
					readonly={true}
				/>

				<div className="double-input">
					<UserInput
						value={email}
						setValue={setEmail}
						label="E-mail:"
						placeholder="Ex: seuemail@gov.br"
						icon={<FaEnvelope />}
						type="email"
						width="100%"
						isLoading={isLoading}
						id={"adicionar-email-input"}
						readonly={true}
					/>

					<AutoCompleteSelect
						inputValue={roleValue}
						setInputValue={setRoleValue}
						options={roleOptions}
						item={selectedRoleOption}
						setItem={setSelectedRoleOption}
						icon={<FaSearch />}
						showDropdownButton={true}
						label="Perfil:"
						isLoading={roleOptions.length === 0 || isLoading}
					/>
				</div>

				<div className="double-input">
					<AutoCompleteSelect
						inputValue={tenantValue}
						setInputValue={setTenantValue}
						options={tenantOptons}
						item={selectedTenantOption}
						setItem={setSelectedTenantOption}
						icon={<FaSearch />}
						showDropdownButton={true}
						label="Tenants"
						isLoading={tenantOptons.length === 0 || isLoading}
					/>

					<AutoCompleteSelect
						inputValue={activeValue}
						options={ActiveOptions}
						setInputValue={setActiveValue}
						item={selectedActiveOption}
						setItem={setSelectedActiveOption}
						showDropdownButton={true}
						label="Status"
					/>
				</div>
			</main>

			<EditarFormDivider />

			<EditarFormButtonsWrapper>
				<div>
					<button
						type="button"
						disabled={isLoading}
						onClick={handleExcluirUsuario}
					>
						Excluir usuário
					</button>
				</div>
				<div>
					<DefaultButton
						width="150px"
						height="35px"
						fontSize="1em"
						params={{
							type: "button",
							onClick: () => setOpen(false),
						}}
						withOutBackground={true}
					>
						Fechar
					</DefaultButton>

					<DefaultButton
						width="150px"
						height="35px"
						fontSize="1em"
						params={{
							type: "submit",
							disabled: isLoading,
						}}
					>
						Salvar alterações
					</DefaultButton>
				</div>
			</EditarFormButtonsWrapper>
		</EditUserFormContainer>
	);
}

export default EditarUsuarioForm;
