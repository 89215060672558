import { FaTrash } from "react-icons/fa";
import { Tooltip } from "antd";
import { ResponseUseTermDto } from "../../../../../../shared/dto/ResponseUseTermDto";
import { UseTermItem } from "../../../../../../shared/dto/UseTermItem";
import generateUUID from "../../../../../../shared/constants/uuid";
import React from "react";
import EditUseTermForm from "../EditUseTerm/components/EditUseTermForm";

interface MapProps {
  dto: ResponseUseTermDto[];
  deleteFn: (useTerm: ResponseUseTermDto) => void;
  viewFn: (useTerm: ResponseUseTermDto) => void;
}

function mapUseTermDtoListToItems({ dto, deleteFn, viewFn }: MapProps): UseTermItem[] {
  if (!dto || dto.length === 0) return [];
  return dto
    .sort((a, b) => new Date(b.startDate ?? 0).getTime() - new Date(a.startDate ?? 0).getTime())
    .map((item) => ({
      ...item,
      authorName: item.author.name,
      key: generateUUID(),
      action: (
        <>
          <Tooltip title="Excluir termo de uso">
            <span className="list-delete-button" onClick={() => deleteFn(item)}>
              <FaTrash />
            </span>
          </Tooltip>
          <span className="list-editar-button" onClick={() => viewFn(item)}>
            Editar
          </span>
        </>
      ),
    }));
}

export default mapUseTermDtoListToItems;