import React from "react";
import { AdicionarUseTermContainer } from "./styles";
import AddUseTermForm from "./components/addUseTermForm";
import CustomModal from "../../../../../../shared/util/AntModal/CustomModal";

function UseTerm({
  open,
  setOpen,
  requireUseTermList,
}: Readonly<{
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  requireStageList(): Promise<void>;
  requireUseTermList?: () => Promise<void>;
}>) {
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  React.useEffect(() => {
    if (!open) {
      setSuccessMessage("");
    }
  }, [open]);

  const handleCancel = () => {
    if (!successMessage) {
      setOpen(false);
    }
  };

  const handleSuccessConfirm = () => {
    setOpen(false);
  };

  return (
    <AdicionarUseTermContainer>
      <CustomModal
        title={!successMessage ? <h2>Criar Termo de Uso</h2> : <></>}
        visible={open}
        onCancel={handleCancel}
        showWarning={false}
        warningMessage="Ao fechar o formulário as informações serão apagadas. Deseja fechar a janela?"
        onWarningConfirm={handleCancel}
        showSuccess={!!successMessage}
        successMessage={successMessage}
        width={1280}
        onSuccessConfirm={handleSuccessConfirm}
        footer={<></>}
      >
        <AddUseTermForm
            setOpen={setOpen}
            isLoading={loading}
            setLoading={setLoading}
            setSuccessMessage={setSuccessMessage}
            requireUseTermList={requireUseTermList || (() => Promise.resolve())}
            open={open}
          />
      </CustomModal>
    </AdicionarUseTermContainer>
  );
}

export default UseTerm;