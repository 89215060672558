import { TableColumnsType } from "antd";
import  { ChallengeItem }  from "../../../../../../../shared/dto/ChallengeItemDto";

const columns: TableColumnsType<ChallengeItem> = [
	{
		dataIndex: 'coverImage',
		render: (coverImage) => <img src={`${window.global.env.reactAppApiPublicUrl}/${coverImage}?t${new Date().getTime()}`} alt="Cover" style={{ width: 158, height: 67 }} />,
		width: 158,
	},
	{
		title: 'Nome',
		dataIndex: 'title',
		sorter: (a, b) => a.title.localeCompare(b.title),
	},
	{
		title: 'Ações',
		dataIndex: 'action',
		width: 250,
	},
]

export default columns;