import styled from "styled-components";

export const EditarChallengeContainer = styled.div`
    transition: all 0.5 ease-in-out;

    header {
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 1.5em;
            margin-bottom: 20px;
        }
    }

    .edit-challenge-success-step {
        text-align: center;
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 15px;
        }
    }

    .adicionar-challenge-modal-container {
        max-width: 700px;
        width: 90%;
        border-radius: 10px;
        margin: 0 auto;
        padding: 20px; 
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    }

    @media (max-width: 768px) {
        .adicionar-challenge-modal-container {
            width: 95%;
        }
    }
`;