import React from "react";
import { AddSUseTermFormContainer } from "./styles";
import { ConfigProvider, DatePicker, Flex, message, Switch } from "antd";
import DefaultButton from "../../../../../../../shared/util/DefaultButton";
import UseTermProvider from "../../../../../../../shared/provider/UseTermProvider";
import ptBR from "antd/lib/locale/pt_BR";
import CustomEditor from "../../../../../../ideia/components/AddModal/components/CustomEditor";
import dayjs from "dayjs";

function AddUseTermForm({
  setOpen,
  setLoading,
  setSuccessMessage,
  requireUseTermList,
  open,
}: {
  setOpen: React.Dispatch<boolean>,
  isLoading?: boolean,
  setLoading: React.Dispatch<boolean>,
  setSuccessMessage: React.Dispatch<string>,
  requireUseTermList: () => Promise<void>,
  open?: boolean,
}) {
  const [UserTermDescription, setUserTermDescription] = React.useState<string>("");
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [active, setActive] = React.useState<boolean>(false);

  const useTermProvider = new UseTermProvider();

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    createUseTerm();
  }

  function handleStartDateChange(date: dayjs.Dayjs | null) {
    const today = dayjs();
    if (date && date.isBefore(today, 'day')) {
      message.error("Data de início não pode ser anterior a hoje");
      return;
    }
    setStartDate(date);
  }
  
  function handleEndDateChange(date: dayjs.Dayjs | null) {
    if (date && startDate && date.isBefore(startDate, 'day')) {
      message.error("Data de término não pode ser anterior à data de início");
      return;
    }
    setEndDate(date);
  }

  function handleSwitchChange(checked: boolean) {
    const today = dayjs();
    if (checked && (startDate && endDate && (startDate.isAfter(today) || endDate.isBefore(today)))) {
      message.error("Datas inválidas para ativar o termo de uso");
      return;
    }
    setActive(checked);
  }

  async function createUseTerm() {
    setLoading(true);

    try {
      if (!UserTermDescription || !startDate || !endDate) {
        message.error("Preencha todos os campos");
        return;
      }

      const response = await useTermProvider.createUseTerm({
        description: UserTermDescription,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        active
      });

      if (!response) throw new Error("Erro ao criar termo de uso");
      setSuccessMessage("Termo de uso criado com sucesso!");
      if (requireUseTermList) {
        setTimeout(() => requireUseTermList(), 550);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (open) {
      setUserTermDescription("");
      setStartDate(null);
      setEndDate(null);
      setActive(false);
    }
  }, [open]);

  return (
    <AddSUseTermFormContainer onSubmit={handleSubmit}>
      <CustomEditor
        value={UserTermDescription}
        onChange={(e) => setUserTermDescription(e.target.value)}
        showCharCount
        charLimit={8000}
      />

			<ConfigProvider locale={ptBR}>
      <div className="date-picker-container">
        <DatePicker
          placeholder="Data de Início"
          onChange={handleStartDateChange}
          format={"DD/MM/YYYY"}
        />
        <DatePicker
          placeholder="Data de Término"
          onChange={handleEndDateChange}
          format={"DD/MM/YYYY"}
          disabledDate={(current) => !!current && !!startDate && current.isBefore(startDate, 'day')}
        />
      </div>
      <Flex align="center" gap={15} style={{marginTop: 15}}>
        <Switch checked={active} onChange={handleSwitchChange} /> {active ? "Ativo" : "Inativo"}
      </Flex>
			</ConfigProvider>

      <footer>
        <DefaultButton
          width="125px"
          height="36px"
          fontSize="1em"
          withOutBackground={true}
          params={{
            onClick: () => setOpen(false),
            type: "button"
          }}
        >
          Voltar
        </DefaultButton>
        <DefaultButton
          width="125px"
          height="36px"
          fontSize="1em"
          params={{
            type: "submit",
          }}
        >
          Cadastrar
        </DefaultButton>
      </footer>
    </AddSUseTermFormContainer>
  );
}

export default AddUseTermForm;